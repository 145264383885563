import React from "react";
import { localStore } from "../core/utils/store";
import { config } from "../config";
import { cleanObject, isEmptyOrDefault } from "../core/helpers/misc";

export const logOut = () => {
  localStore.remove(config.TOKEN_KEY);
  localStore.remove(config.OPERATOR_KEY);
  window.location.reload(true);
};

export const getHeader = () => {
  const token = localStore.get(config.TOKEN_KEY);
  return new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(!!token ? { Authorization: `Bearer ${token}` } : {}),
  });
};

export const getTokenHeader = () => {
  const token = localStore.get(config.TOKEN_KEY);
  return new Headers({
    Accept: "application/json",
    ...(!!token ? { Authorization: `Bearer ${token}` } : {}),
  });
};
export const cleanFilters = (filters) =>
  filters
    .map((f) => ({
      ...f,
      items: f.items.filter((i) => !!i && !isEmptyOrDefault(i.value, true)),
    }))
    .filter((f) => !!f && f.items.length > 0);

export function convertQuery({
  page,
  perPage,
  usePager,
  filters,
  fields = [],
  sort = [],
}) {
  const sorting = sort.reduce(
    (acc, [name, order]) => [...acc, { name, order }],
    []
  );
  return cleanObject({
    page,
    perPage,
    usePager,
    sort: sorting,
    fields,
    filters,
  });
}

export async function parseStatus(res, callback) {
  const { status } = res;
  const contentType = res.headers.get("content-type");

  if (status === 401) {
    logOut();
    throw Error("Erro 401: Token expirada ou não informada.");
  } else if (status === 403) {
    throw Error("Erro 403: Você não tem permissão pra executar essa ação.");
  } else if (status === 404 && !contentType) {
    throw Error("Erro 404: Não encontrado.");
  } else if ([400, 404, 409, 422].includes(status)) {
    const payload = await res.json();
    console.log(payload);
    throw Error(
      `Erro ${status}: ${
        payload?.error?.message ? payload.error.message : payload
      }`
    );
  } else if (status === 500) {
    const payload = await res.json();
    const object = {
      message: (
        <>
          <p>
            Erro 500: Erro interno do sistema, entre em contato com o suporte
          </p>

          <pre>
            <code>{payload.error.message}</code>
          </pre>
        </>
      ),
    };

    throw object;
  } else if (status === 204) {
    return Promise.resolve(null);
  } else {
    return callback ? callback(res) : res.json();
  }
}
