import { useState } from "react";
import { deepMerge } from "../helpers/functional";
import { fillObject } from "../helpers/misc";
import { setValueInPath } from "../helpers/path";

const initialStatus = {
  displayName: "",
  fetching: false,
  submitting: false,
  errors: {},
  touched: {},
};

export function useForm(settings, route) {
  const [status, setStatus] = useState(initialStatus);
  const [entity, setEntity] = useState(settings.initialEntity);
  const abortControl = new AbortController();

  return {
    entity,
    hasId: !!route.match.params.id,
    submitting: status.submitting,
    fetching: status.fetching,
    displayName: status.displayName,
    errors: status.errors,
    touched: status.touched,
    handleSubmit: (onSubmit) => {
      const errors = settings.validate(entity, {});
      const canSubmit = Object.values(errors).length === 0;

      setStatus((prev) => ({
        ...prev,
        touched: fillObject(entity, true),
        submitting: canSubmit,
        errors,
      }));

      const elem = document.activeElement;

      if ("blur" in elem) {
        elem.blur();
      }

      if (canSubmit) {
        onSubmit(entity);
      }
    },
    handleFetch: ({ action, errorFn, mapper }) => {
      const handleError = (err) => {
        setStatus((prev) => ({ ...prev, fetching: false }));
        errorFn(err);
      };

      const finallyDo = (ent) => {
        setStatus((prev) => ({
          ...prev,
          displayName: !!settings.displayName
            ? settings.displayName(ent).toString()
            : "",
          fetching: false,
        }));

        setEntity((prev) => deepMerge(prev, ent));
      };

      setStatus((prev) => ({ ...prev, fetching: true }));

      action(route.match.params.id, abortControl).then((resp) => {
        const promEnt = !mapper ? resp : mapper(resp);

        if (promEnt instanceof Promise) {
          promEnt.then((ent) => finallyDo(ent)).catch(handleError);
        } else {
          finallyDo(promEnt);
        }
      });
    },
    handleChange: ({ type = "blur", path, values }) =>
      setEntity((prev) => {
        const ent =
          values instanceof Function
            ? values(prev)
            : Object.assign({}, prev, values);

        setStatus((prevStat) => {
          const errors = settings.validate(ent, {});
          let touched = { ...prevStat.touched };

          if (type === "blur") {
            touched = setValueInPath(touched, path, true);
          }

          return { ...prevStat, errors, touched };
        });

        return ent;
      }),
    setSubmitting: (submitting) =>
      setStatus((prev) => ({ ...prev, submitting })),
    setErrors: (errors) =>
      setStatus((prev) => ({
        ...prev,
        errors: Object.assign({}, prev.errors, errors),
        submitting: false,
      })),
    setValues: (values) =>
      setEntity((prev) =>
        Object.assign(
          {},
          prev,
          values instanceof Function ? values(prev) : values
        )
      ),
    resetForm: () => {
      setEntity(settings.initialEntity);
      setStatus(initialStatus);
    },
  };
}
