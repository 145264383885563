import React from "react";
import { Button } from "../button";
import { Field } from "../form/field";
import { TextInput } from "../form/text-input";
import { ModalPortal } from "./ModalPortal";
import { classNames } from "../../helpers/misc";

const closeModal = (action, setModal) =>
  setModal((prev) => {
    const { alert, prompt, confirm, generic } = prev;
    const { onClose } = alert;
    const { onSend } = prompt;
    const { onAct } = confirm;

    if (alert.opened) {
      onClose && onClose();
    } else if (prompt.opened && action !== "close") {
      onSend && onSend(prompt.value);
    } else if (confirm.opened) {
      onAct && onAct(action === "submit");
    } else if (generic.opened) {
      generic.onClose && generic.onClose();
    }

    return {
      alert: {
        message: "",
        opened: false,
      },
      confirm: {
        message: "",
        opened: false,
      },
      prompt: {
        message: "",
        opened: false,
        title: "",
        value: "",
      },
      generic: {
        title: "",
        message: "",
        opened: false,
        hidePrint: false,
      },
    };
  });

const renderAlert = (message, setModal) => (
  <div className="modal-content">
    <div className="modal-header"></div>
    <div className="modal-body">{message}</div>
    <div className="modal-footer">
      <Button
        customClassName="btn-primary"
        title="Ok"
        icon="fas fa-check"
        onClick={(_) => closeModal("submit", setModal)}
      />
    </div>
  </div>
);

const renderConfirm = (message, setModal) => (
  <div className="modal-content">
    <div className="modal-header"></div>
    <div className="modal-body">{message}</div>
    <div className="modal-footer">
      <Button
        customClassName="btn-primary btn-block"
        title="Sim"
        icon="fas fa-check"
        onClick={(_) => closeModal("submit", setModal)}
      />

      <Button
        customClassName="btn-danger btn-block"
        title="Não"
        icon="fas fa-times"
        onClick={(_) => closeModal("cancel", setModal)}
      />
    </div>
  </div>
);

const renderPrompt = (prompt, setModal) => (
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">{prompt.title}</h5>
    </div>
    <div className="modal-body">
      <Field label={prompt.message}>
        <TextInput
          placeholder="Digite..."
          type={prompt.type}
          value={prompt.value}
          onChange={(value) =>
            setModal((prev) => ({
              ...prev,
              prompt: {
                ...prev.prompt,
                value,
              },
            }))
          }
        />
      </Field>
    </div>
    <div className="modal-footer">
      <Button
        customClassName="btn-secondary"
        icon="fas fa-angle-left valign-middle"
        title="Voltar"
        onClick={(_) => closeModal("close", setModal)}
      />

      <Button
        customClassName="btn-primary"
        icon="fas fa-check"
        title="Alterar limite"
        onClick={(_) => closeModal("submit", setModal)}
      />
    </div>
  </div>
);

const renderGeneric = (title, message, hidePrint, setModal) => (
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title">
        <strong>{title}</strong>
      </h5>
    </div>
    <div className="modal-body">{message}</div>
    <div className="modal-footer">
      {!hidePrint && (
        <Button
          customClassName="btn-primary"
          icon="fas fa-print"
          title="Imprimir"
          onClick={() => window.print()}
        />
      )}
      <Button
        customClassName="btn-primary"
        icon="fas fa-times"
        title="Fechar"
        onClick={(_) => closeModal("close", setModal)}
      />
    </div>
  </div>
);

export const renderModal = ({ alert, prompt, confirm, generic }, setModal) => {
  const show =
    alert.opened || prompt.opened || confirm.opened || generic.opened;

  return (
    <ModalPortal>
      {show && (
        <>
          <div className={classNames("modal fade", { show })} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              {alert.opened && renderAlert(alert.message, setModal)}
              {confirm.opened && renderConfirm(confirm.message, setModal)}
              {prompt.opened && renderPrompt(prompt, setModal)}
              {generic.opened &&
                renderGeneric(
                  generic.title,
                  generic.message,
                  generic.hidePrint,
                  setModal
                )}
            </div>
          </div>

          <div className={classNames("modal-backdrop fade", { show })} />
        </>
      )}
    </ModalPortal>
  );
};
