import React from "react";
import { DynamicMenu } from "../../core/components/DynamicMenu/DynamicMenu";

import { HrnCalculate } from "./tools/HrnCalculate";
import { NbrCalculate } from "./tools/NbrCalculate";

export const AnalysisTools = () => {
  return (
    <>
      <DynamicMenu
        menu={[
          {
            key: 1,
            value: "Cálculo HRN",
            icon: "fas fa-calculator fa-lg fa-fw mr-3",
            component: <HrnCalculate />,
          },
          {
            key: 2,
            value: "Categoria NBR 14153",
            icon: "fas fa-calculator fa-lg fa-fw mr-3",
            component: <NbrCalculate />,
          },
        ]}
      ></DynamicMenu>
    </>
  );
};
