import React, { useState } from "react";

import { classNames } from "../../../core/helpers/misc";
import { getRiskCategory } from "../../riskanalysis/utils";

export const NbrCalculate = () => {
  const [risk, setRisk] = useState({
    severityCategory: 1,
    frequencyCategory: 1,
    possibilityCategory: 1,
  });

  const category = getRiskCategory(risk);

  return (
    <div className="col-md-9">
      <div className="ptm-3 pb-2 display-inline-table font-medium-5">
        <h5>
          <div>Cálculo NBR 14153</div>
        </h5>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="module-title">
            <h2>S - Severidade do ferimento</h2>
            <p>
              <b>S1 -</b> Ferimento leve (normalmente reversível)
            </p>
            <p>
              <b>S2 -</b> Ferimento sério (normalmente irreversível, incluindo
              morte)
            </p>
          </div>

          <div className="check-group">
            <div className="checkbox noui">
              <input
                id="catSev1"
                type="checkbox"
                checked={risk.severityCategory === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    severityCategory: 1,
                    frequencyCategory: 1,
                    possibilityCategory: 1,
                  }))
                }
              />
              <label htmlFor="catSev1">S1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="catSev2"
                type="checkbox"
                checked={risk.severityCategory === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    severityCategory: 2,
                  }))
                }
              />
              <label htmlFor="catSev2">S2</label>
            </div>
          </div>

          <div className="module-title">
            <h2>F - Frequência e/ou tempo de exposição ao perigo</h2>
            <p>
              <b>F1 -</b> Raro a relativamente frequente e/ou baixo tempo de
              exposição
            </p>
            <p>
              <b>F2 -</b> Frequente a continuo e/ou tempo de exposição longo
            </p>
          </div>

          <div
            className={classNames("check-group", {
              disabled: risk.severityCategory === 1,
            })}
          >
            <div className="checkbox noui">
              <input
                id="catFreq1"
                type="checkbox"
                checked={risk.frequencyCategory === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    frequencyCategory: 1,
                  }))
                }
              />
              <label htmlFor="catFreq1">F1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="catFreq2"
                type="checkbox"
                checked={risk.frequencyCategory === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    frequencyCategory: 2,
                  }))
                }
              />
              <label htmlFor="catFreq2">F2</label>
            </div>
          </div>

          <div className="module-title">
            <h2>P - Possibilidade de evitar o perigo</h2>
            <p>
              <b>P1 -</b> Possível sob condições específicas
            </p>
            <p>
              <b>P2 -</b> Quase nunca possível
            </p>
          </div>

          <div
            className={classNames("check-group mb-0", {
              disabled: risk.severityCategory === 1,
            })}
          >
            <div className="checkbox noui">
              <input
                id="catPos1"
                type="checkbox"
                checked={risk.possibilityCategory === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    possibilityCategory: 1,
                  }))
                }
              />
              <label htmlFor="catPos1">P1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="catPos2"
                type="checkbox"
                checked={risk.possibilityCategory === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    possibilityCategory: 2,
                  }))
                }
              />
              <label htmlFor="catPos2">P2</label>
            </div>
          </div>

          <div className="hrn-bar category">
            Categoria: <span>{category}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
