import React, { useState, useEffect } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { Link } from "../../core/components/route";
import { toLocaleDateTime } from "../../core/utils/format";
import { parseNumber } from "../../core/utils/parse";
import { get, getById, getList } from "../../service";
import { UserEnginnerTopPageDashBord, userType } from "../user";
import { EngineerAnalysisList } from "./EngineerAnalysisList";
import { EngineerStudiesList } from "./EngineerStudiesList";
import { TechnicalReportList } from "./TechnicalReportList";

import { GroundingReportList } from "./GroundingReportList";
import { ManualReportList } from "./ManualReportList";
import { ProceduresReportList } from "./ProceduresReportList";
import { TrainingReportList } from "./TrainingReportList";

import { getAnalisyName } from "./utils";
import { getStudiesName } from "./utils";
import { getTecnicalReportName } from "./utils";

import { getProceduresReportName } from "./utils";
import { getManualReportName } from "./utils";
import { getTrainingReportName } from "./utils";
import { getGroundingReportName } from "./utils";

export const AnalysisPage = ({ global, route }) => {
  const [info, setInfo] = useState({
    completedAnalysisCount: 0,
    currentAnalysisBalance: 0,
    firstAnalisy: "",
    maxAnalysis: 0,
    riskCount: 0,
    stetesAnalisys: [],
    teamSize: 0,
    totalAnalysis: 0,
    totalClients: 0,
    totalStates: 0,
    usedAnalysis: 0,
    isIlimitedUser: false,
  });
  const [key, setKey] = useState("analise");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [customer, setCustomer] = useState({});
  const [analysis, setAnalysis] = useState([]);
  const [studies, setStudies] = useState([]);
  const [proceduresReports, setProceduresReports] = useState([]);
  const [manualReports, setManualReports] = useState([]);
  const [trainingReports, setTrainingReports] = useState([]);
  const [groundingReports, setGroundingReports] = useState([]);
  const [technicalReports, setTechnicalReports] = useState([]);

  const [filter, setFilter] = useState({
    code: "",
    resume: "",
  });

  const filteredAnalysis = [
    ["code", filter.code],
    ["sector", filter.resume],
  ].reduce((analysis, [key, value]) => {
    return value !== ""
      ? analysis.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getAnalisyName(i)}${i.machine.sectorName}${toLocaleDateTime(
              i.createdAt
            )}`
          )
        )
      : analysis;
  }, analysis);

  const filteredStudies = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((studies, [key, value]) => {
    return value !== ""
      ? studies.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getStudiesName(i)}${i.resume}${toLocaleDateTime(i.createdAt)}`
          )
        )
      : studies;
  }, studies);

  const filteredProceduresReports = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((proceduresReports, [key, value]) => {
    return value !== ""
      ? proceduresReports.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getProceduresReportName(i, customer)}${i.name}${toLocaleDateTime(
              i.dateFile
            )}`
          )
        )
      : proceduresReports;
  }, proceduresReports);

  const filteredManualReports = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((manualReports, [key, value]) => {
    return value !== ""
      ? manualReports.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getManualReportName(i, customer)}${i.name}${toLocaleDateTime(
              i.dateFile
            )}`
          )
        )
      : manualReports;
  }, manualReports);

  const filteredTrainingReports = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((trainingReports, [key, value]) => {
    return value !== ""
      ? trainingReports.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getTrainingReportName(i, customer)}${i.name}${toLocaleDateTime(
              i.dateFile
            )}`
          )
        )
      : trainingReports;
  }, trainingReports);

  const filteredGroundingReports = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((groundingReports, [key, value]) => {
    return value !== ""
      ? groundingReports.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getGroundingReportName(i, customer)}${i.name}${toLocaleDateTime(
              i.dateFile
            )}`
          )
        )
      : groundingReports;
  }, groundingReports);

  const filteredTechnicalReports = [
    ["code", filter.code],
    ["resume", filter.resume],
  ].reduce((technicalReports, [key, value]) => {
    return value !== ""
      ? technicalReports.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            `${getTecnicalReportName(i, customer)}${i.name}${toLocaleDateTime(
              i.dateFile
            )}`
          )
        )
      : technicalReports;
  }, technicalReports);

  useEffect(() => {
    const key = route.match.params.id;

    setLoading(true);

    Promise.all([
      getById("user", parseNumber(key), null),
      get(`dashboard.client?id=${parseNumber(key)}`),
      getList("analysis", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("studies", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("proceduresReports", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("manualReports", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("trainingReports", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("groundingReports", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
      getList("technicalReports", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: parseNumber(key),
              },
            ],
          },
        ],
      }),
    ])
      .then(
        ([
          customer,
          bord,
          analisis,
          studies,
          proceduresReports,
          manualReports,
          trainingReports,
          groundingReports,
          technicalReports,
        ]) => {
          setCustomer(customer);
          setAnalysis(analisis.items);
          setStudies(studies.items);
          setProceduresReports(proceduresReports.items);
          setManualReports(manualReports.items);
          setTrainingReports(trainingReports.items);
          setGroundingReports(groundingReports.items);
          setTechnicalReports(technicalReports.items);
          setInfo(bord);
        }
      )
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => global.modal.alert(err.message));
  }, [refresh]);

  useEffect(() => {
    Promise.all([get(`dashboard.client?id=${customer.id}`)])
      .then(([bord]) => {
        setInfo(bord);
      })
      .finally(() => {
        setRefresh(false);
      })
      .catch((err) => global.modal.alert(err.message));
  }, [refresh]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md">
          <div className="card">
            <div className="card-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-auto mr-auto pl-0">
                    <h5 className="card-title">
                      <div className="customer">
                        <i className="fas fa-tachometer-alt"></i> Visão geral do
                        cliente
                        <span className="dash">/</span>
                        <span className="data cut">
                          Cliente {`${customer.name}`}{" "}
                          <i
                            className="fas fa-check-circle valign-middle fa-xs"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <UserEnginnerTopPageDashBord
                customer={customer}
                global={global}
                route={route}
                loading={loading || refresh}
                userInfo={info}
              ></UserEnginnerTopPageDashBord>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <div className="card">
            <div className="card-body">
              <div className="container-fluid mt-1">
                <div className="row">
                  <div className="col-md mr-auto pl-0"></div>

                  <div className="col-md-2 custom-input-field">
                    <TextInput
                      label="Filtrar"
                      customClassName="custom"
                      icon="fas fa-search"
                      value={filter.resume}
                      onChange={(resume) => {
                        setFilter((prev) => ({ ...prev, resume }));
                      }}
                    />
                  </div>

                  <div className="col-md-auto"></div>

                  <div className="col-auto no-padding">
                    <Button
                      customClassName="btn-secondary mtm-2 mt-0 mb-0"
                      title="Voltar"
                      icon="fas fa-angle-left valign-middle"
                      onClick={(ev) => {
                        route.history.push("/clientes");
                      }}
                      //onClick={() => route.history.goBack()}
                    />
                  </div>
                </div>
              </div>
              <Tabs id="documento" activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab
                  eventKey="analise"
                  title={
                    <React.Fragment>
                      Análises de Risco (AR)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {analysis.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os equipamentos e análises de risco
                    criadas para este cliente. Da mais recente para a mais
                    antiga.
                  </p>
                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Nova Análise"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/analise/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <EngineerAnalysisList
                      userinfo={info}
                      fetching={loading || refresh}
                      items={filteredAnalysis}
                      setRefresh={setRefresh}
                      global={global}
                      route={route}
                    />

                    {analysis.map((a) => (
                      <Link
                        key={a.id}
                        className="card-analysis"
                        to={`/analise/${a.id}`}
                      />
                    ))}
                  </div>
                </Tab>

                <Tab
                  eventKey="estudo"
                  title={
                    <React.Fragment>
                      Estudos de Adequação (EA)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {studies.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os estudos de adequação feitos com base
                    nos equipamentos adicionados anteriormente. Do mais recente
                    para o mais antigo.
                  </p>
                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Novo Estudo"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/estudo/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <EngineerStudiesList
                      fetching={loading || refresh}
                      items={filteredStudies}
                      setRefresh={setRefresh}
                      global={global}
                      route={route}
                    />

                    {analysis.map((a) => (
                      <Link
                        key={a.id}
                        className="card-analysis"
                        to={`/estudo/${a.id}`}
                      />
                    ))}
                  </div>
                </Tab>

                <Tab
                  eventKey="laudo"
                  title={
                    <React.Fragment>
                      Laudos Técnicos (LT)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {technicalReports.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os laudos técnicos criados com base nas
                    análises de risco. Do mais recente para o mais antigo.
                  </p>

                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Novo Laudo"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/laudo/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <TechnicalReportList
                      fetching={loading || refresh}
                      items={filteredTechnicalReports}
                      setRefresh={setRefresh}
                      customer={customer}
                      global={global}
                      route={route}
                    />
                  </div>
                </Tab>

                <Tab
                  eventKey="Procedimentos"
                  title={
                    <React.Fragment>
                      Procedimentos (PR)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {proceduresReports.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os procedimentos criados com base nas
                    análises de risco. Do mais recente para o mais antigo.
                  </p>

                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Novo processo"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/processo/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <ProceduresReportList
                      fetching={loading || refresh}
                      items={filteredProceduresReports}
                      setRefresh={setRefresh}
                      customer={customer}
                      global={global}
                      route={route}
                    />
                  </div>
                </Tab>

                <Tab
                  eventKey="manuais"
                  title={
                    <React.Fragment>
                      Manuais (MA)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {manualReports.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os manuais criados com base nas análises
                    de risco. Do mais recente para o mais antigo.
                  </p>

                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Novo manual"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/manual/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <ManualReportList
                      fetching={loading || refresh}
                      items={filteredManualReports}
                      setRefresh={setRefresh}
                      customer={customer}
                      global={global}
                      route={route}
                    />
                  </div>
                </Tab>

                <Tab
                  eventKey="capacitacao"
                  title={
                    <React.Fragment>
                      Capacitações (CP)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {trainingReports.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todas as capacitações criadas com base nas
                    análises de risco. Do mais recente para o mais antigo.
                  </p>

                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Nova capacitação"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/capacitacao/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <TrainingReportList
                      fetching={loading || refresh}
                      items={filteredTrainingReports}
                      setRefresh={setRefresh}
                      customer={customer}
                      global={global}
                      route={route}
                    />
                  </div>
                </Tab>

                <Tab
                  eventKey="aterramento"
                  title={
                    <React.Fragment>
                      Laudos de Aterramento (LAT)
                      {loading ? (
                        <i className="ml-2 fas fa-spinner fa-spin fa-1x" />
                      ) : (
                        <Badge className="ml-2 pt-1 pb-1" pill variant="light">
                          {groundingReports.length}
                        </Badge>
                      )}
                    </React.Fragment>
                  }
                  tabClassName="text-center width-15-per-mobile"
                >
                  <p>
                    Listagem de todos os laudos de aterramento criados com base
                    nas análises de risco. Do mais recente para o mais antigo.
                  </p>

                  <div className="container-fluid mt-1">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>
                      <div className="col-auto no-padding">
                        {[
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            disabled={
                              global.operator.isTrial &&
                              !global.operator.hasTrialLimit
                            }
                            customClassName="btn-success mtm-2 ml-1 mb-0 valign-middle"
                            title="Novo laudo"
                            icon=""
                            onClick={() =>
                              route.history.push({
                                pathname: "/aterramento/cadastro",
                                state: { customer },
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analises mt-2">
                    {loading && (
                      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                        <p>
                          <Skeleton duration={1.5} height={65} count={3} />
                        </p>
                      </SkeletonTheme>
                    )}

                    <GroundingReportList
                      fetching={loading || refresh}
                      items={filteredGroundingReports}
                      setRefresh={setRefresh}
                      customer={customer}
                      global={global}
                      route={route}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="card-footer">
              <small>
                <i className="fas fa-question fa-fw fa-sm"></i> Listagem de
                todos os documentos criados a partir dos equipamentos. Mais
                recentes primeiro.
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
