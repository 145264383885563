import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "../../core/components/route";
import { getRiskClassAndDefinition } from "../riskanalysis/utils";
import { userType } from "./utils";

const RenderCardUserInfo = ({
  global,
  userInfo,
  creditPercent,
  whenLoading,
}) => {
  return (
    <div className="card card-primary">
      <div className="card-body">
        <div className="float-right">
          <i className="fas fa-wallet"></i>
        </div>
        <p>Seus créditos disponíveis</p>

        {whenLoading ? (
          <SkeletonTheme color="#44037a" highlightColor="#5a1096">
            <p>
              <Skeleton height={35} count={1} />
            </p>
          </SkeletonTheme>
        ) : userInfo.maxAnalysis ? (
          <div>
            {global.operator.isTrial ? (
              <h4>
                {userInfo.usedAnalysis}{" "}
                <sub> / {userInfo.maxAnalysis} grátis</sub>
              </h4>
            ) : (
              <h4>
                {userInfo.usedAnalysis}{" "}
                <sub> de {userInfo.maxAnalysis} usados</sub>
              </h4>
            )}
          </div>
        ) : (
          <h4>
            <i className="fas fa-infinity" /> Ilimitado
          </h4>
        )}
      </div>
      <div className="progress">
        <div
          className="progress-bar bg-success"
          style={{ width: `${creditPercent}%` }}
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="card-body">
        {!global.operator.isTrial && (
          <button
            disabled
            type="button"
            className="btn btn-outline-normatiza-secondary overlap-white btn-sm mb-0 mt-0 more"
            tabIndex={0}
          >
            <span>Meu Plano</span>{" "}
            <i className="fas fa-hand-holding-usd valign-middle" />
          </button>
        )}

        {global.operator.isTrial && (
          <button
            type="button"
            className="btn btn-outline-normatiza-secondary overlap-white btn-sm mb-0 mt-0 more"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              // URL do Whatsapp Aqui
              window.open("https://normatiza.app/desbloquear", "_blank");
            }}
          >
            <span>Desbloquear Recursos</span>{" "}
            <i className="fab fa-whatsapp valign-middle" />
          </button>
        )}
      </div>
    </div>
  );
};

export const UserHomeTopPageDashBord = ({
  global,
  userInfo,
  loading = true,
}) => {
  const [completedPercent] = useState(
    (userInfo.completedAnalysisCount * 100) / userInfo.totalAnalysis
  );
  const [creditPercent] = useState(
    global.operator.maxAnalysis
      ? (userInfo.usedAnalysis * 100) / userInfo.maxAnalysis
      : 100
  );

  return (
    <div className="row">
      <div className="col-md customer-analytics">
        <RenderCardUserInfo
          global={global}
          whenLoading={loading}
          userInfo={userInfo}
          creditPercent={creditPercent}
        ></RenderCardUserInfo>
      </div>

      <div className="col-md customer-analytics">
        <div className="card">
          <div className="card-body">
            <div className="float-right">
              <i className="fas fa-clipboard-check"></i>
            </div>
            <p>Equipamentos analisados</p>
            {loading ? (
              <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                <p>
                  <Skeleton height={35} count={1} />
                </p>
              </SkeletonTheme>
            ) : (
              <h4>
                {userInfo.completedAnalysisCount}{" "}
                <span className="percentage-completed float-right text-right">
                  {completedPercent ? `${completedPercent.toFixed(0)}` : 0}{" "}
                  <i className="fas fa-percentage" />
                </span>
              </h4>
            )}
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              style={{ width: `${completedPercent}%` }}
              role="progressbar"
            ></div>
          </div>
          <div className="card-body">
            <Link
              className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
              to={"/clientes"}
            >
              <span>Ver clientes</span> <i className="" />
            </Link>
          </div>
        </div>
      </div>

      <div className="col-md customer-analytics">
        <div className="card">
          <div className="card-body">
            <div className="float-right">
              <i className="fas fa-clipboard-check"></i>
            </div>
            <p>Pontos analisados</p>
            {loading ? (
              <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                <p>
                  <Skeleton height={35} count={1} />
                </p>
              </SkeletonTheme>
            ) : (
              <h4>{userInfo.riskCount}</h4>
            )}
          </div>
          <div className="progress no-progress">
            <div className="progress-bar bg-success" role="progressbar"></div>
          </div>
          <div className="card-body ">
            <Link
              className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
              to={"/clientes"}
            >
              <span>Ver clientes</span> <i className="" />
            </Link>{" "}
          </div>
        </div>
      </div>

      {[userType.ENGINEER].includes(global.operator.type) && (
        <div className="col-md customer-analytics">
          <div className="card">
            <div className="card-body">
              <div className="float-right">
                <i className="fas fa-users"></i>
              </div>
              <p>Equipe</p>
              {loading ? (
                <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                  <p>
                    <Skeleton height={35} count={1} />
                  </p>
                </SkeletonTheme>
              ) : (
                <h4>{userInfo.teamSize}</h4>
              )}
            </div>
            <div className="progress no-progress">
              <div className="progress-bar bg-success no-progress"></div>
            </div>
            <div className="card-body">
              <Link
                disabled={true}
                className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
                to={"equipe"}
              >
                <span>Ver Membros</span> <i className="" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const UserEnginnerTopPageDashBord = ({
  global,
  route,
  loading,
  userInfo,
  customer,
}) => {
  const completedPercent =
    (userInfo.completedAnalysisCount * 100) / userInfo.totalAnalysis;
  const creditPercent = global.operator.maxAnalysis
    ? (userInfo.usedAnalysis * 100) / userInfo.maxAnalysis
    : 100;
  return (
    <div className="row">
      {[userType.ENGINEER, userType.GUESTENGINEER].includes(
        global.operator.type
      ) && (
        <div className="col-md-3 customer-analytics">
          <RenderCardUserInfo
            global={global}
            whenLoading={loading}
            userInfo={userInfo}
            creditPercent={creditPercent}
          ></RenderCardUserInfo>
        </div>
      )}

      <div className="col-md customer-analytics">
        <div className="card">
          <div className="card-body">
            <div className="float-right">
              <i className="fas fa-clipboard-check"></i>
            </div>
            <p>Equipamentos analisados</p>
            {loading ? (
              <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                <p>
                  <Skeleton height={35} count={1} />
                </p>
              </SkeletonTheme>
            ) : (
              <h4>
                {userInfo.completedAnalysisCount}{" "}
                <span className="percentage-completed float-right text-right">
                  {completedPercent ? `${completedPercent.toFixed(0)}` : 0}{" "}
                  <i className="fas fa-percentage" />
                </span>
              </h4>
            )}
          </div>

          <div className="progress">
            <div
              className="progress-bar bg-success"
              style={{ width: `${completedPercent}%` }}
              role="progressbar"
            ></div>
          </div>
          <div>
            {[
              userType.ENGINEER,
              userType.GUESTENGINEER,
              userType.MANAGER,
            ].includes(global.operator.type) && (
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
                  tabIndex={0}
                  onClick={() => {
                    route.history.push(`/cliente/${customer.id}/relatorio`);
                  }}
                >
                  <span>Ver relatórios</span> <i className="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-md customer-analytics">
        <div className="card">
          <div className="card-body">
            <div className="float-right">
              <i className="fas fa-chart-bar"></i>
            </div>
            <p>HRN aproximado</p>

            {loading ? (
              <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                <p>
                  <Skeleton height={35} count={1} />
                </p>
              </SkeletonTheme>
            ) : (
              <h4
                className={
                  getRiskClassAndDefinition(userInfo.clientRiskValue)
                    .description
                }
              >
                {userInfo.clientRiskValue}
              </h4>
            )}
          </div>
          <div
            className={`progress ${
              getRiskClassAndDefinition(userInfo.clientRiskValue).class
            }`}
          >
            <div
              className="progress-bar bg-success no-progress"
              style={{ width: `10%` }}
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div>
            {[
              userType.MANAGER,
              userType.ENGINEER,
              userType.GUESTENGINEER,
            ].includes(global.operator.type) && (
              <div className="card-body">
                <Link
                  className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
                  to={`/cliente/${customer.id}/relatorio`}
                >
                  <span>Ver matriz de riscos</span> <i className="" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-md customer-analytics">
        <div className="card">
          <div className="card-body">
            <div className="float-right">
              <i className="fas fa-file"></i>
            </div>
            <p>Documentação extra</p>
            {loading ? (
              <SkeletonTheme color="#b2b0b0" highlightColor="#bcbaba">
                <p>
                  <Skeleton height={35} count={1} />
                </p>
              </SkeletonTheme>
            ) : (
              <h4>{userInfo.totalDocs || 0}</h4>
            )}
          </div>
          <div className="progress no-progress">
            <div
              className="progress-bar bg-success no-progress"
              style={{ width: `100%` }}
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div>
            {[userType.ENGINEER, userType.GUESTENGINEER].includes(
              global.operator.type
            ) && (
              <div className="card-body">
                <Link
                  className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
                  to={`/cliente/${customer.id}/extras`}
                >
                  <span>Ver documentos extras</span> <i className="" />
                </Link>
              </div>
            )}
            {global.operator.type === userType.MANAGER && (
              <div className="card-body">
                <Link
                  className="btn btn-outline-normatiza-primary btn-sm mb-0 mt-0 more"
                  to={`/cliente/${customer.id}/extras`}
                >
                  <span>Ver documentos extras</span> <i className="" />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
