import React, { useState, useEffect } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NoCustomer from "../../assets/img/011-customer.png";
import DefaultImage from "../../assets/img/avatar-default.png";
import Brasil from "../../assets/img/br.png";
import { config } from "../../config";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { localStore } from "../../core/utils/store";
import { get, getList, post } from "../../service";
import { userType } from "../user";

function refreshPage() {
  window.location.reload(false);
}

const CustomerList = ({ items, onClick, global, CustomersImages }) =>
  items.length === 0 ? null : (
    <div className="row">
      {items
        .map((u) => (
          <div key={u.id} className="col-md-2-5 d-flex pb-3">
            <div className="card client-list text-center">
              <a onClick={() => onClick(u.id)}>
                <div className="client-overlay pt-1">
                  <img
                    className="card-img-top rounded-circle p-1 overlay reeed"
                    src={
                      CustomersImages.find((c) => c.id === u.id)?.url ||
                      DefaultImage
                    }
                    alt="Acessar Cliente"
                  ></img>
                </div>
                <div className="card-body client-list p-2 mtn-1">
                  <h4 className="card-title cut">{u.name}</h4>
                  <p className="card-text small cut">
                    <img
                      className="valign-baseline width-10-per pr-1"
                      src={Brasil}
                      alt="Brasil"
                    ></img>{" "}
                    <span className="text-capitalize">
                      {u.state}, {u.city}
                    </span>
                  </p>
                  <p className="card-text small uppercase">
                    <i className="fas fa-check"></i> {u.totalEquipments}{" "}
                    equipamentos analisadas
                  </p>
                </div>
              </a>
              <div className="card-footer client-list p-1">
                {global.operator.type === userType.MANAGER && (
                  <Button
                    title="Equipamentos"
                    icon="fas fa-file-pdf"
                    customClassName="btn-outline-light valign-baseline btn-sm mb-0 mt-0"
                    onClick={() => onClick(u.id)}
                  />
                )}

                {global.operator.type === userType.MANAGER && (
                  <Dropdown bsPrefix="dropdown-normatiza">
                    <Dropdown.Toggle
                      bsPrefix="btn btn-outline-light valign-baseline btn-sm ml-1 mb-0 mt-0"
                      id="dropdown-normatiza"
                    >
                      <i className="fas fa-ellipsis-v valign-baseline"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`/cliente/${u.id}/extras`}>
                        <i className="fas fa-folder-open"></i> Ver Documentos
                      </Dropdown.Item>
                      <Dropdown.Item href={`/cliente/${u.id}/relatorio`}>
                        <i className="fas fa-chart-bar"></i> Ver Relatórios
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {global.operator.type === userType.ANALYST && (
                  <Button
                    title="Ver Equipamentos"
                    icon="fas fa-eye"
                    customClassName="btn-outline-light valign-baseline btn-sm mb-0 mt-0"
                    onClick={() => onClick(u.id)}
                  />
                )}

                {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                  global.operator.type
                ) && (
                  <Button
                    title="Ver Cliente"
                    icon="fas fa-eye valign-middle"
                    customClassName="btn-outline-light valign-baseline btn-sm mb-0 mt-0"
                    onClick={() => onClick(u.id)}
                  />
                )}

                {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                  global.operator.type
                ) && (
                  <Dropdown bsPrefix="dropdown-normatiza">
                    <Dropdown.Toggle
                      bsPrefix="btn btn-outline-light valign-baseline btn-sm ml-1 mb-0 mt-0"
                      id="dropdown-normatiza"
                    >
                      <i className="fas fa-ellipsis-v valign-middle"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`/cliente/cadastro/${u.id}`}>
                        <i className="fas fa-user-edit fa-fw"></i> Editar
                        cliente
                      </Dropdown.Item>
                      <Dropdown.Item href={`/cliente/${u.id}/extras`}>
                        <i className="fas fa-folder-open fa-fw"></i> Documentos
                      </Dropdown.Item>
                      <Dropdown.Item href={`/cliente/${u.id}/relatorio`}>
                        <i className="fas fa-chart-bar fa-fw"></i> Relatórios
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        ))
        .reverse()}
    </div>
  );

export const CustomerPage = ({ route, global, simpleView }) => {
  const [name, setName] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [customersImages, setCustomersImages] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [info, setInfo] = useState({
    totalAnalysis: 0,
    completedAnalysis: 0,
    firstAnalisy: "",
    totalClients: 0,
    totalStates: 0,
    stetesAnalisys: [],
  });

  const [loading, setLoading] = useState(false);
  const filteredItems = [
    ["name", name],
    ["cpfCnpj", cpfCnpj],
  ].reduce((items, [key, value]) => {
    return value !== ""
      ? items.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            i[key]
          )
        )
      : items;
  }, customers);

  //simpleView
  useEffect(() => {
    setLoading(true);
    if (simpleView) {
      Promise.all([
        getList("user", {
          perPage: 5,
          sort: [["id", "DESC"]],
          filters: [{ items: [{ name: "type", value: userType.CUSTOMER }] }],
        }),
        get("user.logoClients"),
      ])
        .then(([{ items }, logos]) => {
          setCustomers(items);
          setCustomersImages(logos);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => global.modal.alert(err.message));
    } else {
      Promise.all([
        getList("user", {
          usePager: false,
          filters: [{ items: [{ name: "type", value: userType.CUSTOMER }] }],
        }),
        get("user.logoClients"),
      ])
        .then(([{ items }, logos]) => {
          setCustomers(items);
          setCustomersImages(logos);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => global.modal.alert(err.message));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (global.operator.type === userType.ENGINEER && !simpleView) {
      setLoading(true);
      get("dashboard.engineer")
        .then((done) => setInfo(done))
        .catch((err) => global.modal.alert(err.message));
    }
  }, []);

  useEffect(() => {
    if (!simpleView)
      post("user.login-info", global.operator).then((user) => {
        localStore.set(config.OPERATOR_KEY, {
          ...user,
          apiVersion: global.operator.version,
        });

        const newOperator = localStore.get(config.OPERATOR_KEY) || {};
        global.operator = newOperator;

        global.refresh();
      });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <>
          <SkeletonTheme color="#2E2F42" highlightColor="#404258">
            <p>
              <Skeleton
                duration={1.5}
                height={simpleView ? 180 : 80}
                count={simpleView ? 1 : 2}
              />
            </p>
          </SkeletonTheme>
        </>
      ) : (
        <div>
          <div>
            {!simpleView &&
              [userType.ENGINEER, userType.GUESTENGINEER].includes(
                global.operator.type
              ) && (
                <div className="row mb-3">
                  <div className="col-md">
                    <div className="card">
                      <div className="card-header">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-auto mr-auto pl-0">
                              <h5 className="card-title">
                                <div className="customer">
                                  <i className="fas fa-tachometer-alt "></i>{" "}
                                  Visão geral de clientes
                                </div>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md customer-analytics">
                            <div className="card">
                              <div className="card-body">
                                <div className="float-right">
                                  <i className="fas fa-user-check"></i>
                                </div>
                                <p>Clientes atendidos</p>
                                {loading ? (
                                  <SkeletonTheme
                                    color="#b2b0b0"
                                    highlightColor="#bcbaba"
                                  >
                                    <p>
                                      <Skeleton height={35} count={1} />
                                    </p>
                                    <p>
                                      <Skeleton height={15} count={1} />
                                    </p>
                                  </SkeletonTheme>
                                ) : (
                                  <span>
                                    <h5>
                                      {info.totalClients} cliente(s) em{" "}
                                      {info.totalStates} estado(s)
                                    </h5>
                                    {info.stetesAnalisys.map((a, index) => (
                                      <span
                                        key={index}
                                        className="badge badge-dark mr-1"
                                      >
                                        {a}
                                      </span>
                                    ))}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md customer-analytics">
                            <div className="card">
                              <div className="card-body">
                                <div className="float-right">
                                  <i className="fas fa-clipboard-check"></i>
                                </div>
                                <p>Análise de risco realizadas até hoje</p>
                                {loading ? (
                                  <SkeletonTheme
                                    color="#b2b0b0"
                                    highlightColor="#bcbaba"
                                  >
                                    <p>
                                      <Skeleton height={35} count={1} />
                                    </p>
                                    <p>
                                      <Skeleton height={15} count={1} />
                                    </p>
                                  </SkeletonTheme>
                                ) : (
                                  <div>
                                    <h5>{info.totalAnalysis} análises NR 12</h5>
                                    <small>
                                      <i className="fas fa-info-circle"></i>{" "}
                                      Somando equipamentos completados e não
                                      completados
                                    </small>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="row">
            <div className="col-md">
              <div className="card">
                <div className="card-content">
                  {!simpleView && (
                    <div className="card-header">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-auto mr-auto pl-0">
                            <OverlayTrigger
                              key="right"
                              placement="right"
                              overlay={
                                <Tooltip id={`tooltip-right`}>
                                  Acesse inventários, documentos, relatórios e
                                  análises de riscos das empresas!
                                </Tooltip>
                              }
                            >
                              <h5 id="showHelpCustomer" className="card-title">
                                {[
                                  userType.ENGINEER,
                                  userType.GUESTENGINEER,
                                  userType.ANALYST,
                                ].includes(global.operator.type) && (
                                  <span>
                                    <i className="fas fa-user-check"></i> Meus
                                    Clientes
                                  </span>
                                )}

                                {global.operator.type === userType.MANAGER && (
                                  <span>
                                    <i className="fas fa-user-check"></i> Minhas
                                    Empresas
                                  </span>
                                )}
                              </h5>
                            </OverlayTrigger>
                          </div>

                          <div className="col-md-2 custom-input-field">
                            <TextInput
                              label="Filtrar por nome"
                              customClassName="custom"
                              icon="fas fa-search"
                              value={name}
                              onChange={(name) => setName(name)}
                            />
                          </div>

                          <div className="col-md-2 custom-input-field">
                            <TextInput
                              label="Filtrar por CNPJ"
                              customClassName="custom"
                              icon="fas fa-search"
                              value={cpfCnpj}
                              onChange={(value) => setCpfCnpj(value)}
                            />
                          </div>

                          <div className="col-md-auto"></div>

                          {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                            global.operator.type
                          ) && (
                            <div className="col-auto no-padding">
                              <Button
                                disabled={
                                  global.operator.isTrial &&
                                  !global.operator.hasTrialClient
                                }
                                customClassName="btn-success mtm-2 mb-0"
                                title="Novo Cliente"
                                icon=""
                                onClick={() =>
                                  route.history.push("/cliente/cadastro")
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="card-body">
                    {filteredItems.length > 0 ? (
                      <CustomerList
                        items={filteredItems}
                        CustomersImages={customersImages}
                        global={global}
                        onClick={(id) => {
                          route.history.push(`/clientes/${id}/equipamentos`);
                        }}
                      />
                    ) : (
                      <div className="info-no-content">
                        <img
                          className="image"
                          alt="Não há análises cadastradas"
                          src={NoCustomer}
                        />
                        <div className="inventory">
                          Ainda não há clientes cadastrados ou que respondem a
                          este filtro
                          <Button
                            title="Atualizar"
                            icon="fas fa-sync"
                            customClassName="btn btn-outline-light btn-xs ml-1 mb-0 mt-0"
                            onClick={refreshPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {!simpleView && (
                    <div className="card-footer">
                      <small>
                        <i className="fas fa-question fa-fw fa-sm"></i> Listagem
                        de todos os clientes. Mais recentes primeiro.
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
