import { toast } from "react-toastify";

export function forceDownload(blob, name, prefixMensage = null) {
  showToast({
    text: `PDF ${prefixMensage} ${name} pronto para baixar!`,
    type: toastType.SUCESS,
  });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.style.display = "none";
  link.href = blobUrl;
  link.download = name;
  link.click();
  URL.revokeObjectURL(blobUrl);
}

export function forceDownloadUrl(url, fileName, prefixMensage = null) {
  showToast({
    text: `Arquivo ${prefixMensage??""} ${fileName} pronto para baixar!`,
    type: toastType.SUCESS,
  });
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export function classNames(...args) {
  const classes = [];

  args.forEach((arg) => {
    if (!!arg) {
      if (typeof arg === "string" || typeof arg === "number") {
        classes.push(arg);
      } else if (Array.isArray(arg) && arg.length) {
        const inner = classNames(...arg);

        if (inner) {
          classes.push(inner);
        }
      } else if (typeof arg === "object" && arg !== null) {
        for (const [key, value] of Object.entries(arg)) {
          if (value) {
            classes.push(key);
          }
        }
      }
    }
  });

  return classes.join(" ");
}

// Object
export const fillObject = (obj, value) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v instanceof Object ? fillObject(v, value) : value,
    }),
    {}
  );

export const isObject = (v) =>
  v instanceof Object && !(v instanceof Array) && !(v instanceof Date);

export const isEmptyOrDefault = (v) =>
  v === null ||
  v === undefined ||
  (v instanceof Array && v.length === 0) ||
  (isObject(v) && isEqual(v, {})) ||
  (typeof v === "number" && v === 0) ||
  (typeof v === "string" && v.trim() === "");

export const cleanObject = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => (isObject(v) ? [k, cleanObject(v)] : [k, v]))
    .filter(([_, v]) => !isEmptyOrDefault(v))
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const pick = (ks, o) => {
  const copy = {};
  ks.forEach((k) => (copy[k] = o[k]));
  return copy;
};

export function isEqual(a, b, keys) {
  if (!keys) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  return JSON.stringify(pick(keys, a)) === JSON.stringify(pick(keys, b));
}

// Fn
export function debounce(fn, time) {
  let timeout;

  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(context, args), time);
  };
}

export const toastType = {
  SUCESS: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
};

export const showToast = ({ text, type }) => {
  const toastConfig = {
    hideProgressBar: false,
    position: "top-right",
    rtl: true,
  };

  if (type == toastType.SUCESS) toast.success(text, toastConfig);
  if (type == toastType.INFO) toast.info(text, toastConfig);
  if (type == toastType.WARNING) toast.warning(text, toastConfig);
  if (type == toastType.ERROR) toast.error(text, toastConfig);
};
