import React, { useState, useEffect } from "react";
import { Button } from "../../core/components/button";
import { forceDownload, classNames } from "../../core/helpers/misc";
import { toLocaleDateTime } from "../../core/utils/format";
import { downloadReport, post } from "../../service";
import { getAnalisyName } from "./utils";
import NoAnalysisFilter from "../../assets/img/028-analysis.png";
import { userType } from "../user";
import { localStore } from "../../core/utils/store";
import { config } from "../../config";

function download(id, code, pdf, modal, setLoading) {
  setLoading(id);
  downloadReport(id, pdf, `${code}.${pdf ? "pdf" : "docx"}`)
    .then((blob) =>
      forceDownload(blob, `${code}.${pdf ? "pdf" : "docx"}`, "Análise")
    )
    .catch((err) => modal.alert(err.message))
    .finally(() => setLoading(0));
}
export const EngineerAnalysisList = ({
  fetching,
  items = [],
  route,
  global,
  userinfo,
  dashBoard,
  noMargin,
  setRefresh,
  simpleView,
}) => {
  const [downloadingPdf, setDownloadingPdf] = useState(0);

  return items.length === 0 ? (
    !fetching && (
      <div className="info-no-content">
        <img
          className="image"
          alt="Não há análises cadastradas"
          src={NoAnalysisFilter}
        />
        <div className="inventory">
          Ainda não há nenhuma análise criada para este cliente ou que
          correspondam ao filtro selecionado!
        </div>
      </div>
    )
  ) : !fetching && (
    <div className="table-responsive">
      <table
        className={classNames("table", {
          "mb-0": noMargin,
        })}
      >
        <tbody>
          {items.map((a, i) => {
            return (
              <tr key={i}>
                <th>
                  {a.createdDevice === 0 ? (
                    <i className="fas fa-lg fa-fw display-inline-table fa-mobile-alt valign-bottom"></i>
                  ) : (
                    <i className="fas fa-lg fa-fw display-inline-table fa-desktop valign-bottom"></i>
                  )}
                </th>
                <th>
                  <h6 className="list-title">ID da análise</h6>
                  <div className="cut-text-medium">{getAnalisyName(a)}</div>
                </th>
                <th>
                  <h6 className="list-title">Setor localizado</h6>
                  <div>{a.machine.sectorName}</div>
                </th>
                <th>
                  <h6 className="list-title">Data de criação</h6>
                  {toLocaleDateTime(a.createdAt)}
                </th>
                {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                  global.operator.type
                ) &&
                  !dashBoard && (
                    <th>
                      <h6 className="list-title">Análise está completa?</h6>
                      <input
                        type="checkbox"
                        className="switch analise"
                        checked={a.completed}
                        onChange={(ev) => {
                          if (
                            ev.target.checked &&
                            (userinfo.currentAnalysisBalance ||
                              userinfo.isIlimitedUser)
                          ) {
                            global.modal.confirm(
                              <>
                                <p className="alert-remove">
                                  <h6>Ação ao completar:</h6>
                                  <ul className="lista text-left">
                                    {userinfo.maxAnalysis ? (
                                      <li>
                                        Gastará <b>+1 crédito</b> de{" "}
                                        <b>
                                          {userinfo.currentAnalysisBalance}{" "}
                                          créditos restante
                                        </b>
                                      </li>
                                    ) : null}
                                    <li>
                                      Liberado o{" "}
                                      <b>download do relatório de análise</b>
                                    </li>
                                  </ul>
                                  <span>
                                    Deseja marcar a análise{" "}
                                    <b>{getAnalisyName(a)}</b> como completo?
                                  </span>
                                </p>
                              </>,

                              (confirmed) => {
                                if (confirmed) {
                                  if (setRefresh) {
                                    setRefresh(true);
                                  }
                                  post("analysis.complete", {
                                    id: a.id,
                                    completed: true,
                                  })
                                    .then(() => {
                                      a.completed = true;
                                      localStore.set(config.OPERATOR_KEY, {
                                        ...global.operator,
                                        usedAnalysis:
                                          global.operator.usedAnalysis + 1,
                                      });
                                      global.refresh();
                                    })
                                    .catch((err) =>
                                      global.modal.alert(err.message)
                                    );
                                }
                              }
                            );
                          } else if (
                            ev.target.checked &&
                            !userinfo.currentAnalysisBalance &&
                            !userinfo.isIlimitedUser
                          ) {
                            global.modal.alert(
                              "Você não possui a creditos de analise disponiveis para executar essa ação!"
                            );
                          }

                          if (!ev.target.checked) {
                            if (
                              global.operator.maxAnalysis === 0 ||
                              global.operator.canDeleteAnalisys
                            ) {
                              if (setRefresh) {
                                setRefresh(true);
                              }
                              post("analysis.complete", {
                                id: a.id,
                                completed: false,
                              })
                                .then(() => {
                                  a.completed = false;
                                  localStore.set(config.OPERATOR_KEY, {
                                    ...global.operator,
                                    usedAnalysis:
                                      global.operator.usedAnalysis - 1,
                                  });
                                  global.refresh();
                                })
                                .catch((err) =>
                                  global.modal.alert(err.message)
                                );
                            } else {
                              global.modal.alert(
                                "Você não possui a permissão para executar essa ação!"
                              );
                              a.completed = true;
                            }
                          }
                        }}
                      />
                    </th>
                  )}
                {!simpleView && (
                  <th className="no-wrap no-width">
                    <h6 className="list-title">Ações/download</h6>
                    {!dashBoard &&
                      [
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(global.operator.type) && (
                        <Button
                          title="Editar Análise"
                          icon="fas fa-edit"
                          customClassName="btn-light btn-xs mb-0 mt-0"
                          disabled={
                            downloadingPdf > 0 ||
                            ([userType.ANALYST].includes(
                              global.operator.type
                            ) &&
                              a.completed)
                          }
                          onClick={() => route.history.push(`/analise/${a.id}`)}
                        />
                      )}

                    <Button
                      title="Baixar análise"
                      id="relatorioPDF"
                      icon="fas fa-file-pdf"
                      customClassName="btn-primary btn-pdf btn-xs mb-0 mt-0"
                      disabled={downloadingPdf > 0 || !a.completed}
                      loading={downloadingPdf === a.id}
                      onClick={() =>
                        download(
                          a.id,
                          `${getAnalisyName(a)}`,
                          true,
                          global.modal,
                          setDownloadingPdf
                        )
                      }
                    />
                  </th>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
