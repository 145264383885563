import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "../../core/components/button";
import { useForm } from "../../core/hooks/form";
import { parseNumber } from "../../core/utils/parse";
import { addOrUpdate, get, getById } from "../../service";
import { userType } from "./utils";
import ModeloPadrao from "../../assets/img/modelo-padrao.png";
import ModeloNormatiza from "../../assets/img/modelo-normatiza.png";

function onSubmit(user, form, route, { modal, refresh, operator }) {
  addOrUpdate("user", user)
    .then((resp) => {
      form.setSubmitting(false);
      modal.alert("Modelo alterado com sucesso! ");
    })
    .catch((err) => form.setErrors({ _error: err.message }));
}

export const UserConfigsReport = ({ global, route }) => {
  const form = useForm(
    {
      initialEntity: {
        id: 0,
        reportType: 0,
      },
      validate: (values, errors) => {
        return errors;
      },
    },
    route
  );
  const { entity, errors, touched } = form;
  const [info, setInfo] = useState({
    completedAnalysisCount: 0,
    currentAnalysisBalance: 0,
    firstAnalisy: "",
    maxAnalysis: 0,
    riskCount: 0,
    stetesAnalisys: [],
    teamSize: 0,
    totalAnalysis: 0,
    totalClients: 0,
    totalStates: 0,
    usedAnalysis: 0,
    isIlimitedUser: false,
  });

  useEffect(() => {
    if (global.operator.type === userType.ENGINEER) {
      get("dashboard.engineer")
        .then((data) => {
          setInfo(data);
        })
        .catch((err) => global.modal.alert(err.message));
    }
  }, [global.loading]);

  useEffect(() => {
    if (!global.loading) {
      form.handleFetch({
        action: async (id, ac) => {
          const key = id || global.operator.id;

          const user = await getById("user", parseNumber(key), ac.signal);

          return user;
        },
        errorFn: (err) =>
          global.modal.alert(err.message, () => route.history.goBack()),
      });

      // eslint-disable-next-line
    }
  }, [global.loading]);

  return (
    <div>
      <form
        autoComplete="off"
        className="box is-clearfix"
        onSubmit={(ev) => {
          ev.preventDefault();
          form.handleSubmit((u) => onSubmit(u, form, route, global));
        }}
      >
        {form.fetching ? (
          <SkeletonTheme color="#2E2F42" highlightColor="#404258">
            <div className="row">
              <div className="col-md-3">
                <Skeleton height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={4} />
                </div>
              </div>

              <div className="col-md-9">
                <Skeleton width={200} height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={6} />
                </div>
              </div>
            </div>
          </SkeletonTheme>
        ) : (
          <div className="row">
            <div className="col-md-3">
              <div className="card my-profile">
                <div className="card-body p-2">
                  <ul className="nav flex-column">
                    <p className="menu-label pl-1">Conta</p>
                    <li className="nav-item mb-1">
                      <a className="nav-link" href="/meu-perfil">
                        <i className="fas fa-user-circle fa-lg fa-fw mr-3"></i>{" "}
                        Meus dados
                      </a>
                    </li>

                    <li className="nav-item mb-1">
                      <a
                        className="nav-link active"
                        href="/meu-perfil/personalizar"
                      >
                        <i className="fas fa-palette fa-lg fa-fw mr-3"></i>{" "}
                        Personalizar relatórios
                      </a>
                    </li>

                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/notificacoes"
                      >
                        <i className="fas fa-bell-slash fa-lg fa-fw mr-3"></i>{" "}
                        Notificações e e-mails
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/historico"
                      >
                        <i className="fas fa-history fa-lg fa-fw mr-3"></i>{" "}
                        Histórico de consumo
                      </a>
                    </li>
                    <p className="menu-label pl-1">Faturamento</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento"
                      >
                        <i className="fas fa-credit-card fa-lg fa-fw mr-3"></i>{" "}
                        Meu Plano
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento#planos"
                      >
                        <i className="fas fa-money-check-alt fa-lg fa-fw mr-3"></i>{" "}
                        Planos
                      </a>
                    </li>
                    <p className="menu-label pl-1">Outros</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/afiliados"
                      >
                        <i className="fas fa-hands-helping fa-lg fa-fw mr-3"></i>{" "}
                        Programa de Afiliados
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="customer-analytics mt-2">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="float-right">
                      <p className="display-inline font-medium-2">
                        {global.operator.maxAnalysis ? (
                          <div>
                            {global.operator.isTrial ? (
                              <b>
                                {info.usedAnalysis}
                                <sub> / {info.maxAnalysis} grátis</sub>
                              </b>
                            ) : (
                              <b>
                                {info.usedAnalysis}{" "}
                                <sub> de {info.maxAnalysis} usados</sub>
                              </b>
                            )}
                          </div>
                        ) : (
                          <b>
                            <i className="fas fa-infinity" /> Ilimitado
                          </b>
                        )}
                      </p>
                    </div>
                    <p>Seus créditos disponíveis:</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="ptm-3 pb-2 display-inline-table font-medium-5">
                <h5>
                  <div>Personalizar meus documentos e relatórios</div>
                </h5>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="mb-1">
                    <h5 className="card-title">
                      <div>
                        <i className="fas fa-palette"></i> Escolha um modelo
                      </div>
                    </h5>
                  </div>
                  <p style={{ fontWeight: 200 }}>
                    A escolha do modelo irá modificar o visual de seus
                    documentos e relatórios.
                  </p>
                  <div className="row mb-2">
                    <div className="col-md-3 select-model">
                      <label>
                        <input
                          type="radio"
                          name="model-default"
                          className="card-input-element"
                        />
                        <div className="model card-input">
                          <img src={ModeloPadrao} alt="Modelo Padrão" />
                          <div className="name p-2 mtn-4">
                            <h5>Modelo Padrão</h5>
                            <small>Sem marca Normatiza.</small>
                            <button
                              type="button"
                              value="0"
                              id=""
                              className={
                                entity.reportType === 0
                                  ? "btn btn-secondary btn-block btn-xs mb-0 mt-0 disabled"
                                  : "btn btn-success btn-block btn-xs mb-0 mt-0"
                              }
                              onClick={(type) =>
                                form.handleChange({
                                  path: "reportType",
                                  type,
                                  values: {
                                    reportType: 0,
                                  },
                                })
                              }
                            >
                              {entity.reportType === 0 ? (
                                <div>
                                  <i className="fas fa-check" /> Já está usando
                                  este modelo!
                                </div>
                              ) : (
                                <div>
                                  <i className="fas fa-plus" /> Usar este modelo
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="col-md-3 select-model">
                      <label>
                        <input
                          type="radio"
                          name="model-normatiza"
                          className="card-input-element"
                        />
                        <div className="model card-input">
                          <img src={ModeloNormatiza} alt="Modelo Normatiza" />
                          <div className="name p-2 mtn-4">
                            <h5>Modelo Normatiza</h5>
                            <small>Com a marca normatiza.</small>
                            <button
                              type="button"
                              value="1"
                              id=""
                              className={
                                entity.reportType === 1
                                  ? "btn btn-secondary btn-block btn-xs mb-0 mt-0 disabled"
                                  : "btn btn-success btn-block btn-xs mb-0 mt-0"
                              }
                              onClick={(type) =>
                                form.handleChange({
                                  path: "reportType",
                                  type,
                                  values: {
                                    reportType: 1,
                                  },
                                })
                              }
                            >
                              {entity.reportType === 1 ? (
                                <div>
                                  <i className="fas fa-check" /> Já está usando
                                  este modelo!
                                </div>
                              ) : (
                                <div>
                                  <i className="fas fa-plus" /> Usar este modelo
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <div className="alert alert-normatiza p-1" role="alert">
                        <i className="fas fa-exclamation"></i> Selecione o
                        modelo e clique em salvar para aplicar!
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="text-right">
                        <Button
                          customClassName="btn-success"
                          icon="fas fa-check valign-middle fa-sm"
                          title="Salvar Modelo"
                          loading={form.submitting}
                          disabled={form.submitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
