import React, { useState } from "react";
import { classNames } from "../../helpers/misc";
import { Link } from "../route";

export const DynamicMenu = (props) => {
  const menu = props.menu;
  const startedId = props.startedId | 0;
  const [currentMenu, setCurrentMenu] = useState(
    menu ? (startedId ? menu[startedId] : menu[0]) : {}
  );

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="card my-profile">
            <div className="card-body p-2">
              <ul className="nav flex-column">
                <p className="menu-label pl-1">Ferramentas</p>

                {menu.map((m) => (
                  <li
                    className={classNames(
                      "nav-item mb-1",
                      currentMenu.key == m.key ? "active" : ""
                    )}
                    key={m.key}
                  >
                    <a className="nav-link " onClick={() => setCurrentMenu(m)}>
                      <i className={classNames("menu-icon", m.icon)}></i>
                      {m.value}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {currentMenu.component}
      </div>
    </>
  );
};

export default DynamicMenu;
