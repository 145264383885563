import React from "react";
import { getPicture } from "../../../components/riskanalysis/utils";

export const PhotoBox = ({
  photo,
  title,
  noRemove,
  onChange,
  modal,
  readOnly,
  isClient,
}) => {
  return (
    <div
      className="quadrado"
      style={{
        backgroundImage: photo ? `url(${photo.data})` : "",
        cursor: readOnly ? "initial" : "pointer",
      }}
      onClick={() => !readOnly && getPicture(isClient).then(onChange)}
    >
      {title && <span>{title}</span>}
      {!photo && <i className="fas fa-camera-retro icon mt-1" />}
      {photo && !readOnly && !noRemove && (
        <div
          className="remove"
          onClick={(ev) => {
            ev.stopPropagation();
            modal.confirm(
              "Deseja remover essa foto?",
              (confirmed) => confirmed && onChange(null)
            );
          }}
        >
          Remover <i className="fas fa-times" />
        </div>
      )}
    </div>
  );
};
