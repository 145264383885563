import React, { useEffect, useMemo } from "react";
import { useForm } from "../../core/hooks/form";
import { Button } from "../../core/components/button";
import { ErrorMessage } from "../../core/components/form/error-message";
import { TextInput } from "../../core/components/form/text-input";
import {
  CepMask,
  CpfMask,
  PhoneMask,
  requiredMessage,
  CellPhoneMask,
} from "../../core/utils/misc";

import { addOrUpdate, getExternal } from "../../service";
import { userType } from "./utils";
import { isCNPJ, isCPF, isEmail } from "../../core/utils/validate";
import { config } from "../../config";
import Logo from "../../assets/img/logo.svg";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { toastType } from "../../core/helpers/misc";

function onSubmit(user, form, route, { modal, refresh }) {
  const toastMessage = {
    text: `${user.name} enviado!`,
    type: toastType.INFO,
  };
  addOrUpdate("user", user, toastMessage)
    .then(() => {
      form.setSubmitting(false);
      modal.alert(
        "Verifique seu e-mail! Clique no link do e-mail para ativar sua conta. Não deixe de verificar também no SPAM.",
        () => route.history.push("/login")
      );
    })
    .catch((err) => {
      form.setErrors({ _error: err.message });
    });
}

export const UserFormSingUp = ({ global, route }) => {
  const userTypes = useMemo(
    () => [{ id: userType.ENGINEER, name: "Engenheiro" }],
    []
  );

  const form = useForm(
    {
      initialEntity: {
        id: 0,
        city: "",
        complement: "",
        cpfCnpj: "",
        district: "",
        email: "",
        name: "",
        customerCode: "",
        businessName: "",
        cellphone: "",
        postalCode: "",
        state: "",
        streetName: "",
        streetNumber: "",
        telephone: "",
        proRegisterNumber: "",
        proRegisterType: "",
        role: "",
        responsible: "",
        password: "",
        password_checker: "",
        customers: [],
        useDocx: false,
        canDeleteAnalisys: false,
        type: userTypes[0].id,
        selectedType: userTypes[0],
      },
      validate: (values, errors) => {
        if (!values.name) {
          errors.name = requiredMessage;
        }

        if (!values.cellphone) {
          errors.cellphone = requiredMessage;
        }

        if (!values.email) {
          errors.email = requiredMessage;
        } else if (!isEmail(values.email)) {
          errors.email = "Preencha o campo com um e-mail válido.";
        }

        if (!values.role) {
          errors.role = requiredMessage;
        }
        if (!values.proRegisterType) {
          errors.proRegisterType = requiredMessage;
        }
        if (!values.proRegisterNumber) {
          errors.proRegisterNumber = requiredMessage;
        }

        if (!values.password) {
          errors.password = requiredMessage;
        }
        if (!values.password_checker) {
          errors.password_checker = requiredMessage;
        }

        if (
          (values.password || values.password_checker) &&
          values.password !== values.password_checker
        ) {
          errors.password_checker = "Senhas não coincidem";
          errors.password = "Senhas não coincidem";
        }

        return errors;
      },
    },
    route
  );

  useEffect(() => {
    form.setValues({
      type: userTypes[0].id,
      selectedType: userTypes[0],
    });
    // eslint-disable-next-line
  }, []);

  const { entity, errors, touched } = form;

  return (
    <div className="login-page">
      <div className="login-logo">
        <img src={Logo} alt="Normatiza" />
        <i className="fas fa-code" aria-hidden="true"></i>{" "}
        <small style={{ color: `#ffffff` }}>
          BETA v{config.VERSION} / API v{config.APIVERSION}
        </small>
      </div>

      <div className="login-box login-box-big">
        <div className="login-form">
          <form
            autoComplete="off"
            className="box is-clearfix"
            onSubmit={(ev) => {
              ev.preventDefault();
              form.handleSubmit((u) => onSubmit(u, form, route, global));
            }}
          >
            {form.fetching ? (
              "Carregando..."
            ) : (
              <div className="">
                <div className="col-md">
                  <div className="card bg-white shadow-none">
                    <div className="card-body pt-2 pr-3 pb-0 pl-3">
                      <h5 className="card-title">
                        <p>
                          <i className="fas fa-user"></i> Informações básicas
                        </p>
                      </h5>
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            label="Nome"
                            name="nome"
                            value={entity.name}
                            meta={{
                              error: errors.name,
                              touched: touched.name,
                            }}
                            onChange={(name, type) =>
                              form.handleChange({
                                path: "name",
                                type,
                                values: { name },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            label="E-mail"
                            name="email"
                            value={entity.email}
                            meta={{
                              error: errors.email,
                              touched: touched.email,
                            }}
                            onChange={(email, type) =>
                              form.handleChange({
                                path: "email",
                                type,
                                values: { email },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            label="Celular"
                            name="celular"
                            mask={CellPhoneMask}
                            value={entity.cellphone}
                            meta={{
                              error: errors.cellphone,
                              touched: touched.cellphone,
                            }}
                            onChange={(cellphone, type) =>
                              form.handleChange({
                                path: "cellphone",
                                type,
                                values: { cellphone },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            autoComplete="off"
                            label="Senha"
                            type="password"
                            value={entity.password}
                            meta={{
                              error: errors.password,
                              touched: touched.password,
                            }}
                            onChange={(password, type) =>
                              form.handleChange({
                                path: "password",
                                type,
                                values: { password },
                              })
                            }
                          />
                        </div>

                        <div className="col-md">
                          <TextInput
                            autoComplete="off"
                            label="Confirmar senha"
                            type="password"
                            value={entity.password_checker}
                            meta={{
                              error: errors.password_checker,
                              touched: touched.password_checker,
                            }}
                            onChange={(password_checker, type) =>
                              form.handleChange({
                                path: "password_checker",
                                type,
                                values: { password_checker },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-white shadow-none pt-4"
                    id="assinatura"
                  >
                    <div className="card-body pt-0 pr-3 pb-0 pl-3">
                      <p>
                        <i className="fas fa-signature"></i> Configure seu
                        registro profissional e assinatura
                      </p>
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            name="cargo"
                            label="Cargo exercido"
                            placeholder={
                              "Insira o seu cargo exercido. Ex: Engenheiro de Segurança do Trabalho"
                            }
                            value={entity.role}
                            meta={{
                              error: errors.role,
                              touched: touched.role,
                            }}
                            onChange={(role, type) =>
                              form.handleChange({
                                path: "role",
                                type,
                                values: { role },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <TextInput
                            name="registro"
                            label="Registro Profissional"
                            placeholder="Insira o emissor do seu registro profissional. Ex: CREA-SC"
                            value={entity.proRegisterType}
                            meta={{
                              error: errors.proRegisterType,
                              touched: touched.proRegisterType,
                            }}
                            onChange={(proRegisterType, type) =>
                              form.handleChange({
                                path: "proRegisterType",
                                type,
                                values: { proRegisterType },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <TextInput
                            name="numregistro"
                            label="Número do Registro"
                            placeholder="Insira o número do seu registro"
                            value={entity.proRegisterNumber}
                            meta={{
                              error: errors.proRegisterNumber,
                              touched: touched.proRegisterNumber,
                            }}
                            onChange={(proRegisterNumber, type) =>
                              form.handleChange({
                                path: "proRegisterNumber",
                                type,
                                values: { proRegisterNumber },
                              })
                            }
                          />
                        </div>
                      </div>

                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            Esta é uma prévia da assinatura inserida ao final
                            dos relatórios e documentos gerados!
                          </Tooltip>
                        }
                      >
                        <div className="msg-example black" id="signature">
                          <>
                            <span>{entity.name}</span>
                            <span>{entity.role}</span>
                            <span>
                              {entity.proRegisterType}{" "}
                              {entity.proRegisterNumber}
                            </span>
                          </>
                        </div>
                      </OverlayTrigger>

                      <div className="text-right">
                        <Button
                          type="submit"
                          customClassName="btn register-btn"
                          title="Criar minha conta"
                          loading={form.submitting}
                          disabled={form.submitting}
                        />

                        <Button
                          loading={form.submitting}
                          disabled={form.submitting}
                          customClassName="no-btn text-initial backtologin-link"
                          icon="fas fa-angle-left valign-middle"
                          title="Voltar para o login"
                          onClick={() => route.history.push("/login")}
                        />
                      </div>

                      <ErrorMessage error={errors._error} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
