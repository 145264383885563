import { parse as _parse } from 'qs'
import { onlyNumbers } from './format'

export const parseNumber = (txt = '') => parseInt(`0${onlyNumbers(txt)}`, 10)

export const parseQueryString = query =>
    _parse(query, {
        allowDots: true,
        ignoreQueryPrefix: true
    })

export const parseMoney = (value = '0') => {
    let decimalPart

    value
        .split(/\D/)
        .reverse()
        .filter(e => !!e)
        .forEach((numberPiece, ind, arr) => {
            if (ind === 0 && arr.length > 1) {
                decimalPart = numberPiece
            }
        })

    value = value.replace(/\D/g, '')
    value = value.replace(new RegExp(`${decimalPart}$`), `.${decimalPart}`)

    return Number.parseFloat(value)
}
