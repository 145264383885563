import React, { useState, useEffect } from 'react'
import { Link } from '../../core/components/route'
import { Button } from '../../core/components/button'
import { getList, remove } from '../../service'
import { useRefresh } from '../../core/hooks/refresh'

const List = ({ items, loading, modal, refresh }) =>
    items.length === 0 && !loading ? (
        <div className="alert alert-light fade show" role="alert">
            Nenhuma norma foi encontrada.
        </div>
    ) : (
        <table className="table">
            <tbody>
                {items.map(i => (
                    <tr key={i.id}>
                        <th className="">
                            <h6 className="list-title">Número da norma</h6>
                            {i.code}
                        </th>
                        <th>
                            <h6 className="list-title">Descrição</h6>
                            {i.description.length > 80
                                ? i.description.substring(0, 80).concat('...')
                                : i.description}
                        </th>
                        <th className="no-wrap no-width">
                            <h6 className="list-title">Ações</h6>
                            <Button
                                customClassName="btn btn-light btn-xs mb-0 mt-0"
                                title="Editar"
                                icon="fas fa-edit"
                                onClick={`/norma/${i.id}`}
                            />

                            <Button
                                customClassName="btn btn-danger btn-xs mb-0 mt-0"
                                title="Excluir norma"
                                icon="fas fa-times"
                                onClick={() =>
                                    modal.confirm(
                                        `Deseja realmente excluir a norma '${i.code}'?`,
                                        confirmed => {
                                            if (confirmed) {
                                                remove('standard', i.id)
                                                    .then(refresh)
                                                    .catch(err => modal.alert(err.message))
                                            }
                                        }
                                    )
                                }
                            />
                        </th>
                    </tr>
                ))}
            </tbody>
        </table>
    )

export const StandardList = ({ global }) => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const refresh = useRefresh()

    useEffect(() => {
        let mounted = true

        getList('standard', { usePager: false })
            .then(({ items }) => {
                if (mounted) {
                    setItems(items)
                    setLoading(false)
                }
            })
            .catch(err => global.modal.alert(err.message))

        return () => {
            mounted = false
        }
        // eslint-disable-next-line
    }, [refresh.ref])

    return (
        <div className="row">
            <div className="col-md">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title"><i className="menu-icon fas fa-book" aria-hidden="true"></i> Normas - NR 12</h5>

                        <Button
                            customClassName="btn-success mb-0"
                            title="Nova Norma"
                            icon="valign-middle"
                            onClick="/norma/cadastro"
                        />
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <List
                                items={items}
                                loading={loading}
                                modal={global.modal}
                                refresh={refresh.force}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
