import React, { useEffect, useState } from "react";
import { generateId } from "../utils";
import { Button } from "../../../core/components/button";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { Select } from "../../../core/components/form/select";
import { TextAreaInput } from "../../../core/components/form/textarea-input";
import { addOrUpdate, remove } from "../../../service";

export function PeForm({ modal, lists, pe, setPe, removePe, setPage }) {
  const fields = [
    ["Há dispositivos de seg. instalados.", "installedDevices"],
    ["O dispositivo é usado para partida.", "startupDevice"],
    ["Pode ser acionado por outro operador", "triggeredByAnother"],
    ["E passível de burla.", "antiFraud"],
    ["Está identificado em língua portuguesa", "portuguese"],
    ["Exige rearme manual", "manualReset"],
    ["Apresenta retenção após acionado", "retention"],
    ["Acionado em extrabaixa tensão", "lowVoltage"],
  ];

  const [setedPe, onSetPe] = useState({ modified: false, nav: false });
  const [removedPe, onRemovePe] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (setedPe.modified) {
      setSubmitting(true);
      addOrUpdate("pe", pe)
        .then((resp) => {
          if (resp) {
            pe.id = resp.id;
          }
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onSetPe(false);
          if (setedPe.nav)
            setPage((prev) => ({
              ...prev,
              type: "machine3",
              id: 0,
            }));
        });
    }
  }, [setedPe]);

  useEffect(() => {
    if (removedPe) {
      setSubmitting(true);
      remove("pe", pe.id)
        .then(() => {
          removePe();
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onRemovePe(false);
        });
    }
  }, [removedPe]);

  return (
    <>
      <label>Foto do ponto</label>
      <div className="row">
        <div className="col-md">
          <div className="fotos mb-3">
            <PhotoBox
              title="Adicionar foto do ponto"
              photo={pe.photo}
              modal={modal}
              onChange={(data) =>
                setPe((prev) => ({
                  ...prev,
                  photo: data ? { id: generateId(), data } : null,
                }))
              }
            />
          </div>
        </div>
      </div>

      {fields.map(([name, field], i) => (
        <div key={i} className="row">
          <div className="col-md">
            <h3>{name}</h3>

            <div className="check-group">
              <div className="checkbox">
                <input
                  id={field}
                  type="checkbox"
                  checked={pe[field]}
                  onChange={({ target }) =>
                    setPe((pe) => ({
                      ...pe,
                      [field]: target.checked,
                    }))
                  }
                />
                <label className="noselect" htmlFor={field}>
                  {pe[field] ? "Sim" : "Não"}
                </label>
              </div>

              <div className="checkbox">
                <input
                  id={`${field}Nr12`}
                  type="checkbox"
                  checked={pe[`${field}Nr12`]}
                  onChange={({ target }) =>
                    setPe((pe) => ({
                      ...pe,
                      [`${field}Nr12`]: target.checked,
                    }))
                  }
                />
                <label className="noselect" htmlFor={`${field}Nr12`}>
                  {pe[`${field}Nr12`] ? "Atende NR-12" : "Não Atende NR-12"}
                </label>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="row">
        <div className="col-md">
          <Select
            isMulti
            label="Parecer Técnico"
            noItemMessage="Nenhum parecer foi encontrado..."
            getId={(t) => t.id}
            getDisplay={(t) => `${t.code} ${t.description}`}
            items={lists.standards.filter((s) => s.titleId === 6)}
            onChange={(items) =>
              setPe((prev) => ({
                ...prev,
                standardsItems: items,
                standards: items.map((i) => i.id),
              }))
            }
            selected={pe.standardsItems}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextAreaInput
            label="Possíveis soluções"
            value={pe.solution}
            rows={5}
            onChange={(solution) =>
              setPe((prev) => ({
                ...prev,
                solution,
              }))
            }
          />
        </div>
      </div>

      <div className="buttons text-right">
        <Button
          disabled={submitting}
          customClassName="btn-secondary"
          icon="fas fa-angle-left valign-middle"
          title="Voltar"
          onClick={() => {
            onSetPe({ modified: true, nav: true });
          }}
        />

        <Button
          disabled={submitting}
          customClassName="btn-danger"
          icon="fas fa-times valign-middle fa-sm"
          title="Remover PE"
          onClick={() => {
            modal.confirm("Deseja remover esse PE?", (confirmed) => {
              if (confirmed) {
                onRemovePe(true);
              }
            });
          }}
        />

        <Button
          customClassName="btn-success"
          icon="fas fa-check valign-middle fa-sm"
          title="Salvar PE"
          loading={submitting}
          disabled={submitting}
          onClick={() => onSetPe({ modified: true, nav: false })}
        />
      </div>
    </>
  );
}
