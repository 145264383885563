import React, { useState } from "react";
import { Button } from "../../core/components/button";
import { forceDownload, classNames } from "../../core/helpers/misc";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

import { toLocaleDateTime } from "../../core/utils/format";
import { downloadStudiesReport, post } from "../../service";
import NoAnalysisFilter from "../../assets/img/028-analysis.png";
import { userType } from "../user";
import { getStudiesName } from "./utils";

function download(id, code, pdf, modal, setLoading) {
  setLoading(id);
  downloadStudiesReport(id, pdf, `${code}.${pdf ? "pdf" : "docx"}`)
    .then((blob) =>
      forceDownload(blob, `${code}.${pdf ? "pdf" : "docx"}`, "Estudo")
    )
    .catch((err) => modal.alert(err.message))
    .finally(() => setLoading(0));
}

export const EngineerStudiesList = ({
  fetching,
  items = [],
  route,
  global,
  dashBoard,
  noMargin,
  setRefresh,
}) => {
  const [downloadingPdf, setDownloadingPdf] = useState(0);

  return items.length === 0 ? (
    !fetching && (
      <div className="info-no-content">
        <img
          className="image"
          alt="Não há estudos cadastrados"
          src={NoAnalysisFilter}
        />
        <div className="inventory">
          Ainda não há nenhum estudo criado para este cliente ou que
          correspondam ao filtro selecionado!
        </div>
      </div>
    )
  ) : !fetching &&  (
    <div className="table-responsive">
      <table
        className={classNames("table", {
          "mb-0": noMargin,
        })}
      >
        <tbody>
          {items.map((a, i) => {
            return (
              <tr key={i}>
                <th>
                  {a.createdDevice === 0 ? (
                    <i className="fas fa-lg fa-fw display-inline-table fa-mobile-alt valign-bottom"></i>
                  ) : (
                    <i className="fas fa-lg fa-fw display-inline-table fa-desktop valign-bottom"></i>
                  )}
                </th>
                <th>
                  <h6 className="list-title">ID do Estudo</h6>
                  <div className="cut-text-medium">{getStudiesName(a)}</div>
                </th>
                <th>
                  <h6 className="list-title">Análise Relacionada</h6>
                  <div className="cut-text-small">{a.resume}</div>
                </th>
                <th>
                  <h6 className="list-title">Data de criação</h6>
                  {toLocaleDateTime(a.createdAt)}
                </th>
                {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                  global.operator.type
                ) &&
                  !dashBoard && (
                    <th>
                      <h6 className="list-title">Estudo está completo?</h6>
                      <input
                        type="checkbox"
                        className="switch estudo"
                        checked={a.completed}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            global.modal.confirm(
                              <>
                                <p className="alert-remove">
                                  <h6>Ação ao completar:</h6>{" "}
                                  <ul className="lista text-left">
                                    <li>
                                      Gastará <b>0 crédito</b>
                                    </li>
                                    <li>
                                      Liberado o{" "}
                                      <b>download do relatório de estudo</b>
                                    </li>
                                  </ul>{" "}
                                  <span>
                                    {" "}
                                    Deseja marcar este estudo como completo?{" "}
                                  </span>
                                </p>
                              </>,
                              (confirmed) => {
                                if (confirmed) {
                                  if (setRefresh) setRefresh(true);
                                  post("studies.complete", {
                                    id: a.id,
                                    completed: true,
                                  })
                                    .then(() => {
                                      a.completed = true;
                                      global.refresh();
                                    })
                                    .catch((err) =>
                                      global.modal.alert(err.message)
                                    );
                                }
                              }
                            );
                          }

                          if (!ev.target.checked) {
                            if (setRefresh) setRefresh(true);

                            post("studies.complete", {
                              id: a.id,
                              completed: false,
                            })
                              .then(() => {
                                a.completed = false;
                                global.refresh();
                              })
                              .catch((err) => global.modal.alert(err.message));
                          }
                        }}
                      />
                    </th>
                  )}
                <th className="no-wrap no-width">
                  <h6 className="list-title">Ações/download</h6>
                  {!dashBoard &&
                    [
                      userType.ENGINEER,
                      userType.GUESTENGINEER,
                      userType.ANALYST,
                    ].includes(global.operator.type) && (
                      <Button
                        title="Editar Estudo"
                        icon="fas fa-edit"
                        customClassName="btn-light btn-xs mb-0 mt-0"
                        disabled={
                          downloadingPdf > 0 ||
                          ([userType.ANALYST].includes(global.operator.type) &&
                            a.completed)
                        }
                        onClick={() => route.history.push(`/estudo/${a.id}`)}
                      />
                    )}

                  <Button
                    title="Baixar estudo"
                    id="relatorioPDF"
                    icon="fas fa-file-pdf"
                    customClassName="btn-primary btn-pdf btn-xs mb-0 mt-0"
                    disabled={downloadingPdf > 0 || !a.completed}
                    loading={downloadingPdf === a.id}
                    onClick={() =>
                      download(
                        a.id,
                        `${getStudiesName(a)}`,
                        true,
                        global.modal,
                        setDownloadingPdf
                      )
                    }
                  />

                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
