import React, { useState } from "react";
import { Button } from "../../core/components/button";
import { forceDownloadUrl, classNames } from "../../core/helpers/misc";
import { userType } from "../user";
import { toLocaleDateTime } from "../../core/utils/format";
import { get, remove } from "../../service";
import NoAnalysisFilter from "../../assets/img/028-analysis.png";
import { getTrainingReportName } from "./utils";

function download(id, fileName, modal, setLoading) {
  setLoading(id);
  get(`TrainingReports.DownloadFileUrl/${id}`)
    .then((url) => {
      console.log(url);
      forceDownloadUrl(url, fileName);
    })
    .catch((err) => modal.alert(err.message))
    .finally(() => setLoading(0));
}

export const TrainingReportList = ({
  fetching,
  items = [],
  customer,
  route,
  global,
  dashBoard,
  noMargin,
  setRefresh,
}) => {
  const [downloading, setDownloading] = useState(0);

  return items.length === 0
    ? !fetching && (
        <div className="info-no-content">
          <img
            className="image"
            alt="Não há capacitações cadastradas"
            src={NoAnalysisFilter}
          />
          <div className="inventory">
            Ainda não há nenhuma capacitação criado para este cliente ou que
            correspondam ao filtro selecionado!
          </div>
        </div>
      )
    : !fetching && (
        <div className="table-responsive">
          <table
            className={classNames("table", {
              "mb-0": noMargin,
            })}
          >
            <tbody>
              {items.map((a, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {a.createdDevice === 0 ? (
                        <i className="fas fa-lg fa-fw display-inline-table fa-mobile-alt valign-bottom"></i>
                      ) : (
                        <i className="fas fa-lg fa-fw display-inline-table fa-desktop valign-bottom"></i>
                      )}
                    </th>
                    <th>
                      <h6 className="list-title">ID da Capacitação</h6>
                      <div className="cut-text-medium">
                        {getTrainingReportName(a, customer)}
                      </div>
                    </th>
                    <th>
                      <h6 className="list-title">Análise Relacionada</h6>
                      <div className="cut-text-small">{a.analisisRef}</div>
                    </th>
                    <th>
                      <h6 className="list-title">Data de criação</h6>
                      {toLocaleDateTime(a.dateFile)}
                    </th>

                    <th className="no-wrap no-width">
                      <h6 className="list-title">Ações/download</h6>
                      <Button
                        title="Baixar laudo"
                        id="laudoFile"
                        icon="fas fa-file-pdf"
                        customClassName="btn-success btn-xs mb-0 mt-0"
                        disabled={downloading > 0}
                        loading={downloading === a.id}
                        onClick={() =>
                          download(
                            a.id,
                            `${getTrainingReportName(a, customer)}`,
                            global.modal,
                            setDownloading
                          )
                        }
                      />
                      {!dashBoard &&
                        [
                          userType.ENGINEER,
                          userType.GUESTENGINEER,
                          userType.ANALYST,
                        ].includes(global.operator.type) && (
                          <Button
                            title="Excluir Laudo"
                            icon="fas fa-trash"
                            customClassName="btn-primary btn-pdf btn-xs mb-0 mt-0"
                            disabled={downloading > 0}
                            onClick={() =>
                              global.modal.confirm(
                                <div>
                                  <p className="alert-remove">
                                    <h6>Esta ação é irreversível!</h6>
                                    <span>
                                      Deseja realmente remover esta capacitação?
                                    </span>
                                  </p>
                                </div>,
                                (confirmed) => {
                                  if (confirmed) {
                                    if (setRefresh) setRefresh(true);
                                    remove("TrainingReports", a.id)
                                      .then(() => {
                                        global.refresh();
                                      })
                                      .catch((err) => modal.alert(err.message));
                                  }
                                }
                              )
                            }
                          />
                        )}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
};
