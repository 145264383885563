import loadImage from "blueimp-load-image";
import { toast } from "react-toastify";
import { showToast, toastType } from "../../core/helpers/misc";

export const hrnTitles = {
  pe: {
    0.03: "Quase impossível",
    1: "Altamente improvável",
    1.5: "Improvável",
    2: "Possível",
    5: "Alguma Chance",
    8: "Provável",
    10: "Muito Provável",
    15: "Certo",
  },
  fe: {
    0.5: "Anualmente",
    1: "Mensalmente",
    1.5: "Semanalmente",
    2.5: "Diariamente",
    4: "Em termos de hora",
    5: "Constantemente",
  },
  mpl: {
    0.1: "Arranhão / Contusão leve",
    0.5: "Dilaceração / Doenças moderadas",
    2: "Fratura / Enfermidade leve",
    4: "Fratura / Enfermidade grave",
    6: "Perda de um membro / olho",
    10: "Perda de dois membros / olhos",
    15: "Fatalidade",
  },
  np: {
    1: "1-2 pessoas",
    2: "3-7 pessoas",
    4: "8-15 pessoas",
    8: "16-50 pessoas",
    12: "Mais que 50 pessoas",
  },
};

export const pageSteps = [
  "analysis",
  "machine1",
  "machine2",
  "machine3",
  "machine4",
  "risk",
  "pap",
  "pe",
];

export const generateId = () => Math.round(Date.now() / 1000) * -1;

export function peHelper(pe) {
  if (pe === 0.03) {
    return "Em qualquer situação normal de trabalho não pode ocorrer acidente. Ponto de perigo totalmente protegido.";
  } else if (pe === 1) {
    return "Ponto de perigo protegido, sem dispositivos de segurança, fora da área de trabalho.";
  } else if (pe === 1.5) {
    return "Situação improvável, porém, concebível. Exemplo: Proteção móvel com intertravamento sem dispositivos de segurança.";
  } else if (pe === 2) {
    return "Situação possível, mas não usual. Exemplo: Proteção móvel obedecendo o Anexo I, sem intertravamento/Painel elétrico com trava mecânica. Pequenas aberturas onde o braço deve ser guiado até o ponto.";
  } else if (pe === 5) {
    return "Evento poderia ocorrer. Exemplo: Ponto de perigo semi protegido que pode ser acessado de forma voluntária (aberturas grandes fora da zona de operação, cerca baixa, etc).";
  } else if (pe === 8) {
    return "Grande chance de ocorrer. Exemplo: O trabalhador está desenvolvendo uma atividade na proximidade do ponto sem ter a necessidade de acessá-lo.";
  } else if (pe === 10) {
    return "Esperado. Exemplo: Trabalhador interage com o ponto de perigo ou com peças ligadas ao mesmo sistema ao qual o ponto de perigo está conectado: dispositivos de trabalho alimentados manualmente.";
  } else {
    return "Nenhuma dúvida. Exemplo: Trabalhador interage na região de ação do ponto de perigo: Contato direto com o risco.";
  }
}

export function getAnalisyName(i) {
  let analise = "";
  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine.equipmentName
      ? `-${i.machine.equipmentName}`
      : "";
    analise = `AR-${i.customerCode}-${sequential}${machine}`;
  }
  return analise;
}

export function getStudiesName(i) {
  let analise = "";
  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machineName ? `-${i.machineName}` : "";
    analise = `EA-${i.customerCode}-${sequential}${machine}`;
  }
  return analise;
}

export function getTecnicalReportName(i, customer) {
  let laudo = "";

  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine ? `-${i.machine}` : "";
    laudo = `LT-${customer.customerCode}-${sequential}${machine}`;
  }
  return laudo;
}

export function getProceduresReportName(i, customer) {
  let laudo = "";

  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine ? `-${i.machine}` : "";
    laudo = `PR-${customer.customerCode}-${sequential}${machine}`;
  }
  return laudo;
}

export function getManualReportName(i, customer) {
  let laudo = "";

  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine ? `-${i.machine}` : "";
    laudo = `MA-${customer.customerCode}-${sequential}${machine}`;
  }
  return laudo;
}

export function getTrainingReportName(i, customer) {
  let laudo = "";

  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine ? `-${i.machine}` : "";
    laudo = `CP-${customer.customerCode}-${sequential}${machine}`;
  }
  return laudo;
}

export function getGroundingReportName(i, customer) {
  let laudo = "";

  if (i) {
    const sequential = `${i.sequential}`.padStart(7, "0");
    const machine = i.machine ? `-${i.machine}` : "";
    laudo = `LAT-${customer.customerCode}-${sequential}${machine}`;
  }
  return laudo;
}

export function getPageTitle(page) {
  if (page.type === "analysis") {
    return "Passo 1 - Criação da Análise";
  } else if (["machine1", "machine2", "machine3"].includes(page.type)) {
    return "Passo 2 - Identificação da Máquina e Pontos";
  } else if (page.type === "machine4") {
    return "Passo 3 - Disposições finais";
  } else if (page.type === "risk") {
    return "Passo 2.1 - Identificação dos Pontos de Perigo";
  } else if (page.type === "pap") {
    return "Passo 2.2 - Identificação dos PAP";
  }

  return "Passo 2.3 - Identificação dos PE";
}

export function getRiskClassAndDefinition(value) {
  if (value <= 1.0) {
    return { class: "riskLevel aceitavel", description: "Aceitável" };
  } else if (value >= 1.1 && value <= 5.0) {
    return { class: "riskLevel muitoBaixo", description: "Muito Baixo" };
  } else if (value >= 5.1 && value <= 10.0) {
    return { class: "riskLevel baixo", description: "Baixo" };
  } else if (value >= 10.1 && value <= 50.0) {
    return { class: "riskLevel significante", description: "Significante" };
  } else if (value >= 50.1 && value <= 100.0) {
    return { class: "riskLevel alto", description: "Alto" };
  } else if (value >= 100.1 && value <= 500.0) {
    return { class: "riskLevel muitoAlto", description: "Muito Alto" };
  } else if (value >= 500.1 && value <= 1000.0) {
    return { class: "riskLevel extremo", description: "Extremo" };
  } else if (value > 1000) {
    return { class: "riskLevel inaceitavel", description: "Inaceitável" };
  }
  return { class: "riskLevel inaceitavel", description: "Inaceitável" };
}

export function getRiskCategory(risk) {
  if (risk.severityCategory === 1) return 1;
  if (risk.frequencyCategory === 1 && risk.possibilityCategory === 1) return 2;
  if (risk.frequencyCategory === 2 && risk.possibilityCategory === 2) return 4;
  return 3;
}

export function getHrnClassName(hrnResult) {
  if (hrnResult > 1 && hrnResult <= 5) return "hrn-level-2";
  if (hrnResult > 5 && hrnResult <= 10) return "hrn-level-3";
  if (hrnResult > 10 && hrnResult <= 50) return "hrn-level-4";
  if (hrnResult > 50 && hrnResult <= 100) return "hrn-level-5";
  if (hrnResult > 100 && hrnResult <= 500) return "hrn-level-6";
  if (hrnResult > 500 && hrnResult <= 1000) return "hrn-level-7";
  if (hrnResult > 1000) return "hrn-level-8";
  return undefined;
}

export function getHrnDescription(hrnResult) {
  const hrnResultMessage =
    hrnResult > 1 && hrnResult <= 5
      ? "Risco Muito Baixo"
      : hrnResult > 5 && hrnResult <= 10
      ? "Risco Baixo"
      : hrnResult > 10 && hrnResult <= 50
      ? "Risco Significante"
      : hrnResult > 50 && hrnResult <= 100
      ? "Risco Alto"
      : hrnResult > 100 && hrnResult <= 500
      ? "Risco Muito Alto"
      : hrnResult > 500 && hrnResult <= 1000
      ? "Risco Extremo"
      : hrnResult > 1000
      ? "Risco Inaceitável"
      : "Risco Aceitável";

  return hrnResultMessage;
}

const MAX_WIDTH = 6000;
const MAX_HEIGHT = 6000;

export function getPicture(isClient = false) {
  return new Promise((resolve, reject) => {
    const inputFile = document.createElement("input");
    inputFile.type = "file";
    inputFile.accept = "image/*";
    inputFile.addEventListener("change", (ev) => {
      const [file] = ev.target.files;

      if (file) {
        if (isClient && file.type === "image/png") {
          showToast({
            text: "Formato de arquivo de imagem Inválido.",
            type: toastType.ERROR,
          });

          reject();
        }
        loadImage(
          file,
          (img) => {
            const elem = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            }

            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }

            elem.width = width;
            elem.height = height;

            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const imgData = ctx.canvas.toDataURL("image/jpeg", 0.75);
            resolve(imgData);
          },
          {
            orientation: false,
          }
        );
      } else {
        reject();
      }
    });
    inputFile.click();
  });
}

export function getFile() {
  return new Promise((resolve, reject) => {
    const inputFile = document.createElement("input");
    inputFile.type = "file";
    inputFile.accept = "*/*";
    inputFile.addEventListener("change", (ev) => {
      const [file] = ev.target.files;

      if (file) {
        resolve(file);
      } else {
        reject();
      }
    });
    inputFile.click();
  });
}

export const newRisk = (machineId, analiseId, customerId, sequencial) => ({
  id: generateId(),
  machineId: machineId,
  analiseId: analiseId,
  customerId: customerId,
  local: "",
  solution: "",
  frequencyCategory: 1,
  possibilityCategory: 1,
  severityCategory: 1,
  hrnFe: 0.5,
  hrnPe: 0.03,
  hrnMpl: 0.1,
  hrnNp: 1,
  hrnFeResidual: 0.5,
  hrnPeResidual: 0.03,
  hrnMplResidual: 0.1,
  hrnNpResidual: 1,
  types: [],
  consequences: [],
  standardTitles: [],
  standards: [],
  origins: [],
  securities: [],
  securityTypes: [],
  useCategory: false,
  useHrnResidual: false,
  photo: null,
  sequencial: sequencial + 1,
});

export const newPEDevice = (machineId, analiseId, customerId, sequencial) => ({
  id: generateId(),
  machineId: machineId,
  analiseId: analiseId,
  customerId: customerId,
  antiFraud: false,
  antiFraudNr12: false,
  installedDevices: false,
  installedDevicesNr12: false,
  lowVoltage: false,
  lowVoltageNr12: false,
  manualReset: false,
  manualResetNr12: false,
  photo: null,
  portuguese: false,
  portugueseNr12: false,
  retention: false,
  retentionNr12: false,
  startupDevice: false,
  startupDeviceNr12: false,
  standards: [],
  triggeredByAnother: false,
  triggeredByAnotherNr12: false,
  solution: "",
  sequencial: sequencial + 1,
});

export const newPAPDevice = (machineId, analiseId, customerId, sequencial) => ({
  id: generateId(),
  machineId: machineId,
  analiseId: analiseId,
  customerId: customerId,
  activationAccidental: false,
  activationAccidentalNr12: false,
  activationAntiFraud: false,
  activationAntiFraudNr12: false,
  activationEbt: false,
  activationEbtNr12: false,
  activationInstalled: false,
  activationInstalledNr12: false,
  activationPhoto: null,
  activationPortuguese: false,
  activationPortugueseNr12: false,
  activationSafeArea: false,
  activationSafeAreaNr12: false,
  resetAccidental: false,
  resetAccidentalNr12: false,
  resetAntiFraud: false,
  resetAntiFraudNr12: false,
  resetEbt: false,
  resetEbtNr12: false,
  resetInstalled: false,
  resetInstalledNr12: false,
  resetPhoto: null,
  resetPortuguese: false,
  resetPortugueseNr12: false,
  resetSafeArea: false,
  resetSafeAreaNr12: false,
  stopAccidental: false,
  stopAccidentalNr12: false,
  stopAntiFraud: false,
  stopAntiFraudNr12: false,
  stopEbt: false,
  stopEbtNr12: false,
  stopInstalled: false,
  stopInstalledNr12: false,
  stopPhoto: null,
  stopPortuguese: false,
  stopPortugueseNr12: false,
  stopSafeArea: false,
  stopSafeAreaNr12: false,
  solution: "",
  standards: [],
  sequencial: sequencial + 1,
});

export const cloneRisk = (risk, machine) => {
  let clone = { ...risk };
  clone.id = generateId();
  clone.photo = null;
  clone.sequencial = Math.max(...machine.risks.map((o) => o.sequencial), 0) + 1;

  return clone;
};

export const clonePEDevice = (peDevice, machine) => {
  console.log(machine.peDevices);
  console.log(machine);
  let clone = { ...peDevice };
  clone.id = generateId();
  clone.photo = null;
  clone.sequencial =
    Math.max(...machine.peDevices.map((o) => o.sequencial), 0) + 1;
  return clone;
};

export const clonePAPDevice = (papDevice, machine) => {
  let clone = { ...papDevice };
  clone.id = generateId();
  clone.activationPhoto = null;
  clone.resetPhoto = null;
  clone.stopPhoto = null;
  clone.sequencial =
    Math.max(...machine.papDevices.map((o) => o.sequencial), 0) + 1;
  return clone;
};
