export const BrazilStates = [
    ['AC', 'Acre'],
    ['AL', 'Alagoas'],
    ['AM', 'Amazonas'],
    ['AP', 'Amapá'],
    ['BA', 'Bahia'],
    ['CE', 'Ceará'],
    ['DF', 'Distrito Federal'],
    ['ES', 'Espírito Santo'],
    ['GO', 'Goiás'],
    ['MA', 'Maranhão'],
    ['MG', 'Minas Gerais'],
    ['MS', 'Mato Grosso do Sul'],
    ['MT', 'Mato Grosso'],
    ['PA', 'Pará'],
    ['PB', 'Paraíba'],
    ['PE', 'Pernambuco'],
    ['PI', 'Piauí'],
    ['PR', 'Paraná'],
    ['RJ', 'Rio de Janeiro'],
    ['RN', 'Rio Grande do Norte'],
    ['RO', 'Rondônia'],
    ['RR', 'Roraima'],
    ['RS', 'Rio Grande do Sul'],
    ['SC', 'Santa Catarina'],
    ['SP', 'São Paulo'],
    ['SE', 'Sergipe'],
    ['TO', 'Tocantins']
]

export const getStateFullName = (uf) => {
    const [name] = BrazilStates
        .filter(([s]) => s === uf)
        .map(([_, n]) => n)

    return name
}

export const CnpjMask = [
    /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/,
    /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/
]
export const CpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
export const CepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
export const CellPhoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
export const PhoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
export const requiredMessage = 'Esse campo é obrigatório.'