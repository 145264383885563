import React, { useState, useRef, useEffect } from "react";
import { Switch, Route } from "react-router";
import { Link, PageRoute } from "../../core/components/route";
import { classNames } from "../../core/helpers/misc";
import { useOnClickOutside } from "../../core/hooks/clickOutside";
import { config } from "../../config";
import Avatar from "react-avatar";
import GooglePlayReady from "../../assets/img/google-play.png";
import { toast } from "react-toastify";
import "moment/locale/pt-br";
import { logOut } from "../../service";
import { userType } from "../user";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Detector } from "react-detect-offline";
import Logo from "../../assets/img/avatar-default.png";

export const Panel = (props) => {
  const userMenuRef = useRef(null);
  const [userOpened, setUserOpened] = useState(false);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const { global } = props;

  useOnClickOutside(userMenuRef, () => setUserOpened(false));

  const pages = props.pages.filter(
    ({ hideWhen = () => false }) => !hideWhen(global.operator)
  );

  const userPages = props.userPages.filter(
    ({ hideWhen = () => false }) => !hideWhen(global.operator)
  );

  const allPages = pages.concat(userPages);
  const { pathname } = props.route.location;

  useEffect(() => {
    let filtered = props.pages.filter((c) => c.href == pathname);

    setUserOpened(false);
    setSidebarOpened(false);
  }, [pathname]);

  return (
    <div id="topo" className="wrapper main-layout sidebar layout-dark ml-3">
      <div className="app-sidebar">
        <div className="sidebar-header">
          <div className="logo clearfix">
            <Link className="logo-text float-left active" to={props.path}>
              <div className="logo-img">
                <img src={Logo} alt="Normatiza"></img>
              </div>
            </Link>
          </div>
        </div>
        <div className="scrollbar-container sidebar-content">
          <div className="nav-container">
            <ul
              id="main-menu-navigation"
              className="navigation navigation-main"
            >
              <OverlayTrigger
                key={-1}
                placement="right"
                overlay={<Tooltip id={-1}>Visão Geral</Tooltip>}
              >
                <li key={-1} className="nav-item">
                  <Link to="/">
                    <i
                      className="fas fa-tachometer-alt fa-lg width-35-per height-35-per"
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              </OverlayTrigger>

              {pages.map((p, i) => (
                <OverlayTrigger
                  key={i}
                  placement="right"
                  overlay={<Tooltip id={i}>{p.title}</Tooltip>}
                >
                  <li key={i} className="nav-item">
                    <Link to={p.href}>
                      <i
                        className={classNames(
                          "menu-icon",
                          "width-35-per",
                          "height-35-per",
                          p.icon
                        )}
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                </OverlayTrigger>
              ))}
            </ul>
          </div>
        </div>
        <div className="sidebar-recommended text-center">
          <OverlayTrigger
            key={-3}
            placement="right"
            overlay={
              <Tooltip id={-3}>A Normatiza no seu celular Android!</Tooltip>
            }
          >
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.normatiza&hl=pt_BR&utm_source=sidebar-web"
            >
              <div>
                <img alt="Disponível no Google Play" src={GooglePlayReady} />
              </div>
            </a>
          </OverlayTrigger>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-faded navbar">
        <div className="container-fluid px-0">
          <div className="navbar-header">
            <div className="float-left logo-img-normatiza">
              <span className="badge badge-pill badge-primary normatiza">
                BETA
              </span>
              <Link to={props.path}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504 122">
                  <path fill="#00A887" d="M9.8 11h477.6v100.2H9.8z" />
                  <path
                    fill="#FFF"
                    d="M3.1 7.5v107.2l3.2 3.2h23.6v-10.2l-3.2-3.2H16.5V17.7h10.2l3.2-3.2V4.3H6.3zM497.6 4.3H474v10.2l3.2 3.2h10.2v86.8h-10.2l-3.2 3.2v10.2h23.6l3.2-3.2V7.5z"
                  />
                  <g fill="#FFF">
                    <path d="M403.7 31l-33.4 46.8V88l3.2 3.1h3.7l33.2-46.5V34.2l-3.2-3.2zM61.6 31c-12 0-21.7 9.8-21.7 21.7V88L43 91h10.2v-38-.4a8.3 8.3 0 1 1 16.7.5V88l3.2 3.1h10.2V52.7c0-12-9.7-21.7-21.7-21.7zM333.6 34.2l-3.2-3.2h-3.5v-7.1l-3.1-3.1h-10.3V31h-5v10.2l3.2 3.2h21.9zM346.9 37.5V88l3.2 3.1h10.2V40.6l-3.2-3.1zM346.9 20.8V31l3.2 3.1h10.2V23.9l-3.2-3.1zM281.8 31c-12 0-21.7 9.8-21.7 21.7v16.7a21.7 21.7 0 0 0 26.7 21.2v-9.8l-3.6-3.1h-1.4a8.3 8.3 0 0 1-8.3-8.3V53v-.3a8.3 8.3 0 0 1 16.7 0V88l3.1 3.1h10.2V52.7c0-12-9.7-21.7-21.7-21.7zM442.3 31c-12 0-21.7 9.8-21.7 21.7v16.7a21.7 21.7 0 0 0 26.7 21.2v-9.8l-3.6-3.1h-1.4a8.3 8.3 0 0 1-8.3-8.3V53v-.3a8.3 8.3 0 0 1 16.7 0V88l3.1 3.1H464V52.7c0-12-9.7-21.7-21.7-21.7zM120.7 31.8l-4 3v9.8c3.8.7 6.7 4.1 6.7 8.1V69.4a8.3 8.3 0 0 1-16.7 0V53v-.3c0-4 2.8-7.4 6.6-8.1v-9.8l-4-3c-9.2 2.5-16 10.9-16 21v16.6a21.7 21.7 0 1 0 43.4 0V52.7c0-10-6.8-18.4-16-21zM228.4 31c-4.7 0-9 1.5-12.5 4 3.2 3.1 5.6 7.2 6.7 11.7a8.3 8.3 0 0 1 14.1 6.5V88L240 91H250V52.7c0-12-9.7-21.7-21.7-21.7zM198.5 31c-12 0-21.7 9.8-21.7 21.7V88L180 91h10.2v-38-.4a8.3 8.3 0 1 1 16.6.5V88L210 91h10.2V52.7c0-12-9.7-21.7-21.7-21.7zM390.3 44.4l9.5-13.4h-29.5v10.2l3.2 3.2zM390.6 77.8l-9.5 13.3h29.3V80.9l-3.2-3.1zM146.7 52.7V88L150 91h10.2V52.7c0-4.1 3-7.6 7-8.2l1.4-.1h1.6V33.7l-2.6-2.6c-11.6.5-20.8 10-20.8 21.6zM333.6 77.6a8.4 8.4 0 0 1-6.7-8.2V51l-3.1-3.2h-10.3v21.7c0 11.7 9.2 21.2 20.8 21.7h2.6V80.6l-2.8-3h-.5z" />
                  </g>
                </svg>
              </Link>
            </div>
          </div>{" "}
          <div className="navbar-container">
            <div className="collapse navbar-collapse">
              <ul className="ml-auto float-right navbar-nav">
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-bottom`}>Sua Conta</Tooltip>}
                >
                  <li id="account-class" className="pr-1 dropdown nav-item">
                    {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                      global.operator.type
                    ) &&
                      (global.operator.isTrial ? (
                        <p
                          className="user-class"
                          onClick={(e) => {
                            e.preventDefault();
                            // URL do Whatsapp Aqui
                            window.open(
                              "https://normatiza.app/desbloquear",
                              "_blank"
                            );
                          }}
                        >
                          <span className="badge badge-default trial p-2">
                            {`Restam ${global.operator.remainingTrialDays} dias - Contratar`}{" "}
                            <i className="fab fa-whatsapp fa-fw" />
                          </span>
                        </p>
                      ) : (
                        <p className="user-class">
                          <span className="badge badge-default engenheiro p-2">
                            Plano Profissional
                          </span>
                        </p>
                      ))}
                    {global.operator.type === userType.ANALYST && (
                      <p className="user-class">
                        <span className="badge badge-default analista p-2">
                          Plano Analista
                        </span>
                      </p>
                    )}
                    {global.operator.type === userType.MANAGER && (
                      <p className="user-class">
                        <span className="badge badge-default gestor p-2">
                          Plano Gestor
                        </span>
                      </p>
                    )}
                    {global.operator.type === userType.ADMIN && (
                      <p className="user-class">
                        <span className="badge badge-default admin p-2">
                          Plano Administrador
                        </span>
                      </p>
                    )}{" "}
                  </li>
                </OverlayTrigger>

                <li className="pr-1 dropdown nav-item" ref={userMenuRef}>
                  <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-bottom`}>Sua Conta</Tooltip>}
                  >
                    <button
                      type="button"
                      id="account-avatar"
                      className="nav-link account-avatar"
                      onClick={() => setUserOpened((x) => !x)}
                    >
                      <Avatar
                        textSizeRatio={3.1}
                        alt="Foto de Perfil"
                        maxInitials={3}
                        className="avatar rounded-circle width-40 height-40 text-bold-500"
                        fgColor="black"
                        color="GhostWhite"
                        email={global.operator.email}
                        name={global.operator.name}
                      />
                    </button>
                  </OverlayTrigger>

                  <div
                    tabIndex={-1}
                    role="menu"
                    className={classNames("dropdown-menu dropdown-menu-right", {
                      show: userOpened,
                    })}
                  >
                    <div className="dropdown-account">
                      <div className="row">
                        <div className="col">
                          <div className="name">
                            <span>{global.operator.name}</span>
                          </div>
                          <div className="email mtn-2">
                            <span>{global.operator.email}</span>
                          </div>
                          <div className="id mtn-2">
                            <OverlayTrigger
                              key="left"
                              placement="left"
                              overlay={
                                <Tooltip id={`tooltip-left`}>
                                  Este é seu ID para suporte, utilize quando um
                                  atendente solicitar
                                </Tooltip>
                              }
                            >
                              <span id="id">
                                ID{" "}
                                <span className="id-code">
                                  #0000{global.operator.id}
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>

                    {userPages.map((up, i) => (
                      <>
                        <Link key={i} to={up.href}>
                          {
                            <button
                              type="button"
                              tabIndex={0}
                              className="dropdown-item"
                            >
                              <i
                                className={classNames("mr-1", up.icon)}
                                aria-hidden="true"
                              />

                              {up.title}
                            </button>
                          }
                        </Link>{" "}
                      </>
                    ))}
                    <div className="dropdown-divider"></div>
                    <button
                      type="button"
                      tabIndex={0}
                      className="dropdown-item"
                      onClick={() => {
                        global.modal.confirm(
                          `Você deseja sair? Tudo o que não foi salvo será perdido!`,
                          (confirmed) => {
                            if (confirmed) {
                              logOut();
                            }
                          }
                        );
                      }}
                    >
                      <i className="fas fa-sign-out-alt fa-fw mr-1"></i>
                      Desconectar
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <main>
        <Switch>
          {props.routes
            .filter(({ hideWhen = () => false }) => !hideWhen(global.operator))
            .map(({ path, component }) =>
              typeof path === "string" ? (
                <PageRoute
                  exact
                  key={path}
                  path={path}
                  component={component}
                  global={global}
                />
              ) : (
                path.map((pt) => (
                  <PageRoute
                    exact
                    key={pt}
                    path={pt}
                    component={component}
                    global={global}
                  />
                ))
              )
            )}

          {allPages.map((pg) => (
            <PageRoute
              exact
              key={pg.href}
              path={pg.href}
              component={pg.component}
              global={global}
            />
          ))}

          <Route
            exact
            path={props.path}
            render={() =>
              !!props.homeRender ? props.homeRender(global.operator) : <div />
            }
          />

          {
            <Route
              path="*"
              render={() => {
                return (
                  !global.loading && (
                    <>
                      <div className="content-header text-center">
                        Erro 404 - Não encontrado.
                      </div>
                      <div className="content-sub-header text-center">{`A página que você solicitou não pôde ser encontrada ou você não possui permissão de acesso. Em caso de dúvida entre em contato pelo suporte.`}</div>
                      {/* <p className="text-center">
                        <button
                          type="button"
                          value="Voltar ao topo"
                          id="updateDashboard"
                          className="btn btn-outline-light btn-sm mb-0 mt-0"
                          onClick={() => (window.location.href = "/")}
                        >
                          Voltar para o painel
                        </button>
                      </p> */}
                    </>
                  )
                );
              }}
            />
          }
        </Switch>
      </main>
      <footer>
        <div className="container-fluid text-center">
          <small className="footer display-block font-small-1">
            {/* <Detector
              render={({ online }) => (
                <div className={online ? "online font-weight-bold" : "warning font-weight-bold"}>
                  <i className="fas fa-circle fa-fw fa-pulse" aria-hidden="true"></i> Você está {online ? "online!" : "offline, verifique a sua conexão!"}
                </div>
              )}
            /> */}
            <i className="fas fa-code" aria-hidden="true"></i> BETA v
            {config.VERSION} / API v{config.APIVERSION}
          </small>
        </div>
      </footer>
    </div>
  );
};
