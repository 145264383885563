import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "../../core/hooks/form";
import { Button } from "../../core/components/button";
import { ErrorMessage } from "../../core/components/form/error-message";
import { TextInput } from "../../core/components/form/text-input";
import { requiredMessage } from "../../core/utils/misc";

import { postFile, add, getList, get } from "../../service";
import { userDocType } from "./utils";
import { Select } from "../../core/components/form/select";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import SearchNotFound from "../../assets/img/029-search-not-found.png";
import { getAnalisyName, getFile } from "../riskanalysis/utils";

const formatUserDocType = (type) => {
  switch (type) {
    case userDocType.DOCUMENT:
      return "Outros Documentos";
    case userDocType.ANALISY:
      return "Análise";
    case userDocType.PROJECT:
      return "Projetos/Estudos";
    case userDocType.TRAINING:
      return "Treinamentos";
    case userDocType.PROCESS:
      return "Procedimentos";
    default:
      return "Outros Documentos";
  }
};

const List = ({ files, setRemoveFileId, submitting }) =>
  files.length === 0 ? (
    <div className="info-no-content">
      <img className="image" src={SearchNotFound}></img>
      <div className="inventory">
        Documento não cadastrado ou não encontrado por este filtro.
      </div>
    </div>
  ) : (
    <div>
      <p>Arquivos carregados</p>
      <table className="table">
        <tbody>
          {files.map((u, index) => (
            <tr key={index}>
              <th>
                <h6 className="list-title">Tipo de documento</h6>
                {formatUserDocType(u.type)}
              </th>
              <th>
                <h6 className="list-title">Análise relacionada</h6>
                {u.analisisRef}
              </th>
              <th>
                <h6 className="list-title">Nome do arquivo</h6>
                {u.name}
              </th>
              <th>
                <h6 className="list-title">Descrição do documento</h6>
                {u.description}
              </th>

              <th>
                <h6 className="list-title">Excluir</h6>
                <OverlayTrigger
                  key="left"
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-left`}>Excluir documento</Tooltip>
                  }
                >
                  <span id="excluirDoc">
                    {submitting ? (
                      <i className="fas fa-sync fa-spin" />
                    ) : (
                      <i
                        className="fas fa-times remove"
                        onClick={() => {
                          setRemoveFileId(u.id);
                        }}
                      />
                    )}
                  </span>
                </OverlayTrigger>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

export const UserDocsForm = ({ global, route }) => {
  const userDocTypes = useMemo(() => [
    { id: userDocType.ANALISY, name: "Análise" },
    { id: userDocType.PROJECT, name: "Projetos/Estudos" },
    { id: userDocType.TRAINING, name: "Treinamentos" },
    { id: userDocType.PROCESS, name: "Procedimentos" },
    { id: userDocType.DOCUMENT, name: "Outros Documentos" },
  ]);

  useEffect(() => {
    get(`UserDocs.ValidCustumer?id=${customerId}`)
      .then((valid) => {
        if (!valid) {
          global.modal.alert(
            "Cliente não encontrado ou acesso não permitido.",
            () => route.history.push("/")
          );
        }
      })
      .catch((err) => global.modal.alert(err.message));
  }, []);

  const form = useForm(
    {
      initialEntity: {
        id: 0,
        description: "",
        name: "",
        customerId: 0,
        type: null, // userDocTypes[0].id,
        selectedType: userDocTypes[-1],
        storagePath: "",
        analysis: {},
        analisePosition: null,
        selectedAnalysis: "",
      },
      validate: (values, errors) => {
        if (!values.name) {
          errors.name = requiredMessage;
        }

        return errors;
      },
    },
    route
  );

  const { entity, errors, touched } = form;
  const [docfiles, setDocFiles] = useState([]);
  const [fakeid, setfakeid] = useState(0);
  const [customerId] = useState(route.match.params["id"]);
  const [removeFileId, setRemoveFileId] = useState(0);
  const [analysis, setAnalysis] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setDocFiles(docfiles.filter((c) => c.id !== removeFileId));
  }, [removeFileId]);

  useEffect(() => {
    setFetching(true);
    getList("analysis", {
      usePager: false,
      filters: [
        {
          items: [
            {
              name: "customerId",
              value: customerId,
            },
          ],
        },
      ],
    })
      .then(
        ({ items }) => items.length !== analysis.length && setAnalysis(items)
      )
      .finally(() => setFetching(false));
    // eslint-disable-next-line
  }, [customerId]);

  return (
    <div>
      <form autoComplete="off" className="box is-clearfix">
        {form.fetching ? (
          "Carregando..."
        ) : (
          <div className="row">
            <div className="col-md">
              <div className="card mb-3">
                <div className="card-header">
                  <h5 className="card-title">
                    <div className="customer">
                      <div className="title">
                        <i className="fas fa-file-alt "></i> Documentos extras{" "}
                        <span className="dash">/</span>{" "}
                        <span className="data">Cadastrar novo</span>
                      </div>
                    </div>
                  </h5>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md">
                      <Select
                        isClearable={true}
                        label="Tipo de documento"
                        getDisplay={(i) => i.name}
                        getId={(i) => i.id}
                        items={userDocTypes}
                        selected={entity.selectedType || undefined}
                        onChange={(type) => {
                          form.handleChange({
                            path: "type",
                            values: {
                              type: type ? type.id : null,
                              selectedType: type ? type : null,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-md">
                      <Select
                        isClearable={true}
                        isLoading={fetching}
                        selected={entity.analisePosition || undefined}
                        label="Análise de risco relacionada"
                        getDisplay={(i) => {
                          return getAnalisyName(i);
                        }}
                        getId={(i) => i.id}
                        items={analysis}
                        onChange={(analysis) =>
                          form.handleChange({
                            path: "analysis",
                            values: {
                              analisePosition: analysis,
                              selectedAnalysis: getAnalisyName(analysis),
                            },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <TextInput
                        label="Insira descrição ou detalhes do documento"
                        value={entity.description}
                        maxLength={100}
                        meta={{
                          error: errors.description,
                          touched: touched.description,
                        }}
                        onChange={(description, type) =>
                          form.handleChange({
                            path: "description",
                            type,
                            values: { description },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <Button
                      customClassName="btn-primary"
                      icon="fas fa-folder-plus"
                      title="Selecionar um arquivo"
                      loading={form.submitting}
                      disabled={form.submitting || !entity.type}
                      onClick={() => {
                        getFile().then((file) => {
                          var clk = fakeid;
                          clk++;
                          setfakeid(clk);
                          setDocFiles((prevArray) => [
                            ...prevArray,
                            {
                              id: clk,
                              customerId: customerId,
                              name: file.name,
                              file: file,
                              description: entity.description,
                              type: entity.type,
                              analisisRef: entity.selectedAnalysis,
                            },
                          ]);
                          form.handleChange({
                            path: "type",
                            values: {
                              type: null,
                              selectedType: null,
                            },
                          });
                          form.handleChange({
                            path: "analysis",
                            values: {
                              analisePosition: null,
                              selectedAnalysis: null,
                            },
                          });
                          form.resetForm();
                        });
                      }}
                    />
                  </div>

                  {docfiles.length ? (
                    <div>
                      <div className="table-responsive">
                        <List
                          files={docfiles}
                          setRemoveFileId={setRemoveFileId}
                          submitting={form.submitting}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-right">
                    <Button
                      loading={form.submitting}
                      disabled={form.submitting}
                      customClassName="btn-secondary"
                      icon="fas fa-angle-left valign-middle"
                      title="Voltar"
                      onClick={() =>
                        route.history.push(`/cliente/${customerId}/extras`)
                      }
                    />
                    {docfiles.length ? (
                      <Button
                        customClassName="btn-success ml-2"
                        icon="fas fa-check valign-middle fa-sm"
                        title="Salvar"
                        loading={form.submitting}
                        disabled={form.submitting}
                        onClick={() => {
                          docfiles.map((doc, index) => {
                            form.setSubmitting(true);
                            postFile(
                              `UserDocs.uploadfile/${doc.customerId}/${doc.type}`,
                              doc.file
                            )
                              .then((done) => {
                                if (!done) return;
                                doc.StoragePath = done;
                                return add("UserDocs", doc);
                              })
                              .then(() => setRemoveFileId(doc.id))
                              .catch((err) =>
                                console.log(
                                  `Erro ao enviar arquivo: ${doc.name}`
                                )
                              )
                              .finally(() => form.setSubmitting(false));
                          });
                        }}
                      />
                    ) : (
                      " "
                    )}
                  </div>

                  <ErrorMessage error={errors._error} />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
