import React, { useState, useEffect, useMemo, useRef } from "react";

import CreatableSelect from "react-select/creatable";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useForm } from "../../../core/hooks/form";
import {
  addOrUpdate,
  add,
  get,
  getById,
  getList,
  post,
  remove,
  postFile,
} from "../../../service";
import { parseNumber } from "../../../core/utils/parse";
import { userType } from "../../user";
import { Select } from "../../../core/components/form/select";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { Button } from "../../../core/components/button";
import { getAnalisyName, getFile } from "../utils";
import { ErrorMessage } from "../../../core/components/form/error-message";
import { TextInput } from "../../../core/components/form/text-input";
import { requiredMessage } from "../../../core/utils/misc";
import SearchNotFound from "../../../assets/img/029-search-not-found.png";

export const ManualReportForm = ({
  route,
  global: { modal, operator, refresh, loading },
}) => {
  const [customer, setCustomer] = useState({});
  const [fetching, setFetching] = useState(true);
  const routeState = route.location.state || {};
  const [analysis, setAnalysis] = useState([]);
  const [docfiles, setDocFiles] = useState([]);
  const [fakeid, setfakeid] = useState(0);
  const [removeFileId, setRemoveFileId] = useState(0);

  const List = ({ files, setRemoveFileId, submitting }) =>
    files.length === 0 ? (
      <div className="info-no-content">
        <img className="image" src={SearchNotFound}></img>
        <div className="inventory">
          Manuais não cadastrados ou não encontrado por este filtro.
        </div>
      </div>
    ) : (
      <div>
        <p>Arquivos carregados</p>
        <table className="table">
          <tbody>
            {files.map((u, index) => (
              <tr key={index}>
                <th>
                  <h6 className="list-title">Análise relacionada</h6>
                  {u.analisisRef}
                </th>
                <th>
                  <h6 className="list-title">Nome do arquivo</h6>
                  {u.name}
                </th>
                <th>
                  <h6 className="list-title">Descrição do manual</h6>
                  {u.description}
                </th>

                <th>
                  <h6 className="list-title">Excluir</h6>
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={
                      <Tooltip id={`tooltip-left`}>Excluir manual</Tooltip>
                    }
                  >
                    <span id="excluirLaudo">
                      {submitting ? (
                        <i className="fas fa-sync fa-spin" />
                      ) : (
                        <i
                          className="fas fa-times remove"
                          onClick={() => {
                            setRemoveFileId(u.id);
                          }}
                        />
                      )}
                    </span>
                  </OverlayTrigger>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

  const form = useForm(
    {
      initialEntity: {
        id: 0,
        name: "",
        machine: "",
        customerId: routeState.customer ? routeState.customer.id : 0,
        storagePath: "",
        analysis: {},
        analisePosition: null,
        selectedAnalysis: "",
      },
      validate: (values, errors) => {
        if (!values.name) {
          errors.name = requiredMessage;
        }

        return errors;
      },
    },
    route
  );

  useEffect(() => {
    setFetching(true);
    if (form.entity.customerId) {
      getById("user", parseNumber(form.entity.customerId))
        .then((item) => setCustomer(item))
        .catch((err) => modal.alert(err.message))
        .finally(() => setFetching(false));
    }
    // eslint-disable-next-line
  }, [form.entity.customerId]);

  useEffect(() => {
    setFetching(true);
    getList("analysis", {
      usePager: false,
      filters: [
        {
          items: [
            {
              name: "customerId",
              value: form.entity.customerId,
            },
          ],
        },
      ],
    })
      .then(
        ({ items }) => items.length !== analysis.length && setAnalysis(items)
      )
      .finally(() => setFetching(false));
    // eslint-disable-next-line
  }, [form.entity.customerId]);

  useEffect(() => {
    setDocFiles(docfiles.filter((c) => c.id !== removeFileId));
  }, [removeFileId]);

  const { entity, errors, touched, submitting, setSubmitting, handleFetch } =
    form;

  return (
    <div>
      <div className="row">
        <div className="col-md">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <div className="customer">
                  {" "}
                  <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Voltar para equipamentos
                      </Tooltip>
                    }
                  >
                    <a
                      className="suffix"
                      onClick={() =>
                        route.history.push(
                          `/clientes/${customer.id}/equipamentos`
                        )
                      }
                    >
                      <i
                        className="fas fa-folder valign-middle fa-sm"
                        aria-hidden="true"
                      />
                      <span> Equipamentos</span>
                    </a>
                  </OverlayTrigger>
                  <span className="dash">/</span>
                  <span>Criação de Manual</span>
                  <span className="dash">/</span>
                  <span className="data">
                    Cliente {`${customer.name ?? ""}`}{" "}
                    <i
                      className="fas fa-check-circle valign-middle fa-xs"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              </h5>
            </div>

            <div>
              {form.fetching ? (
                "Carregando..."
              ) : (
                <div className="row">
                  <div className="col-md">
                    <div className="card mb-3">
                      <div className="card-body">
                        <form autoComplete="off" className="box is-clearfix">
                          <div className="row">
                            <div className="col-md">
                              <Select
                                isClearable={true}
                                isLoading={fetching}
                                selected={entity.analisePosition || undefined}
                                label="Análise de risco relacionada"
                                getDisplay={(i) => {
                                  return getAnalisyName(i);
                                }}
                                getId={(i) => i.id}
                                items={analysis}
                                onChange={(analysis) =>
                                  form.handleChange({
                                    path: "analysis",
                                    values: {
                                      analisePosition: analysis,
                                      selectedAnalysis:
                                        getAnalisyName(analysis),
                                      machine: analysis.machine.id,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="text-right">
                            <Button
                              customClassName="btn-primary"
                              icon="fas fa-folder-plus"
                              title="Selecionar um arquivo"
                              loading={form.submitting}
                              disabled={
                                form.submitting || !entity.selectedAnalysis
                              }
                              onClick={() => {
                                getFile().then((file) => {
                                  var clk = fakeid;
                                  clk++;
                                  setfakeid(clk);
                                  setDocFiles((prevArray) => [
                                    ...prevArray,
                                    {
                                      id: clk,
                                      customerId: customer.id,
                                      name: file.name,
                                      file: file,
                                      machine: entity.machine,
                                      analisisRef: entity.selectedAnalysis,
                                    },
                                  ]);
                                  form.handleChange({
                                    path: "analysis",
                                    values: {
                                      analisePosition: null,
                                      selectedAnalysis: null,
                                    },
                                  });
                                  form.resetForm();
                                });
                              }}
                            />
                          </div>
                        </form>

                        {docfiles.length ? (
                          <div>
                            <div className="table-responsive">
                              <List
                                files={docfiles}
                                setRemoveFileId={setRemoveFileId}
                                submitting={form.submitting}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="text-right">
                          <Button
                            loading={form.submitting}
                            disabled={form.submitting}
                            customClassName="btn-secondary"
                            icon="fas fa-angle-left valign-middle"
                            title="Voltar"
                            onClick={() =>
                              route.history.push(
                                `/clientes/${customer.id}/equipamentos`
                              )
                            }
                          />
                          {docfiles.length ? (
                            <Button
                              customClassName="btn-success ml-2"
                              icon="fas fa-check valign-middle fa-sm"
                              title="Salvar"
                              loading={form.submitting}
                              disabled={form.submitting}
                              onClick={() => {
                                docfiles.forEach(async (doc) => {
                                  form.setSubmitting(true);
                                  await postFile(
                                    `ManualReports.uploadfile/${doc.customerId}/${doc.type}`,
                                    doc.file
                                  )
                                    .then(async (done) => {
                                      if (!done) return;
                                      doc.StoragePath = done;
                                      return await add("ManualReports", doc);
                                    })
                                    .then(() => setRemoveFileId(doc.id))
                                    .catch((err) =>
                                      console.log(
                                        `Erro ao enviar arquivo: ${doc.name}`
                                      )
                                    )
                                    .finally(() => form.setSubmitting(false));
                                });
                              }}
                            />
                          ) : (
                            " "
                          )}
                        </div>

                        <ErrorMessage error={errors._error} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
