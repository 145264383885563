import moment from "moment";
import { stringify as _stringify } from "qs";
import { applyMask } from "./mask";
import { CnpjMask, CpfMask } from "./misc";
import { isCNPJ } from "./validate";

export const stringify = (obj, addPrefix = true) =>
  _stringify(obj, {
    addQueryPrefix: addPrefix,
    allowDots: true,
    encodeValuesOnly: true,
  });

export const onlyNumbers = (txt) => `${txt}`.replace(/[^\d]/g, "");
export const onlyLetters = (txt) => `${txt}`.replace(/[^A-Za-z]/g, "");
export const toLocaleDate = (date) => moment.utc(date).format("DD/MM/YYYY");

export const toLocaleDateTime = (date) =>
  moment.utc(date).format("DD/MM/YYYY [ás] HH:mm");

export const maskCpfCnpj = (cpfcnpj) =>
  isCNPJ(cpfcnpj) ? applyMask(CnpjMask, cpfcnpj) : applyMask(CpfMask, cpfcnpj);
