import React, { useState, useEffect, useCallback } from "react";
import { localStore } from "./core/utils/store";
import { config } from "./config";
import { renderModal } from "./core/components/modal";
import localForage from "localforage";

export const App = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [operator, setOperator] = useState(config.DEFAULT_OPERATOR);
  const [lists, setLists] = useState([]);
  const [modal, setModal] = useState({
    alert: {
      message: "",
      opened: false,
    },
    confirm: {
      message: "",
      opened: false,
    },
    prompt: {
      message: "",
      opened: false,
      title: "",
      value: "",
    },
    generic: {
      title: "",
      message: "",
      hidePrint: false,
      opened: false,
    },
  });

  const childrenFn = Array.isArray(children) ? children[0] : children;

  const refresh = useCallback(async (refreshLists) => {
    const newLists = (await localForage.getItem(config.LISTS_KEY)) || {};
    const newOperator = localStore.get(config.OPERATOR_KEY) || {};

    setOperator(newOperator);
    if (refreshLists) setLists(newLists);
  }, []);

  useEffect(() => {
    setLoading(true);
    refresh(true).finally(() => setLoading(false));
  }, [refresh]);

  return (
    <>
      {childrenFn({
        operator,
        lists,
        loading,
        modal: {
          alert: (message, onClose) =>
            setModal((prev) => ({
              ...prev,
              alert: { opened: true, message, onClose },
            })),
          generic: (title, message, hidePrint, onClose) =>
            setModal((prev) => ({
              ...prev,
              generic: { opened: true, title, message, hidePrint, onClose },
            })),
          confirm: (message, onAct) =>
            setModal((prev) => ({
              ...prev,
              confirm: { opened: true, message, onAct },
            })),
          prompt: (title, message, onSend, type = "text") =>
            setModal((prev) => ({
              ...prev,
              prompt: {
                ...prev.prompt,
                message,
                onSend,
                opened: true,
                title,
                type,
              },
            })),
        },
        refresh,
      })}

      {renderModal(modal, setModal)}
    </>
  );
};
