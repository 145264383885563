import React, { useRef, useState, useLayoutEffect } from "react";
import { classNames } from "../../../helpers/misc";
import * as MASK from "../../../utils/mask";

function handleChange(
  inputEl,
  value,
  type,
  previousValue,
  mask,
  setState,
  onChange
) {
  if (!!onChange && !!inputEl) {
    if (!!mask) {
      const placeholder = MASK.convertMaskToPlaceholder(mask);
      const { end: caretPosition } = MASK.getInputSelection(inputEl);
      const maskedValue = MASK.conformToMask({
        caretPosition,
        mask: mask,
        placeholder,
        previousValue,
        value,
      });
      const newCaretPosition = MASK.adjustCaretPosition({
        caretPosition,
        placeholder,
        previousValue,
        rawValue: value,
        value: maskedValue,
      });

      setState({
        caretPosition: newCaretPosition,
        previousValue: maskedValue,
      });

      const finalValue = maskedValue === placeholder ? "" : maskedValue;

      onChange(finalValue, type);
    } else {
      onChange(value, type);
    }
  }
}

function handleFocus(value, inputEl, caretPos) {
  if (value.indexOf(MASK.placeholderChar) > -1 && !!inputEl) {
    MASK.setCaretPosition(inputEl, caretPos);
  }
}

export const TextInput = ({
  icon,
  type = "text",
  meta = {},
  value,
  customClassName,
  placeholder,
  label,
  disabled,
  mask,
  onChange,
  ignoreEnter = true,
  ...input
}) => {
  const [state, setState] = useState({
    caretPosition: 0,
    previousValue: "",
  });
  const [active, setActive] = useState(!!placeholder);
  const inputRef = useRef(null);
  const hasError = meta.touched && !!meta.error;
  const hasValue = !!value && value.length > 0;

  useLayoutEffect(() => {
    if (!!mask && !!value) {
      handleChange(
        inputRef.current,
        value,
        "key",
        state.previousValue,
        mask,
        setState,
        onChange
      );
    }
    // eslint-disable-next-line
  }, [mask]);

  useLayoutEffect(() => {
    if (!!mask && !!inputRef.current) {
      MASK.setCaretPosition(inputRef.current, state.caretPosition);
    }
  });

  return (
    <>
      <div
        className={classNames("input-field", {
          "is-loading": meta.loading,
        })}
      >
        {icon && (
          <i
            className={classNames("prefix", icon, {
              invalid: hasError,
              active: active || hasValue,
            })}
          />
        )}

        <input
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          ref={inputRef}
          autoComplete="off"
          className={classNames(customClassName, {
            invalid: hasError,
          })}
          onChange={(ev) =>
            handleChange(
              ev.target,
              ev.target.value,
              "key",
              state.previousValue,
              mask,
              setState,
              onChange
            )
          }
          onBlur={(ev) => {
            setActive(!!placeholder);
            handleChange(
              ev.target,
              ev.target.value,
              "blur",
              state.previousValue,
              mask,
              setState,
              onChange
            );
          }}
          onFocus={(ev) => {
            setActive(true);

            if (!!mask) {
              handleFocus(ev.target.value, ev.target, state.caretPosition);
            }
          }}
          onKeyDown={(ev) =>
            ev.keyCode === 13 && ignoreEnter && ev.preventDefault()
          }
          value={value}
        />

        {label && (
          <label
            className={classNames({
              active: active || hasValue,
            })}
          >
            {label}
          </label>
        )}

        {hasError && typeof meta.error === "string" && (
          <p className="help-error">{meta.error}</p>
        )}
      </div>
    </>
  );
};
