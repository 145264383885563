import React, { useState, useEffect } from "react";
import {
  generateId,
  getRiskCategory,
  getHrnClassName,
  getHrnDescription,
  peHelper,
  hrnTitles,
} from "../utils";
import { Button } from "../../../core/components/button";

import { classNames } from "../../../core/helpers/misc";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { TextAreaInput } from "../../../core/components/form/textarea-input";
import { Select } from "../../../core/components/form/select";
import { ReactHint } from "../../../core/components/tooltip";
import { addOrUpdate, remove } from "../../../service";

const locals = [
  { id: 1, name: "Face Frontal" },
  { id: 2, name: "Face Lateral Direita" },
  { id: 3, name: "Face Lateral Esquerda" },
  { id: 4, name: "Face Posterior" },
];

export function RiskForm({ modal, lists, risk, setRisk, removeRisk, setPage }) {
  const hrnResult = risk.hrnFe * risk.hrnMpl * risk.hrnNp * risk.hrnPe;
  const hrnResultMessage = getHrnDescription(hrnResult);

  const hrnResultResidual =
    risk.hrnFeResidual *
    risk.hrnMplResidual *
    risk.hrnNpResidual *
    risk.hrnPeResidual;
  const hrnResultMessageResidual = getHrnDescription(hrnResultResidual);

  const category = getRiskCategory(risk);
  const [setedRisk, onSetRisk] = useState({ modified: false, nav: false });
  const [removedRisk, onRemoveRisk] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (setedRisk.modified) {
      setSubmitting(true);
      addOrUpdate("risk", risk)
        .then((resp) => {
          if (resp) {
            risk.id = resp.id;
          }
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onSetRisk(false);
          if (setedRisk.nav)
            setPage((prev) => ({
              ...prev,
              type: "machine3",
              id: 0,
            }));
        });
    }
  }, [setedRisk]);

  useEffect(() => {
    if (removedRisk) {
      setSubmitting(true);
      remove("risk", risk.id)
        .then(() => {
          removeRisk();
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onRemoveRisk(false);
        });
    }
  }, [removedRisk]);

  return (
    <>
      <label>Foto do ponto</label>
      <div className="row">
        <div className="col-md">
          <div className="fotos mb-3">
            <PhotoBox
              title="Adicionar foto do ponto"
              photo={risk.photo}
              modal={modal}
              onChange={(data) =>
                setRisk((prev) => ({
                  ...prev,
                  photo: data
                    ? {
                        id: generateId(),
                        data,
                      }
                    : null,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <Select
            label="Localização do Ponto"
            items={locals}
            selected={locals.find((l) => l.name === risk.local)}
            getDisplay={(i) => i.name}
            getId={(i) => i.id}
            onChange={(s) => setRisk((prev) => ({ ...prev, local: s.name }))}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <Select
            isMulti
            label="Tipo de Sistema de Segurança"
            noItemMessage="Nenhum tipo foi encontrado..."
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.securityTypes}
            onChange={(securityTypes) =>
              setRisk((prev) => ({
                ...prev,
                securityTypes,
                securities: prev.securities.filter((s) =>
                  securityTypes.some((x) => x.id === s.typeId)
                ),
              }))
            }
            selected={risk.securityTypes}
          />
        </div>

        <div className="col-md">
          <Select
            isMulti
            label="Sistemas de Segurança Aplicados"
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.securities.filter((s) =>
              risk.securityTypes.some((x) => x.id === s.typeId)
            )}
            disabled={risk.securityTypes.length === 0}
            noItemMessage="Nenhum sistema de segurança foi encontrado..."
            selected={risk.securities}
            onChange={(securities) =>
              setRisk((prev) => ({ ...prev, securities }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <Select
            isMulti
            label="Tipo (ISO-12100)"
            noItemMessage="Nenhum tipo foi encontrado..."
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.dangerTypes}
            onChange={(types) =>
              setRisk((prev) => ({
                ...prev,
                types,
                origins: prev.origins.filter((o) =>
                  types.some((x) => x.id === o.typeId)
                ),
                consequences: prev.consequences.filter((c) =>
                  types.some((x) => x.id === c.typeId)
                ),
              }))
            }
            selected={risk.types}
          />
        </div>

        <div className="col-md">
          <Select
            isMulti
            label="Perigo (Origem)"
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.origins.filter((o) =>
              risk.types.some((x) => x.id === o.typeId)
            )}
            disabled={risk.types.length === 0}
            noItemMessage="Nenhuma origem foi encontrada..."
            selected={risk.origins}
            onChange={(origins) =>
              setRisk((prev) => ({
                ...prev,
                origins,
              }))
            }
          />
        </div>

        <div className="col-md">
          <Select
            isMulti
            label="Risco (Consequências)"
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.consequences.filter((c) =>
              risk.types.some((x) => x.id === c.typeId)
            )}
            disabled={risk.types.length === 0}
            noItemMessage="Nenhuma consequência foi encontrada..."
            selected={risk.consequences}
            onChange={(consequences) =>
              setRisk((prev) => ({
                ...prev,
                consequences,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <Select
            isMulti
            label="Titulos da Norma"
            noItemMessage="Nenhum titulo foi encontrado..."
            getId={(t) => t.id}
            getDisplay={(t) => t.name}
            items={lists.standardTitles}
            onChange={(standardTitles) =>
              setRisk((prev) => ({
                ...prev,
                standardTitles,
                standards: prev.standards.filter((s) =>
                  standardTitles.some((x) => x.id === s.titleId)
                ),
              }))
            }
            selected={risk.standardTitles}
          />
        </div>

        <div className="col-md">
          <Select
            isMulti
            label="Itens da norma"
            disabled={risk.standardTitles.length === 0}
            noItemMessage="Nenhuma norma foi encontrada..."
            getId={(t) => t.id}
            getDisplay={(t) => `${t.code} ${t.description}`}
            items={lists.standards.filter((s) =>
              risk.standardTitles.some((x) => x.id === s.titleId)
            )}
            onChange={(standards) =>
              setRisk((prev) => ({
                ...prev,
                standards,
              }))
            }
            selected={risk.standards}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextAreaInput
            label="Possíveis soluções"
            value={risk.solution}
            rows={5}
            onChange={(solution) =>
              setRisk((prev) => ({
                ...prev,
                solution,
              }))
            }
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md">
          <div className="module-header">
            <span>Cálculo do HRN Atual</span>
          </div>

          <div className="module-title">
            <h2>Probabilidade de Exposição (PE)</h2>
            <p>
              {hrnTitles.pe[risk.hrnPe]}

              <span
                className="question-tooltip"
                data-rh={peHelper(risk.hrnPe)}
                data-rh-at="right"
              >
                ?
              </span>
            </p>

            <ReactHint events={{ click: true, hover: true }} />
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnProb03"
                type="checkbox"
                checked={risk.hrnPe === 0.03}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 0.03,
                  }))
                }
              />
              <label htmlFor="hrnProb03">0,03</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb1"
                type="checkbox"
                checked={risk.hrnPe === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 1,
                  }))
                }
              />
              <label htmlFor="hrnProb1">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb1_5"
                type="checkbox"
                checked={risk.hrnPe === 1.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 1.5,
                  }))
                }
              />
              <label htmlFor="hrnProb1_5">1,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb2"
                type="checkbox"
                checked={risk.hrnPe === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 2,
                  }))
                }
              />
              <label htmlFor="hrnProb2">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb5"
                type="checkbox"
                checked={risk.hrnPe === 5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 5,
                  }))
                }
              />
              <label htmlFor="hrnProb5">5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb8"
                type="checkbox"
                checked={risk.hrnPe === 8}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 8,
                  }))
                }
              />
              <label htmlFor="hrnProb8">8</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb10"
                type="checkbox"
                checked={risk.hrnPe === 10}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 10,
                  }))
                }
              />
              <label htmlFor="hrnProb10">10</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb15"
                type="checkbox"
                checked={risk.hrnPe === 15}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPe: 15,
                  }))
                }
              />
              <label htmlFor="hrnProb15">15</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Frequência de Exposição (FE)</h2>
            <p>{hrnTitles.fe[risk.hrnFe]}</p>
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnFreq05"
                type="checkbox"
                checked={risk.hrnFe === 0.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 0.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq05">0,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq1"
                type="checkbox"
                checked={risk.hrnFe === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 1,
                  }))
                }
              />
              <label htmlFor="hrnFreq1">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq15"
                type="checkbox"
                checked={risk.hrnFe === 1.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 1.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq15">1,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq25"
                type="checkbox"
                checked={risk.hrnFe === 2.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 2.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq25">2,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq4"
                type="checkbox"
                checked={risk.hrnFe === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 4,
                  }))
                }
              />
              <label htmlFor="hrnFreq4">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq5"
                type="checkbox"
                checked={risk.hrnFe === 5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFe: 5,
                  }))
                }
              />
              <label htmlFor="hrnFreq5">5</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Grau de Severidade ou Probabilidade Máxima de Perda (PMP)</h2>
            <p>{hrnTitles.mpl[risk.hrnMpl]}</p>
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnSev01"
                type="checkbox"
                checked={risk.hrnMpl === 0.1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 0.1,
                  }))
                }
              />
              <label htmlFor="hrnSev01">0,1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev05"
                type="checkbox"
                checked={risk.hrnMpl === 0.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 0.5,
                  }))
                }
              />
              <label htmlFor="hrnSev05">0,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev2"
                type="checkbox"
                checked={risk.hrnMpl === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 2,
                  }))
                }
              />
              <label htmlFor="hrnSev2">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev4"
                type="checkbox"
                checked={risk.hrnMpl === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 4,
                  }))
                }
              />
              <label htmlFor="hrnSev4">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev6"
                type="checkbox"
                checked={risk.hrnMpl === 6}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 6,
                  }))
                }
              />
              <label htmlFor="hrnSev6">6</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev10"
                type="checkbox"
                checked={risk.hrnMpl === 10}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 10,
                  }))
                }
              />
              <label htmlFor="hrnSev10">10</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev15"
                type="checkbox"
                checked={risk.hrnMpl === 15}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMpl: 15,
                  }))
                }
              />
              <label htmlFor="hrnSev15">15</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Número de Pessoas Expostas ao Risco (NP)</h2>
            <p>{hrnTitles.np[risk.hrnNp]}</p>
          </div>

          <div className="check-group  mb-0">
            <div className="checkbox noui">
              <input
                id="hrnPeople1"
                type="checkbox"
                checked={risk.hrnNp === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNp: 1,
                  }))
                }
              />
              <label htmlFor="hrnPeople1">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople2"
                type="checkbox"
                checked={risk.hrnNp === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNp: 2,
                  }))
                }
              />
              <label htmlFor="hrnPeople2">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople4"
                type="checkbox"
                checked={risk.hrnNp === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNp: 4,
                  }))
                }
              />
              <label htmlFor="hrnPeople4">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople8"
                type="checkbox"
                checked={risk.hrnNp === 8}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNp: 8,
                  }))
                }
              />
              <label htmlFor="hrnPeople8">8</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople12"
                type="checkbox"
                checked={risk.hrnNp === 12}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNp: 12,
                  }))
                }
              />
              <label htmlFor="hrnPeople12">12</label>
            </div>
          </div>

          <div className={classNames("hrn-bar", getHrnClassName(hrnResult))}>
            <p>HRN Atual:</p>
            <h2>
              {`${hrnResult.toLocaleString("pt-BR", {
                useGrouping: false,
              })} - ${hrnResultMessage}`}
            </h2>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md">
          <div className="module-header">
            <span>Cálculo do HRN Residual</span>
          </div>

          <div className="module-title">
            <h2>Probabilidade de Exposição (PE)</h2>
            <p>
              {hrnTitles.pe[risk.hrnPe]}

              <span
                className="question-tooltip"
                data-rh={peHelper(risk.hrnPeResidual)}
                data-rh-at="right"
              >
                ?
              </span>
            </p>

            <ReactHint events={{ click: true, hover: true }} />
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnProb03r"
                type="checkbox"
                checked={risk.hrnPeResidual === 0.03}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 0.03,
                  }))
                }
              />
              <label htmlFor="hrnProb03r">0,03</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb1r"
                type="checkbox"
                checked={risk.hrnPeResidual === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 1,
                  }))
                }
              />
              <label htmlFor="hrnProb1r">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb1_5r"
                type="checkbox"
                checked={risk.hrnPeResidual === 1.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 1.5,
                  }))
                }
              />
              <label htmlFor="hrnProb1_5r">1,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb2r"
                type="checkbox"
                checked={risk.hrnPeResidual === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 2,
                  }))
                }
              />
              <label htmlFor="hrnProb2r">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb5r"
                type="checkbox"
                checked={risk.hrnPeResidual === 5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 5,
                  }))
                }
              />
              <label htmlFor="hrnProb5r">5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb8r"
                type="checkbox"
                checked={risk.hrnPeResidual === 8}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 8,
                  }))
                }
              />
              <label htmlFor="hrnProb8r">8</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb10r"
                type="checkbox"
                checked={risk.hrnPeResidual === 10}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 10,
                  }))
                }
              />
              <label htmlFor="hrnProb10r">10</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnProb15r"
                type="checkbox"
                checked={risk.hrnPeResidual === 15}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnPeResidual: 15,
                  }))
                }
              />
              <label htmlFor="hrnProb15r">15</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Frequência de Exposição (FE)</h2>
            <p>{hrnTitles.fe[risk.hrnFeResidual]}</p>
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnFreq05r"
                type="checkbox"
                checked={risk.hrnFeResidual === 0.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 0.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq05r">0,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq1r"
                type="checkbox"
                checked={risk.hrnFeResidual === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 1,
                  }))
                }
              />
              <label htmlFor="hrnFreq1r">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq15r"
                type="checkbox"
                checked={risk.hrnFeResidual === 1.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 1.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq15r">1,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq25r"
                type="checkbox"
                checked={risk.hrnFeResidual === 2.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 2.5,
                  }))
                }
              />
              <label htmlFor="hrnFreq25r">2,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq4r"
                type="checkbox"
                checked={risk.hrnFeResidual === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 4,
                  }))
                }
              />
              <label htmlFor="hrnFreq4r">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnFreq5r"
                type="checkbox"
                checked={risk.hrnFeResidual === 5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnFeResidual: 5,
                  }))
                }
              />
              <label htmlFor="hrnFreq5r">5</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Grau de Severidade ou Probabilidade Máxima de Perda (PMP)</h2>
            <p>{hrnTitles.mpl[risk.hrnMplResidual]}</p>
          </div>

          <div className="check-group ">
            <div className="checkbox noui">
              <input
                id="hrnSev01r"
                type="checkbox"
                checked={risk.hrnMplResidual === 0.1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 0.1,
                  }))
                }
              />
              <label htmlFor="hrnSev01r">0,1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev05r"
                type="checkbox"
                checked={risk.hrnMplResidual === 0.5}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 0.5,
                  }))
                }
              />
              <label htmlFor="hrnSev05r">0,5</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev2r"
                type="checkbox"
                checked={risk.hrnMplResidual === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 2,
                  }))
                }
              />
              <label htmlFor="hrnSev2r">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev4r"
                type="checkbox"
                checked={risk.hrnMplResidual === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 4,
                  }))
                }
              />
              <label htmlFor="hrnSev4r">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev6r"
                type="checkbox"
                checked={risk.hrnMplResidual === 6}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 6,
                  }))
                }
              />
              <label htmlFor="hrnSev6r">6</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev10r"
                type="checkbox"
                checked={risk.hrnMplResidual === 10}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 10,
                  }))
                }
              />
              <label htmlFor="hrnSev10r">10</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnSev15r"
                type="checkbox"
                checked={risk.hrnMplResidual === 15}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnMplResidual: 15,
                  }))
                }
              />
              <label htmlFor="hrnSev15r">15</label>
            </div>
          </div>

          <div className="module-title">
            <h2>Número de Pessoas Expostas ao Risco (NP)</h2>
            <p>{hrnTitles.np[risk.hrnNpResidual]}</p>
          </div>

          <div className="check-group  mb-0">
            <div className="checkbox noui">
              <input
                id="hrnPeople1r"
                type="checkbox"
                checked={risk.hrnNpResidual === 1}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNpResidual: 1,
                  }))
                }
              />
              <label htmlFor="hrnPeople1r">1</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople2r"
                type="checkbox"
                checked={risk.hrnNpResidual === 2}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNpResidual: 2,
                  }))
                }
              />
              <label htmlFor="hrnPeople2r">2</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople4r"
                type="checkbox"
                checked={risk.hrnNpResidual === 4}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNpResidual: 4,
                  }))
                }
              />
              <label htmlFor="hrnPeople4r">4</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople8r"
                type="checkbox"
                checked={risk.hrnNpResidual === 8}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNpResidual: 8,
                  }))
                }
              />
              <label htmlFor="hrnPeople8r">8</label>
            </div>

            <div className="checkbox noui">
              <input
                id="hrnPeople12r"
                type="checkbox"
                checked={risk.hrnNpResidual === 12}
                onChange={() =>
                  setRisk((prev) => ({
                    ...prev,
                    hrnNpResidual: 12,
                  }))
                }
              />
              <label htmlFor="hrnPeople12r">12</label>
            </div>
          </div>

          <div
            className={classNames(
              "hrn-bar",
              getHrnClassName(hrnResultResidual)
            )}
          >
            <p>HRN Residual:</p>
            <h2>
              {`${hrnResultResidual.toLocaleString("pt-BR", {
                useGrouping: false,
              })} - ${hrnResultMessageResidual}`}
            </h2>
          </div>

          <div className="module-title">
            <h2>
              <span className="mr-2">
                Você deseja mostrar o HRN Residual na análise de riscos?
              </span>
              <input
                type="checkbox"
                className="switch mini"
                checked={risk.useHrnResidual}
                onChange={(ev) => {
                  const { checked: useHrnResidual } = ev.target;
                  setRisk((prev) => ({
                    ...prev,
                    useHrnResidual,
                  }));
                }}
              />
            </h2>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md">
          <div className="module-header">
            <span>Definição da Categoria NBR 14153</span>
            <input
              type="checkbox"
              className="switch float-right"
              checked={risk.useCategory}
              onChange={(ev) => {
                const { checked: useCategory } = ev.target;
                setRisk((prev) => ({
                  ...prev,
                  useCategory,
                }));
              }}
            />
          </div>

          {!risk.useCategory ? (
            <div style={{ marginBottom: 30 }}>
              <p>Habilite para definir a categorização.</p>
            </div>
          ) : (
            <>
              <div className="module-title">
                <h2>S - Severidade do ferimento</h2>
                <p>
                  <b>S1 -</b> Ferimento leve (normalmente reversível)
                </p>
                <p>
                  <b>S2 -</b> Ferimento sério (normalmente irreversível,
                  incluindo morte)
                </p>
              </div>

              <div className="check-group">
                <div className="checkbox noui">
                  <input
                    id="catSev1"
                    type="checkbox"
                    checked={risk.severityCategory === 1}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        severityCategory: 1,
                        frequencyCategory: 1,
                        possibilityCategory: 1,
                      }))
                    }
                  />
                  <label htmlFor="catSev1">S1</label>
                </div>

                <div className="checkbox noui">
                  <input
                    id="catSev2"
                    type="checkbox"
                    checked={risk.severityCategory === 2}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        severityCategory: 2,
                      }))
                    }
                  />
                  <label htmlFor="catSev2">S2</label>
                </div>
              </div>

              <div className="module-title">
                <h2>F - Frequência e/ou tempo de exposição ao perigo</h2>
                <p>
                  <b>F1 -</b> Raro a relativamente frequente e/ou baixo tempo de
                  exposição
                </p>
                <p>
                  <b>F2 -</b> Frequente a continuo e/ou tempo de exposição longo
                </p>
              </div>

              <div
                className={classNames("check-group", {
                  disabled: risk.severityCategory === 1,
                })}
              >
                <div className="checkbox noui">
                  <input
                    id="catFreq1"
                    type="checkbox"
                    checked={risk.frequencyCategory === 1}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        frequencyCategory: 1,
                      }))
                    }
                  />
                  <label htmlFor="catFreq1">F1</label>
                </div>

                <div className="checkbox noui">
                  <input
                    id="catFreq2"
                    type="checkbox"
                    checked={risk.frequencyCategory === 2}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        frequencyCategory: 2,
                      }))
                    }
                  />
                  <label htmlFor="catFreq2">F2</label>
                </div>
              </div>

              <div className="module-title">
                <h2>P - Possibilidade de evitar o perigo</h2>
                <p>
                  <b>P1 -</b> Possível sob condições específicas
                </p>
                <p>
                  <b>P2 -</b> Quase nunca possível
                </p>
              </div>

              <div
                className={classNames("check-group mb-0", {
                  disabled: risk.severityCategory === 1,
                })}
              >
                <div className="checkbox noui">
                  <input
                    id="catPos1"
                    type="checkbox"
                    checked={risk.possibilityCategory === 1}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        possibilityCategory: 1,
                      }))
                    }
                  />
                  <label htmlFor="catPos1">P1</label>
                </div>

                <div className="checkbox noui">
                  <input
                    id="catPos2"
                    type="checkbox"
                    checked={risk.possibilityCategory === 2}
                    onChange={() =>
                      setRisk((prev) => ({
                        ...prev,
                        possibilityCategory: 2,
                      }))
                    }
                  />
                  <label htmlFor="catPos2">P2</label>
                </div>
              </div>

              <div className="hrn-bar category">
                Categoria: <span>{category}</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="buttons text-right">
        <Button
          disabled={submitting}
          customClassName="btn-secondary"
          icon="fas fa-angle-left valign-middle"
          title="Voltar"
          onClick={() => {
            onSetRisk({ modified: true, nav: true });
          }}
        />

        <Button
          disabled={submitting}
          customClassName="btn-danger"
          icon="fas fa-times valign-middle fa-sm"
          title="Remover Ponto"
          onClick={() => {
            modal.confirm(
              "Deseja remover esse ponto de perigo?",
              (confirmed) => {
                if (confirmed) {
                  onRemoveRisk(true);
                }
              }
            );
          }}
        />

        <Button
          customClassName="btn-success"
          icon="fas fa-check valign-middle fa-sm"
          title="Salvar Ponto"
          loading={submitting}
          disabled={submitting}
          onClick={() => onSetRisk({ modified: true, nav: false })}
        />
      </div>
    </>
  );
}
