import React from 'react'
import { classNames } from '../../../helpers/misc'

export const Field = ({ label, className, children }) => (
    <div className={classNames('field is-expanded', className)}>

        <div className="field-body">
            <div className="field">{children}</div>
        </div>
    </div>
)
