import { Button } from "../../core/components/button";
import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DefaultImage from "../../assets/img/avatar-default.png";
import Brasil from "../../assets/img/br.png";
import ModalVideo from "react-modal-video";

export const VideoCollection = ({ global, route }) => {
  const [loading, setLoading] = useState(false);
  const [isOpenYoutube, playerTutorialYoutube] = useState(false);
  const [video, setVideo] = useState({
    id: 0,
    nome: "",
    descricao: "",
    videoId: "",
    tunmb: "",
  });

  const [listVideo, setListVideo] = useState([
    {
      id: 2,
      nome: "Tutorial",
      descricao: "Tutorial",
      videoId: "mlPG1BOy_4",
      tunmb: "http://i3.ytimg.com/vi/CmlPG1BOy_4/hqdefault.jpg",
    },
  ]);

  return (
    <div className="row mb-3">
      <div className="col-md">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title mb-2">
              <i className="fas fa-history"></i> Últimos Videos criados
            </h5>

            {loading ? (
              <>
                <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                  <p>
                    <Skeleton duration={1.5} height={50} count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              <div className="row">
                {listVideo.map((video) => (
                  <div key={video.id} className="col-md-2-5 d-flex pb-3">
                    <div className="card client-list text-center">
                      <a
                        onClick={() => {
                          setVideo(video);
                          playerTutorialYoutube(true);
                        }}
                      >
                        <div className="client-overlay pt-1">
                          <img
                            className="card-img-top rounded-circle p-1 overlay reeed"
                            src={video.tunmb || DefaultImage}
                            alt="Acessar Video"
                          ></img>
                        </div>
                        <div className="card-body client-list p-2 mtn-1">
                          <h4 className="card-title cut">{video.nome}</h4>
                          <p className="card-text small cut">
                            <img
                              className="valign-baseline width-10-per pr-1"
                              src={Brasil}
                              alt="Brasil"
                            ></img>
                          </p>
                          <span className="text-capitalize">
                            {video.descricao}
                          </span>
                        </div>
                      </a>
                      <div className="card-footer client-list p-1">
                        <Button
                          title="Ver Video"
                          icon="fas fa-eye valign-middle"
                          customClassName="btn-outline-light valign-baseline btn-sm mb-0 mt-0"
                          onClick={() => {
                            setVideo(video);
                            playerTutorialYoutube(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalVideo
        channel="youtube"
        title={false}
        title={0}
        isOpen={isOpenYoutube}
        videoId={video.videoId}
        onClose={() => playerTutorialYoutube(false)}
      />
    </div>
  );
};
