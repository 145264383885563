import React from 'react'
import { classNames } from '../../../helpers/misc'

export const TextAreaInput = ({
    refEl,
    meta = {},
    value,
    placeholder,
    disabled,
    label,
    onChange,
    ...textarea
}) => {
    const hasError = meta.touched && !!meta.error

    return (
        <div
            className={classNames('form-group', {
                'is-loading': meta.loading
            })}
        >
            {label && <label>{label}</label>}

            <textarea
                {...textarea}
                disabled={disabled}
                placeholder={placeholder}
                ref={el => refEl && refEl(el)}
                className="form-control"
                onChange={ev => onChange && onChange(ev.target.value, 'key')}
                onBlur={ev => onChange && onChange(ev.target.value, 'blur')}
                value={value}
            />

            {hasError && <p className="help is-danger">{meta.error}</p>}
        </div>
    )
}
