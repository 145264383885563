import React from 'react'

export const LoginErrorMessage = ({ error }) =>
    error ? <span className="errormsg wrong-login">{error}</span> : null

export const ErrorMessage = ({ error }) =>
    error ? (
        <div className="alert alert-danger" role="alert">
            {error}
        </div>
    ) : null
