import React, { useState, useEffect, useMemo, useRef } from "react";

import CreatableSelect from "react-select/creatable";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useForm } from "../../../core/hooks/form";
import {
  addOrUpdate,
  get,
  getById,
  getList,
  post,
  remove,
} from "../../../service";
import { parseNumber } from "../../../core/utils/parse";
import { userType } from "../../user";
import { Select } from "../../../core/components/form/select";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { Button } from "../../../core/components/button";
import { getAnalisyName, getStudiesName } from "../utils";
import { toastType } from "../../../core/helpers/misc";

export const RiskStudiesForm = ({
  route,
  global: { modal, operator, refresh, loading },
}) => {
  const [removeFotoId, setRemoveFotoId] = useState(0);
  const [removeRelationsId, setRemoveRelationsId] = useState(0);
  const [submittingRemove, setSubmittingRemove] = useState(false);
  const [customer, setCustomer] = useState({});
  const [analysis, setAnalysis] = useState([]);

  const [studiesImageList, setStudiesImageList] = useState([]);
  const [studiesRelationsList, setStudiesRelationsList] = useState([]);
  const [featuresSecurityList, setFeaturesSecurityList] = useState([]);
  const [descriptionSecurityList, setDescriptionSecurityList] = useState([]);

  const instaledTypes = useMemo(() => [
    { id: 0, name: "Não" },
    { id: 1, name: "Sim" },
    { id: 2, name: "Parcial" },
  ]);

  const [selectedType, setSelectedType] = useState({ id: 0, name: "Não" });
  const [selectedFeaturesFeat, setSelectedFeaturesFeat] = useState("");
  const [selectedDescriptionComp, setSelectedDescriptionComp] = useState("");
  const [fetching, setFetching] = useState(true);
  const [fetchingAnalysis, setFetchingAnalysis] = useState(true);
  const selectedFeaturesFeatRef = useRef();
  const selectedDescriptionCompRef = useRef();

  const clearSelectedLists = () => {
    selectedFeaturesFeatRef.current.select.select.clearValue();
    selectedDescriptionCompRef.current.select.select.clearValue();
    setSelectedType({ id: 0, name: "Não" });
  };

  const routeState = route.location.state || {};
  const form = useForm(
    {
      initialEntity: {
        id: 0,
        analysisId: 0,
        createdAt: new Date(),
        creatorId: 0,
        customerId: routeState.customer ? routeState.customer.id : 0,
        customerCode: "",
        createdDevice: 1,
        resume: "",
        sequential: 0,
        completed: 0,
        analysisSequential: 0,
        studiesImage: [],
        studiesRelations: [],
      },
      validate: (values, errors) => {
        if (values.analysisId === 0) {
          errors.analysisId = {
            ...errors.analysisId,
            analysisId: "Selecione a análise de risco referente ao estudo",
          };
        }
        return errors;
      },
    },
    route
  );

  useEffect(() => {
    setFetching(true);
    if (form.entity.customerId) {
      getById("user", parseNumber(form.entity.customerId))
        .then((item) => setCustomer(item))
        .catch((err) => modal.alert(err.message))
        .finally(() => setFetching(false));
    }
    // eslint-disable-next-line
  }, [form.entity.customerId]);

  useEffect(() => {
    setStudiesImageList(studiesImageList.filter((c) => c.id !== removeFotoId));
  }, [removeFotoId]);

  useEffect(() => {
    setStudiesRelationsList(
      studiesRelationsList.filter((c) => c.id !== removeRelationsId)
    );
  }, [removeRelationsId]);

  useEffect(() => {
    get(`studies.featuresSecurity?id=${operator.id}`)
      .then((done) => {
        setFeaturesSecurityList(done);
      })
      .catch((err) => {
        modal.alert(err.message);
      });
  }, [refresh.ref]);

  useEffect(() => {
    get(`studies.descriptionSecurity?id=${operator.id}`)
      .then((done) => {
        setDescriptionSecurityList(done);
      })
      .catch((err) => {
        modal.alert(err.message);
      });
  }, [refresh.ref]);

  useEffect(() => {
    setFetchingAnalysis(true);
    if (form.entity.customerId) {
      getList("analysis", {
        usePager: false,
        filters: [
          {
            items: [
              {
                name: "customerId",
                value: entity.customerId,
              },
            ],
          },
        ],
      })
        .then(({ items }) => {
          setAnalysis(items);
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => setFetchingAnalysis(false));
    }
    // eslint-disable-next-line
  }, [form.entity.customerId]);

  useEffect(() => {
    if (operator.type == userType.ANALYST && entity.completed) {
      modal.alert("Você não possui permissão para executar essa ação.");
      route.history.goBack();
    }

    // eslint-disable-next-line
  }, [form.entity.completed]);

  const { entity, submitting, setSubmitting, handleFetch } = form;
  useEffect(() => {
    if (form.hasId && !loading) {
      handleFetch({
        action: async (code) => {
          const id = parseInt(code, 10);
          const std = await getById("studies", id).then((studies) => ({
            ...studies,
          }));

          setStudiesImageList(std.studiesImage);
          setStudiesRelationsList(std.studiesRelations);
          return std;
        },
        errorFn: (err) =>
          modal.alert(err.message, () => route.history.goBack()),
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (submitting && !submittingRemove) {
      let messageToast =
        entity.id > 0 && entity.sequential > 0
          ? `Estudo ${getStudiesName(entity)} salvo!`
          : `Estudo salvo!`;

      addOrUpdate("studies", entity, {
        text: messageToast,
        type: toastType.SUCESS,
      })
        .then((resp) => {
          if (resp) {
            form.setValues({ id: resp.id });
          }
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => setSubmitting(false));
    }
  }, [submitting]);

  const ListRelations = ({ sisSeg, setRemoveId, submitting }) =>
    sisSeg.length === 0 ? (
      <div className="info-no-content">
        <div className="inventory">
          Nenhum componente adicionado ainda. Selecione acima a relação e
          adicione o primeiro!
        </div>
      </div>
    ) : (
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Descrição</th>
            <th scope="col">Características Técnicas</th>
            <th scope="col">Componente instalado?</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {sisSeg.map((l, index) => (
            <tr key={index}>
              <th>
                <h6 className="list-title">Descrição</h6>
                {l.description}
              </th>
              <th>
                <h6 className="list-title">Características Técnicas </h6>
                {l.feature}
              </th>
              <th>
                <h6 className="list-title">Componente instalado?</h6>
                {l.isInstalled === 1
                  ? "Sim"
                  : l.isInstalled === 2
                  ? "Parcial"
                  : "Não"}
              </th>

              <th>
                <h6 className="list-title">Exluir</h6>
                <OverlayTrigger
                  key="left"
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-left`}>Excluir componente!</Tooltip>
                  }
                >
                  <span id="excluirseg" className="excluirseg">
                    {submitting ? (
                      <i className="fas fa-sync fa-spin" />
                    ) : (
                      <i
                        className="fas fa-times remove"
                        onClick={() => {
                          modal.confirm(
                            <div>
                              <span className="alert-remove">
                                <h6>
                                  Deseja remover este componente da lista?
                                </h6>
                                <span>Esta remoção é irreversível!</span>
                              </span>
                            </div>,
                            (confirmed) => {
                              if (confirmed) {
                                if (confirmed) {
                                  setSubmitting(true);
                                  post(`studies.removeStudiesRelations`, l)
                                    .then((done) => {
                                      setRemoveId(l.id);
                                    })
                                    .catch((err) => modal.alert(err.message))
                                    .finally(() => setSubmitting(false));
                                }
                              }
                            }
                          );
                        }}
                      />
                    )}
                  </span>
                </OverlayTrigger>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    );

  return (
    <div>
      <form className="box is-clearfix">
        <div className="row">
          <div className="col-md">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">
                  <div className="customer">
                    {" "}
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Voltar para equipamentos
                        </Tooltip>
                      }
                    >
                      <a
                        className="suffix"
                        onClick={() =>
                          route.history.push(
                            `/clientes/${customer.id}/equipamentos`
                          )
                        }
                      >
                        <i
                          className="fas fa-folder valign-middle fa-sm"
                          aria-hidden="true"
                        />
                        <span> Equipamentos</span>
                      </a>
                    </OverlayTrigger>
                    <span className="dash">/</span>
                    <span>Criação de Estudo</span>
                    <span className="dash">/</span>
                    <span className="data">
                      Cliente {`${customer.name ?? ""}`}{" "}
                      <i
                        className="fas fa-check-circle valign-middle fa-xs"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                </h5>
              </div>

              <div className="card-body">
                <h3 className="module-header">
                  Análise de risco usada para estudo
                </h3>
                <div className="row">
                  <div className="col-md">
                    <label>
                      Selecione análise de risco{" "}
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Primeiro, selecione uma análise de risco já criada
                            para o cliente <b>{`${customer.name}`}</b>.
                          </Tooltip>
                        }
                      >
                        <i
                          className="fas fa-question-circle fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </OverlayTrigger>
                    </label>
                    <Select
                      isLoading={fetchingAnalysis}
                      selected={analysis.find(
                        (u) => u.id === entity.analysisId
                      )}
                      placeholder="Busque e selecione uma análise"
                      getDisplay={(i) => {
                        return `${getAnalisyName(i)} `;
                      }}
                      getId={(i) => i.id}
                      items={analysis}
                      onChange={(analysis) => {
                        form.handleChange({
                          path: "analysis",
                          values: {
                            analysisId: analysis.id,
                            analysisSequential: analysis.sequential,
                            resume: `${getAnalisyName(analysis)} `,
                            customerCode: customer.customerCode,
                          },
                        });
                        setSubmitting(true);
                      }}
                    />
                  </div>
                </div>

                {fetching ? (
                  renderSkeleton()
                ) : (
                  <div>
                    <h3 className="module-header mt-3">
                      Estudo para Proteções
                    </h3>
                    <div className="row">
                      <div className="col-md">
                        <h3>
                          Imagens do estudo{" "}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-right`}>
                                Apenas arquivos de imagens suportados
                              </Tooltip>
                            }
                          >
                            <i
                              className="fas fa-question-circle valign-middle fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </h3>

                        <div className="risk-slider">
                          <div className="box box-add">
                            <span>Adicionar nova</span>
                            <PhotoBox
                              modal={modal}
                              readOnly={!entity.id}
                              onChange={(data) => {
                                if (data) {
                                  setSubmitting(true);
                                  post(`studies.addImage`, {
                                    customerId: entity.customerId,
                                    studiesId: entity.id,
                                    studiesImageType: 0,
                                    photo: {
                                      data,
                                    },
                                  })
                                    .then((done) => {
                                      setStudiesImageList((prev) => [
                                        ...prev,
                                        done,
                                      ]);
                                    })
                                    .catch((err) => modal.alert(err.message))
                                    .finally(() => setSubmitting(false));
                                }
                              }}
                            />
                          </div>

                          {studiesImageList
                            .filter((c) => c.studiesImageType === 0)
                            .map((d, i) => {
                              return (
                                <div className="box" key={`d${i}`}>
                                  <div className="container text-center">
                                    <p className="box-title">
                                      {`${i + 1}º Estudo`}
                                    </p>
                                  </div>
                                  <div className="box-footer">
                                    <OverlayTrigger
                                      key={`imageEstudo${i}`}
                                      placement="top"
                                      overlay={
                                        <div className="fotos mb-3">
                                          <PhotoBox
                                            readOnly={true}
                                            photo={d.photo}
                                            modal={modal}
                                          />
                                        </div>
                                      }
                                    >
                                      <span id="PreviewEstudo" className="mr-2">
                                        <i
                                          className="fas fa-camera preview"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      key={`excluirEstudo${i}`}
                                      placement="right"
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          Excluir imagem de estudo
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        id={`excluirEstudoSpan${i}`}
                                        onClick={() => {
                                          modal.confirm(
                                            <div>
                                              <span className="alert-remove">
                                                <h6>
                                                  Deseja remover essa imagem de
                                                  estudo?
                                                </h6>
                                                <span>
                                                  Esta remoção é irreversível!
                                                </span>
                                              </span>
                                            </div>,
                                            (confirmed) => {
                                              if (confirmed) {
                                                if (confirmed) {
                                                  setSubmitting(true);
                                                  post(`studies.removeImage`, d)
                                                    .then(() => {
                                                      setRemoveFotoId(d.id);
                                                    })
                                                    .catch((err) =>
                                                      modal.alert(err.message)
                                                    )
                                                    .finally(() =>
                                                      setSubmitting(false)
                                                    );
                                                }
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        <i className="fas fa-times remove" />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              );
                            })
                            .reverse()}
                        </div>
                      </div>
                    </div>

                    <h3 className="module-header mt-3">
                      Diagrama de componentes elétricos
                    </h3>
                    <div className="row">
                      <div className="col-md">
                        <h3>
                          Imagens de diagrama{" "}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-right`}>
                                Apenas arquivos de imagens suportados
                              </Tooltip>
                            }
                          >
                            <i
                              className="fas fa-question-circle valign-middle fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </h3>
                        <div className="risk-slider">
                          <div className="box box-add">
                            <span>Adicionar nova</span>
                            <PhotoBox
                              modal={modal}
                              readOnly={!entity.id}
                              onChange={(data) => {
                                if (data) {
                                  setSubmitting(true);
                                  post(`studies.addImage`, {
                                    customerId: entity.customerId,
                                    studiesId: entity.id,
                                    studiesImageType: 1,
                                    photo: {
                                      data,
                                    },
                                  })
                                    .then((done) => {
                                      setStudiesImageList((prev) => [
                                        ...prev,
                                        done,
                                      ]);
                                    })
                                    .catch((err) => modal.alert(err.message))
                                    .finally(() => setSubmitting(false));
                                }
                              }}
                            />
                          </div>

                          {studiesImageList
                            .filter((c) => c.studiesImageType === 1)
                            .map((d, i) => {
                              return (
                                <div className="box" key={`d${i}`}>
                                  <div className="container text-center">
                                    <p className="box-title">
                                      {`${i + 1}º Diagrama`}
                                    </p>
                                  </div>
                                  <div className="box-footer">
                                    <OverlayTrigger
                                      key={`imageDiagrama${i}`}
                                      placement="top"
                                      overlay={
                                        <div className="fotos mb-3">
                                          <PhotoBox
                                            readOnly={true}
                                            photo={d.photo}
                                            modal={modal}
                                          />
                                        </div>
                                      }
                                    >
                                      <span
                                        id="PreviewDiagrama"
                                        className="mr-2"
                                      >
                                        <i
                                          className="fas fa-camera preview"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      key={`excluirDiagrama${i}`}
                                      placement="right"
                                      overlay={
                                        <Tooltip id={`tooltip-left`}>
                                          Excluir imagem de diagrama
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        id={`excluirDiagramaSpan${i}`}
                                        onClick={() => {
                                          modal.confirm(
                                            <div>
                                              <span className="alert-remove">
                                                <h6>
                                                  Deseja remover este diagrama
                                                  de componentes elétricos?
                                                </h6>
                                                <span>
                                                  Esta remoção é irreversível!
                                                </span>
                                              </span>
                                            </div>,
                                            (confirmed) => {
                                              if (confirmed) {
                                                if (confirmed) {
                                                  setSubmitting(true);
                                                  post(`studies.removeImage`, d)
                                                    .then(() => {
                                                      setRemoveFotoId(d.id);
                                                    })
                                                    .catch((err) =>
                                                      modal.alert(err.message)
                                                    )
                                                    .finally(() =>
                                                      setSubmitting(false)
                                                    );
                                                }
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        <i className="fas fa-times remove" />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              );
                            })
                            .reverse()}
                        </div>
                      </div>
                    </div>

                    <h3 className="module-header mt-3">
                      Relação de Componentes do Sistema de Segurança
                    </h3>
                    <div className="row">
                      <div className="col-md">
                        <div className="row">
                          <div className="col-md">
                            <label>
                              Descrição do componente{" "}
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Selecione uma ou crie uma nova descrição
                                    para este componente. Basta escrever no
                                    seletor abaixo e criar, ou selecionar.
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fas fa-question-circle fa-fw fa-xs"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </label>
                            <div className="form-group">
                              <CreatableSelect
                                classNamePrefix="react-select"
                                className="react-select-container"
                                isClearable={true}
                                isLoading={fetching}
                                ref={selectedDescriptionCompRef}
                                options={descriptionSecurityList.map(
                                  ({ id, description }) => ({
                                    label: description,
                                    value: id,
                                  })
                                )}
                                onChange={(item, { action }) => {
                                  const description = item ? item.label : "";
                                  setSelectedDescriptionComp(description);
                                  if (action === "create-option") {
                                    post("studies.descriptionSecurity", {
                                      description: description,
                                      userId: operator.id,
                                    })
                                      .then((done) => {
                                        setDescriptionSecurityList((prev) => [
                                          ...prev,
                                          done,
                                        ]);
                                      })
                                      .catch((err) => modal.alert(err.message));
                                  }
                                }}
                                placeholder="Selecione ou crie uma descrição"
                                formatCreateLabel={(v) => {
                                  return `Criar nova descrição "${v}"`;
                                }}
                                noOptionsMessage={() =>
                                  "Nenhuma descrição foi localizada! Crie uma nova ou melhore a busca."
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md">
                            <label>
                              Característica técnica{" "}
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Selecione uma ou crie uma nova
                                    característica técnica para este componente.
                                    Basta escrever no seletor abaixo e criar, ou
                                    selecionar.
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fas fa-question-circle fa-fw fa-xs"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </label>
                            <div className="form-group">
                              <CreatableSelect
                                class="cratable-select"
                                classNamePrefix="react-select"
                                ref={selectedFeaturesFeatRef}
                                isClearable={true}
                                isLoading={fetching}
                                options={featuresSecurityList.map(
                                  ({ id, description }) => ({
                                    label: description,
                                    value: id,
                                  })
                                )}
                                onChange={(item, { action }) => {
                                  const description = item ? item.label : "";
                                  setSelectedFeaturesFeat(description);
                                  if (action === "create-option") {
                                    post("studies.featuresSecurity", {
                                      description: description,
                                      userId: operator.id,
                                    })
                                      .then((done) => {
                                        setFeaturesSecurityList((prev) => [
                                          ...prev,
                                          done,
                                        ]);
                                      })
                                      .catch((err) => modal.alert(err.message));
                                  }
                                }}
                                placeholder="Selecione ou crie uma característica"
                                formatCreateLabel={(v) => {
                                  return `Criar nova característica técnica "${v}"`;
                                }}
                                noOptionsMessage={() =>
                                  "Nenhuma característica foi localizada! Crie uma nova ou melhore a busca."
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md">
                            <label>
                              Componente está instalado{" "}
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Componente está instalado? Sim ou não.
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fas fa-question-circle fa-fw fa-xs"
                                  aria-hidden="true"
                                ></i>
                              </OverlayTrigger>
                            </label>
                            <Select
                              getDisplay={(i) => i.name}
                              getId={(i) => i.id}
                              items={instaledTypes}
                              selected={selectedType || undefined}
                              onChange={(type) => {
                                setSelectedType(type);
                              }}
                            />
                          </div>
                          <div className="col">
                            <label>&nbsp;</label>
                            <Button
                              customClassName="btn btn-success btn-block"
                              disabled={
                                submitting ||
                                !selectedFeaturesFeat ||
                                !selectedDescriptionComp ||
                                !selectedType ||
                                !entity.id
                              }
                              icon="fas fa-check valign-middle fa-sm valign-middle fa-sm"
                              title="Adicionar Novo"
                              onClick={() => {
                                clearSelectedLists();

                                setSubmitting(true);
                                post(`studies.addStudiesRelations`, {
                                  studiesId: entity.id,
                                  description: selectedDescriptionComp,
                                  feature: selectedFeaturesFeat,
                                  isInstalled: selectedType.id,
                                })
                                  .then((done) => {
                                    setStudiesRelationsList((prev) => [
                                      ...prev,
                                      done,
                                    ]);
                                  })
                                  .catch((err) => modal.alert(err.message))
                                  .finally(() => setSubmitting(false));
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="table-responsive">
                            <ListRelations
                              sisSeg={studiesRelationsList}
                              setRemoveId={setRemoveRelationsId}
                              submitting={submitting}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="buttons text-right">
                  <button
                    disabled={submitting || fetchingAnalysis || fetching}
                    className="btn btn-secondary"
                    onClick={() =>
                      route.history.push(
                        `/clientes/${customer.id}/equipamentos`
                      )
                    }
                  >
                    <i
                      className="fas fa-angle-left valign-middle fa-sm"
                      aria-hidden="true"
                    />
                    <span>Voltar</span>
                  </button>

                  {entity.id !== 0 &&
                    (operator.type === userType.ENGINEER ||
                      operator.type === userType.ANALYST) &&
                    operator.canDeleteAnalisys && (
                      <Button
                        customClassName="btn-danger"
                        disabled={
                          submitting ||
                          fetchingAnalysis ||
                          fetching ||
                          !entity.id
                        }
                        icon="fas fa-times valign-middle fa-sm"
                        title="Remover Estudo"
                        onClick={() =>
                          modal.confirm(
                            <div>
                              <p className="alert-remove">
                                <h6>
                                  Esta ação é irreversível! Será excluído:
                                </h6>
                                <ul className="lista text-left">
                                  <li>
                                    Todas as fotos cadastradas neste estudo
                                  </li>
                                  <li>
                                    Todos os dados e informações do estudo
                                  </li>
                                  <li>Esta ação é irreversível!</li>
                                </ul>
                                <span>
                                  Deseja realmente remover este estudo?
                                </span>
                              </p>
                            </div>,
                            (confirmed) => {
                              if (confirmed) {
                                setSubmitting(true);
                                setSubmittingRemove(true);
                                remove("studies", entity.id)
                                  .then(() => {
                                    refresh();
                                    route.history.goBack();
                                  })
                                  .catch((err) => modal.alert(err.message))
                                  .finally(() => {
                                    setSubmitting(false);
                                    setSubmittingRemove(false);
                                  });
                              }
                            }
                          )
                        }
                      />
                    )}

                  <button
                    className="btn btn-success"
                    disabled={
                      submitting || fetchingAnalysis || fetching || !entity.id
                    }
                    onClick={() => setSubmitting(true)}
                  >
                    {submitting ? (
                      <i className="fas fa-spinner fa-spin valign-middle" />
                    ) : (
                      <>
                        <i
                          className="fas fa-check valign-middle fa-sm valign-middle fa-sm"
                          aria-hidden="true"
                        />
                        <span>Salvar Estudo</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

function renderSkeleton() {
  return (
    <SkeletonTheme color="#2E2F42" highlightColor="#404258">
      <h3 className="module-header mt-3">Estudo para Proteções</h3>
      <div className="row">
        <div className="col-md">
          <h3>Imagens de estudo</h3>
          <div className="mb-2">
            <Skeleton duration={1.5} height={87} count={1} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3 className="module-header mt-3">
            {" "}
            Diagrama de componentes elétricos
          </h3>
          <div className="mb-2">
            <Skeleton duration={1.5} height={87} count={1} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3 className="module-header mt-3">
            {" "}
            Relação de Componentes do Sistema de Segurança
          </h3>
          <div className="row">
            <div className="col">
              <Skeleton duration={1.5} height={45} count={1} />
            </div>
            <div className="col">
              <Skeleton duration={1.5} height={45} count={1} />
            </div>
            <div className="col">
              <Skeleton duration={1.5} height={45} count={1} />
            </div>
            <div className="col">
              <Skeleton duration={1.5} height={45} count={1} />
            </div>
          </div>
          <div className="mb-2 mt-3">
            <Skeleton duration={1.5} height={45} count={3} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
