import React, { useState, useCallback } from 'react'
import { classNames } from '../../../helpers/misc'
import { parseNumber } from '../../../utils/parse'

export const IntegerInput = ({
    refEl,
    meta = {},
    placeholder,
    disabled,
    value,
    min,
    max,
    step,
    label,
    onChange,
    showButtons,
    ignoreEnter = true,
    ...input
}) => {
    const hasError = meta.touched && !!meta.error
    const [active, setActive] = useState(false)

    const handleChange = useCallback((value, type) => {
            if (!!onChange) {
                let num = value === '' ? null : parseNumber(value)

                if (!!max && !!num) {
                    num = Math.min(num, max)
                }

                if (!!min && !!num) {
                    num = Math.max(min, num)
                }

                onChange(num, type)
            }
        },
        [max, min, onChange]
    )

    return (
        <div
            className={classNames('input-field', {
                'number-field': showButtons && !input.readOnly,
                'is-loading': meta.loading
            })}
        >
            {showButtons && !input.readOnly && (
                <>
                    <button
                        className="btn btn-outline-secondary btn-minus"
                        type="button"
                        disabled={value === min}
                        onClick={() => handleChange(((value || 0) - 1).toString(), 'key')}
                    >
                        <i className="fas fa-minus" />
                    </button>

                    <button
                        className="btn btn-outline-secondary btn-plus"
                        type="button"
                        onClick={() => handleChange(((value || 0) + 1).toString(), 'key')}
                    >
                        <i className="fas fa-plus" />
                    </button>
                </>
            )}

            <input
                {...input}
                disabled={disabled}
                placeholder={placeholder}
                type="number"
                ref={el => refEl && refEl(el)}
                className={classNames({
                    invalid: hasError
                })}
                onChange={ev => handleChange(ev.target.value, 'key')}
                onBlur={ev => {
                    setActive(false)
                    handleChange(ev.target.value, 'blur')
                }}
                onFocus={() => {
                    setActive(true)
                }}
                onKeyDown={ev => ev.keyCode === 13 && ignoreEnter && ev.preventDefault()}
                value={value || ''}
                min={min}
                max={max}
                step={step}
            />

            {label && (
                <label
                    className={classNames({
                        active: active || (!!value && value > 0)
                    })}
                >
                    {label}
                </label>
            )}

            {hasError && typeof meta.error === 'string' && (
                <p className="help-error">{meta.error}</p>
            )}
        </div>
    )
}
