import React, { useEffect } from 'react'
import { useForm } from '../../core/hooks/form'
import { requiredMessage } from '../../core/utils/misc'
import { getById, addOrUpdate, getList } from '../../service'
import { parseNumber } from '../../core/utils/parse'
import { TextInput } from '../../core/components/form/text-input'
import { TextAreaInput } from '../../core/components/form/textarea-input'
import { Button } from '../../core/components/button'
import { ErrorMessage } from '../../core/components/form/error-message'
import AsyncSelect from 'react-select/async'

function onSubmit(user, form, route, modal) {
    addOrUpdate('standard', user)
        .then(() => {
            form.setSubmitting(false)

            return modal.alert(
                `Norma ${form.hasId ? 'atualizada' : 'cadastrada'} com sucesso.`,
                () => route.history.push('/norma')
            )
        })
        .catch(err => form.setErrors({ _error: err.message }))
}

export const StandardForm = ({ global, route }) => {
    const form = useForm(
        {
            initialEntity: {
                id: 0,
                code: '',
                description: '',
                titleId: 0
            },
            validate: (values, errors) => {
                if (!values.code) {
                    errors.code = requiredMessage
                }

                if (!values.description) {
                    errors.description = requiredMessage
                }

                if (!values.titleId) {
                    errors.title = requiredMessage
                }

                return errors
            }
        },
        route
    )

    const { entity, errors, touched } = form

    useEffect(() => {
        if (form.hasId) {
            form.handleFetch({
                action: (id, ac) => getById('standard', parseNumber(id), ac.signal),
                errorFn: err => global.modal.alert(err.message, () => route.history.goBack()),
                mapper: ent => ({
                    ...ent,
                    title: {
                        id: ent.titleId,
                        name: ent.titleName
                    }
                })
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <form
                className="box is-clearfix"
                onSubmit={ev => {
                    ev.preventDefault()
                    form.handleSubmit(ent => onSubmit(ent, form, route, global.modal))
                }}
            >
                {form.fetching ? (
                    <i className="fas fa-spinner fa-spin" />,
                    'Carregando...'
                ) : (
                    <div className="row">
                        <div className="col-md">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        {form.hasId ? 'Editar Norma' : 'Cadastrar Norma'}
                                    </h5>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md">
                                            <TextInput
                                                label="Número"
                                                value={entity.code}
                                                meta={{
                                                    error: errors.code,
                                                    touched: touched.code
                                                }}
                                                onChange={(code, type) =>
                                                    form.handleChange({
                                                        path: 'code',
                                                        type,
                                                        values: { code }
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="col-md">
                                            <div className="form-group">
                                                <label>Titulo</label>

                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    classNamePrefix="react-select"
                                                    getOptionValue={({ id }) => id}
                                                    getOptionLabel={({ name }) => name}
                                                    placeholder="Selecione..."
                                                    value={entity.title}
                                                    onChange={title =>
                                                        form.setValues({ title, titleId: title.id })
                                                    }
                                                    loadOptions={txt =>
                                                        getList('standardtitle', {
                                                            filters: [
                                                                {
                                                                    items: [
                                                                        {
                                                                            name: 'name',
                                                                            value: txt,
                                                                            comparer: 'Like'
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }).then(({ items }) => items)
                                                    }
                                                />

                                                {touched.titleId && !!errors.title && (
                                                    <p className="help-error">{errors.title}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            <TextAreaInput
                                                label="Descrição"
                                                rows={10}
                                                value={entity.description}
                                                meta={{
                                                    error: errors.description,
                                                    touched: touched.description
                                                }}
                                                onChange={(description, type) =>
                                                    form.handleChange({
                                                        path: 'description',
                                                        type,
                                                        values: { description }
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="text-right">
                                        <Button
                                            customClassName="btn-success"
                                            icon="fas fa-check valign-middle fa-sm"
                                            title="Salvar"
                                            loading={form.submitting}
                                            disabled={form.submitting}
                                        />
                                    </div>

                                    <ErrorMessage error={errors._error} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
}
