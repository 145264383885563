import React, { useState, useLayoutEffect } from "react";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { useForm } from "../../core/hooks/form";
import { requiredMessage } from "../../core/utils/misc";
import { isEmail } from "../../core/utils/validate";
import { localStore } from "../../core/utils/store";
import { config } from "../../config";
import Logo from "../../assets/img/logo.svg";
import localForage from "localforage";
import { loginRequest, get, post } from "../../service";
import { LoginErrorMessage } from "../../core/components/form/error-message";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

function tryLogin(login, route, form, global) {
  loginRequest(login)
    .then((resp) => {
      localStore.set(config.TOKEN_KEY, resp.accessToken);
      localStore.set(config.OPERATOR_KEY, {
        ...resp.user,
        apiVersion: resp.version,
      });

      get("analysis.lists")
        .then((lists) => localForage.setItem(config.LISTS_KEY, lists))
        .then(() => global.refresh(true))
        .then(() => route.history.push("/"));
    })
    .catch((err) => form.setErrors({ _error: err.message }));
}

function recoverPassword(login, route, form, global) {
  post("password.recover", login)
    .then(() =>
      global.modal.alert("E-mail enviado com sucesso!", () =>
        route.history.push("/")
      )
    )
    .catch((err) => global.modal.alert(err.message))
    .finally(() => form.setSubmitting(false));
}

function changePassword(login, route, form, global, token) {
  post("password.change", {
    token,
    password: login.password,
  })
    .then(() => {
      global.modal.alert(`Senha atualizada com sucesso.`);
      form.resetForm();
      route.history.push("/login");
    })
    .catch((err) => form.setErrors({ global: err.message }));
}

function renderLogin(form, route, setPage) {
  const { entity, errors, touched } = form;

  return (
    <>
      <TextInput
        type="email"
        label="E-mail cadastrado"
        value={entity.email}
        autoComplete="email"
        meta={{
          error: errors.email,
          touched: touched.email,
        }}
        onChange={(email, type) =>
          form.handleChange({
            path: "email",
            type,
            values: { email },
          })
        }
      />

      <TextInput
        type="password"
        label="Senha cadastrada"
        value={entity.password}
        ignoreEnter={false}
        autoComplete="current-password"
        meta={{
          error: errors.password,
          touched: touched.password,
        }}
        onChange={(password, type) =>
          form.handleChange({
            path: "password",
            type,
            values: { password },
          })
        }
      />

      <Button
        customClassName="login-btn"
        icon="fas fa-sign-in-alt fa-xs"
        title="Acessar"
        loading={form.submitting}
        disabled={form.submitting}
      />

      <LoginErrorMessage error={errors._error} />

      <button
        type="button"
        className="recover-link"
        tabIndex={0}
        onClick={(ev) => {
          ev.preventDefault();
          setPage("forgot");
        }}
      >
        <span>Não lembro a minha senha</span>
        <i className="fas fa-angle-right" />
      </button>

      <div className="login-divider">
        <h5>
          <span>ou</span>
        </h5>
      </div>

      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Tooltip id={`tooltip-right`}>
            <strong>Orçamento</strong> ou dúvidas sobre a Normatiza? Fale com a gente no WhatsApp!
          </Tooltip>
        }
      >
        

        <a
          className="btn register-btn"
          target="_blank"
          //id="cadastrar"
          //tabIndex={0}
          href="https://normatiza.app/whatsapp"
        >
          <span>Fale conosco pelo WhatsApp</span>
          
        </a>
      </OverlayTrigger>
    </>
  );
}

function renderForget(form, setPage) {
  const { entity, errors, touched } = form;

  return (
    <>
      <p className="text-center login-help">
        <i className="fas fa-life-ring"></i> Insira abaixo seu e-mail:
      </p>
      <TextInput
        label="E-mail cadastrado"
        value={entity.email}
        autoComplete="email"
        meta={{
          error: errors.email,
          touched: touched.email,
        }}
        onChange={(email, type) =>
          form.handleChange({
            path: "email",
            type,
            values: { email },
          })
        }
      />

      <Button
        customClassName="login-btn"
        title="Recuperar minha senha"
        loading={form.submitting}
        disabled={form.submitting}
      />

      <button
        type="button"
        className="login-link"
        tabIndex={0}
        onClick={(ev) => {
          ev.preventDefault();
          setPage("login");
        }}
      >
        <i className="fas fa-angle-left valign-middle" />
        <span>Voltar</span>
      </button>
    </>
  );
}

function renderRecover(form) {
  const { entity, errors, touched, handleChange, submitting } = form;

  return (
    <>
      <TextInput
        acceptEnter
        type="password"
        label="Nova Senha"
        value={entity.password}
        autoComplete="current-password"
        meta={{
          error: errors.password,
          touched: touched.password,
        }}
        onChange={(password, type) =>
          handleChange({
            path: "password",
            type,
            values: { password },
          })
        }
      />

      <TextInput
        acceptEnter
        type="password"
        label="Confirme a Nova Senha"
        value={entity.confirmPassword}
        autoComplete="current-password"
        meta={{
          error: errors.confirmPassword,
          touched: touched.confirmPassword,
        }}
        onChange={(confirmPassword, type) =>
          handleChange({
            path: "confirmPassword",
            type,
            values: { confirmPassword },
          })
        }
      />

      <Button
        customClassName="login-btn"
        title="Enviar"
        loading={submitting}
        disabled={submitting}
      />

      <LoginErrorMessage error={errors._error} />
      <br />
      <br />
    </>
  );
}

export const Login = ({ route, global }) => {
  const { token } = route.match.params;
  const [page, setPage] = useState(null);
  const form = useForm(
    {
      initialEntity: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      validate: (values, err) => {
        if (page === "login" || page === "forgot") {
          if (!values.email) {
            err.email = requiredMessage;
          } else if (!isEmail(values.email)) {
            err.email = "Digite um endereço de email válido.";
          }
        }

        if (!values.password && (page === "login" || page === "recover")) {
          err.password = requiredMessage;
        }

        if (!values.confirmPassword && page === "recover") {
          err.confirmPassword = requiredMessage;
        }

        if (values.password !== values.confirmPassword && page === "recover") {
          err.confirmPassword = "Senhas devem ser iguais.";
        }

        return err;
      },
    },
    route
  );

  useLayoutEffect(() => {
    setPage(token ? "recover" : "login");
  }, [token]);

  return (
    <div className="login-page pb-1">
      <div className="login-logo">
        <img src={Logo} alt="Normatiza" />
        <small style={{ color: `#ffffff` }}>
          <i className="fas fa-code" aria-hidden="true"></i> BETA v
          {config.VERSION} / API v{config.APIVERSION}
        </small>
      </div>

      <div className="login-box">
        <div className="login-form">
          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              if (page === "recover" && token) {
                form.handleSubmit((ent) =>
                  changePassword(ent, route, form, global, token)
                );
                return;
              }

              if (page === "forgot") {
                form.handleSubmit((ent) =>
                  recoverPassword(ent, route, form, global)
                );
                return;
              }

              form.handleSubmit((ent) => tryLogin(ent, route, form, global));
            }}
          >
            {page === "login" && renderLogin(form, route, setPage)}
            {page === "forgot" && renderForget(form, setPage)}
            {page === "recover" && renderRecover(form)}
          </form>
        </div>
      </div>
    </div>
  );
};
