import React, { useState, useEffect } from "react";
import { get, post } from "../../service";
import { useRefresh } from "../../core/hooks/refresh";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const List = ({ users, refresh, modal }) => {
  return users.length === 0 ? (
    <div className="alert alert-light fade show" role="alert">
      Nenhum engenheiro foi cadastrado.
    </div>
  ) : (
    <table className="table">
      <tbody>
        {users.map((u) => (
          <tr key={u.id}>
            <th>
              <h6 className="list-title">ID do usuário</h6>
              {u.id}
            </th>
            <th>
              <h6 className="list-title">Nome do usuário</h6>
              {u.name}
            </th>
            <th>
              <h6 className="list-title">
                {u.isTrialUser ? "Limite Atual (Trial)" : "Limite Atual"}
              </h6>
              {u.maxAnalysis ? (
                u.maxAnalysis
              ) : u.isTrialUser ? (
                u.trialLimit
              ) : (
                <i className="fas fa-infinity" />
              )}
            </th>
            <th>
              <h6 className="list-title">Equipamentos Cadastrados</h6>
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    Visualizar consumo de créditos
                  </Tooltip>
                }
              >
                <button
                  type="button"
                  id="view-usage"
                  className="table-action white"
                  onClick={() => {
                    post("user.limit-consume", { id: u.id })
                      .then((consume) => {
                        if (consume) {
                          modal.generic(<ListConsumer list={consume} />);
                        }
                      })
                      .catch((err) => modal.alert(err.message));
                  }}
                >
                  {u.used}
                </button>
              </OverlayTrigger>
            </th>

            <th className="no-wrap no-width">
              <h6 className="list-title">Ação</h6>
              <button
                type="button"
                className="btn btn-light btn-xs mb-0 mt-0"
                onClick={() =>
                  modal.prompt(
                    "Alterar Limite",
                    "Insira um novo limite. Deixe vazio para ilimitado.",
                    (maxAnalysis) =>
                      post("user.limit", { id: u.id, maxAnalysis })
                        .then(refresh)
                        .catch((err) => modal.alert(err.message)),
                    "number"
                  )
                }
              >
                <i className="fas fa-edit"></i> <span>Editar Limite</span>
              </button>
            </th>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ListConsumer = (consume) => {
  return consume.length === 0 ? (
    <div className="alert alert-light fade show" role="alert">
      Nenhum Consumo encontrado no período.
    </div>
  ) : (
    <div class="table-wrapper-scroll-y add-scrollbar">
      <table className="table-responsive table-sm">
        <thead>
          <tr>
            <th>Período</th>
            <th>Descrição</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {consume.list.map((c) => (
            <tr key={c.id}>
              <td>{c.period}</td>
              <td>{c.custumer}</td>
              <td className="text-right">{c.count ?? 0}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <br />
          <tr>
            <th id="total" colspan="3">
              Total :
            </th>
            <td className="text-right">
              <strong>
                {consume.list.reduce((prev, cur) => prev + cur.count, 0)}
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
export const UserLimitList = ({ global }) => {
  const { modal } = global;
  const [fetching, setFetching] = useState(true);
  const [users, setUsers] = useState([]);
  const refresh = useRefresh();

  useEffect(() => {
    let mounted = true;

    get("user.limit")
      .then((items) => mounted && setUsers(items))
      .catch((err) => modal.alert(err.message))
      .finally(() => mounted && setFetching(false));

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, [refresh.ref]);

  return (
    <>
      <div className="row">
        <div className="col-md">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">
                <i className="menu-icon fas fa-user-tag"></i> Limite e Consumo
                de Engenheiros
              </h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {fetching ? (
                  <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                    <p>
                      <Skeleton height={65} count={20} />
                    </p>
                  </SkeletonTheme>
                ) : (
                  <List users={users} modal={modal} refresh={refresh.force} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
