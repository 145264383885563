import { config } from "../config";
import { cleanObject, showToast, toastType } from "../core/helpers/misc";
import { parseStatus, getHeader, convertQuery, getTokenHeader } from "./utils";

export async function loginRequest(data) {
  const response = await fetch(`${config.API_URL}/login.user/`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getHeader(),
    method: "POST",
  });

  return parseStatus(response);
}

export async function get(endpoint) {
  const response = await fetch(`${config.API_URL}/${endpoint}`, {
    headers: getHeader(),
  });

  return parseStatus(response);
}

export async function getExternal(endpoint) {
  const response = await fetch(`${endpoint}`, { method: "get" });

  return parseStatus(response);
}

export async function post(endpoint, data) {
  const response = await fetch(`${config.API_URL}/${endpoint}/`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getHeader(),
    method: "POST",
  });

  return parseStatus(response);
}

export async function postFile(endpoint, data) {
  const formData = new FormData();
  formData.append("file", data);
  const response = await fetch(`${config.API_URL}/${endpoint}/`, {
    body: formData,
    headers: getTokenHeader(),
    method: "POST",
  });

  return parseStatus(response);
}

export async function downloadReport(id, pdf, name) {
  showToast({
    text: `Gerando PDF da Análise ${name}`,
    type: toastType.INFO,
  });
  const res = await fetch(
    `${config.API_URL}/analysis.report/${id}?pdf=${pdf}`,
    {
      headers: getHeader(),
    }
  );

  return parseStatus(res, (res) => res.blob());
}

export async function downloadStudiesReport(id, pdf, name) {
  showToast({
    text: `Gerando PDF do Estudo ${name}`,
    type: toastType.INFO,
  });

  const res = await fetch(`${config.API_URL}/studies.report/${id}?pdf=${pdf}`, {
    headers: getHeader(),
  });

  return parseStatus(res, (res) => res.blob());
}

export async function downloadInventory(customerId, name) {
  showToast({
    text: `Gerando PDF ${name}`,
    type: toastType.INFO,
  });
  const res = await fetch(`${config.API_URL}/machine.report/${customerId}`, {
    headers: getHeader(),
  });

  return parseStatus(res, (res) => res.blob());
}

export async function downloadRiskReport(customerId, name) {
  showToast({
    text: `Gerando PDF ${name}`,
    type: toastType.INFO,
  });
  const res = await fetch(
    `${config.API_URL}/analitics.riskReport/${customerId}`,
    {
      headers: getHeader(),
    }
  );

  return parseStatus(res, (res) => res.blob());
}

export async function getList(name, query, signal) {
  const response = await fetch(`${config.API_URL}/${name}.list/`, {
    body: JSON.stringify(convertQuery(query)),
    headers: getHeader(),
    method: "POST",
    signal,
  });

  return parseStatus(response);
}

export async function getById(name, id, signal) {
  const response = await fetch(`${config.API_URL}/${name}.key/${id}`, {
    headers: getHeader(),
    signal,
  });

  return parseStatus(response);
}

export async function add(name, data, toastParams = null) {
  if (toastParams) {
    showToast(toastParams);
  }

  const response = await fetch(`${config.API_URL}/${name}.add/`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getHeader(),
    method: "POST",
  });

  return parseStatus(response);
}

export async function update(name, data, toastParams = null) {
  if (toastParams) {
    showToast(toastParams);
  }
  const response = await fetch(`${config.API_URL}/${name}.update/`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getHeader(),
    method: "PUT",
  });

  return parseStatus(response);
}

export async function remove(name, key, toastParams = null) {
  if (toastParams) {
    showToast(toastParams);
  }
  const response = await fetch(`${config.API_URL}/${name}.delete/${key}`, {
    headers: getHeader(),
    method: "DELETE",
  });

  return await parseStatus(response);
}

export const addOrUpdate = (name, data, toastParams = null) =>
  data.id > 0 ? update(name, data, toastParams) : add(name, data, toastParams);

export const addUpdateOrRemove = (name, data, toastParams = null) =>
  data.removed
    ? remove(name, data.id, toastParams)
    : data.id > 0
    ? update(name, data, toastParams)
    : add(name, data, toastParams);
