import React from 'react'
import { Route } from 'react-router'

export const PageRoute = ({ component: Component, global, ...rest }) => (
    <Route
        {...rest}
        render={r =>
            Component ? <Component route={r} global={global} /> : <div>undefined component</div>
        }
    />
)
