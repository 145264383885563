import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import calendar from "../../assets/img/011-calendar.png";
import { useForm } from "../../core/hooks/form";
import { parseNumber } from "../../core/utils/parse";
import { addOrUpdate, get, getById } from "../../service";
import { userType } from "./utils";
import { Select } from "../../core/components/form/select";

function onSubmit(user, form, route, { modal, refresh, operator }) {
  addOrUpdate("user", user)
    .then((resp) => {
      form.setSubmitting(false);
      modal.alert("Modelo alterado com sucesso! ");
    })

    .catch((err) => form.setErrors({ _error: err.message }));
}

export const UserConsumeHistory = ({ global, route }) => {
  const form = useForm(
    {
      initialEntity: {
        id: 0,
        reportType: 0,
      },
      validate: (values, errors) => {
        return errors;
      },
    },
    route
  );
  const { entity, errors, touched } = form;
  const [info, setInfo] = useState({
    completedAnalysisCount: 0,
    currentAnalysisBalance: 0,
    firstAnalisy: "",
    maxAnalysis: 0,
    riskCount: 0,
    stetesAnalisys: [],
    teamSize: 0,
    totalAnalysis: 0,
    totalClients: 0,
    totalStates: 0,
    usedAnalysis: 0,
    isIlimitedUser: false,
  });

  useEffect(() => {
    if (global.operator.type === userType.ENGINEER) {
      get("dashboard.engineer")
        .then((data) => {
          setInfo(data);
        })
        .catch((err) => global.modal.alert(err.message));
    }
  }, [global.loading]);

  useEffect(() => {
    if (!global.loading) {
      form.handleFetch({
        action: async (id, ac) => {
          const key = id || global.operator.id;

          const user = await getById("user", parseNumber(key), ac.signal);

          return user;
        },
        errorFn: (err) =>
          global.modal.alert(err.message, () => route.history.goBack()),
      });

      // eslint-disable-next-line
    }
  }, [global.loading]);

  return (
    <div>
      <form
        autoComplete="off"
        className="box is-clearfix"
        onSubmit={(ev) => {
          ev.preventDefault();
          form.handleSubmit((u) => onSubmit(u, form, route, global));
        }}
      >
        {form.fetching ? (
          <SkeletonTheme color="#2E2F42" highlightColor="#404258">
            <div className="row">
              <div className="col-md-3">
                <Skeleton height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={4} />
                </div>
              </div>

              <div className="col-md-9">
                <Skeleton width={200} height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={6} />
                </div>
              </div>
            </div>
          </SkeletonTheme>
        ) : (
          <div className="row">
            <div className="col-md-3">
              <div className="card my-profile">
                <div className="card-body p-2">
                  <ul className="nav flex-column">
                    <p className="menu-label pl-1">Conta</p>
                    <li className="nav-item mb-1">
                      <a className="nav-link" href="/meu-perfil">
                        <i className="fas fa-user-circle fa-lg fa-fw mr-3"></i>{" "}
                        Meus dados
                      </a>
                    </li>

                    <li className="nav-item mb-1">
                      <a className="nav-link" href="/meu-perfil/personalizar">
                        <i className="fas fa-palette fa-lg fa-fw mr-3"></i>{" "}
                        Personalizar relatórios
                      </a>
                    </li>

                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/notificacoes"
                      >
                        <i className="fas fa-bell-slash fa-lg fa-fw mr-3"></i>{" "}
                        Notificações e e-mails
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link active"
                        href="/meu-perfil/historico"
                      >
                        <i className="fas fa-history fa-lg fa-fw mr-3"></i>{" "}
                        Histórico de consumo
                      </a>
                    </li>
                    <p className="menu-label pl-1">Faturamento</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento"
                      >
                        <i className="fas fa-credit-card fa-lg fa-fw mr-3"></i>{" "}
                        Recarregar créditos
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento#planos"
                      >
                        <i className="fas fa-money-check-alt fa-lg fa-fw mr-3"></i>{" "}
                        Planos
                      </a>
                    </li>
                    <p className="menu-label pl-1">Outros</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/afiliados"
                      >
                        <i className="fas fa-hands-helping fa-lg fa-fw mr-3"></i>{" "}
                        Programa de Afiliados
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="customer-analytics mt-2">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="float-right">
                      <p className="display-inline font-medium-2">
                        {global.operator.maxAnalysis ? (
                          <div>
                            {global.operator.isTrial ? (
                              <b>
                                {info.usedAnalysis}
                                <sub> / {info.maxAnalysis} grátis</sub>
                              </b>
                            ) : (
                              <b>
                                {info.usedAnalysis}{" "}
                                <sub> de {info.maxAnalysis} usados</sub>
                              </b>
                            )}
                          </div>
                        ) : (
                          <b>
                            <i className="fas fa-infinity" /> Ilimitado
                          </b>
                        )}
                      </p>
                    </div>
                    <p>Seus créditos disponíveis:</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="ptm-3 pb-2 display-inline-table font-medium-5">
                <h5>
                  <div>Histórico de consumo e cadastro de equipamentos</div>
                </h5>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="mb-1">
                    <h5 className="card-title">
                      <div>
                        <i className="fas fa-history"></i> Histórico de consumo
                      </div>
                    </h5>
                  </div>
                  <p style={{ fontWeight: 200 }}>
                    Histórico diário, mensal e anual de equipamentos
                    cadastrados.
                  </p>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md mr-auto pl-0"></div>

                      <div className="col-md-2">
                        <Select
                          customClassName="custom"
                          placeholder={"Ano..."}
                          // value={filter.code}
                          // onChange={(code) =>
                          //   setFilter((prev) => ({ ...prev, code }))
                          // }
                        />
                      </div>

                      <div className="col-md-2">
                        <Select
                          customClassName="custom"
                          placeholder={"Mês..."}
                          // value={filter.sector}
                          // onChange={(sector) =>
                          //   setFilter((prev) => ({ ...prev, sector }))
                          // }
                        />
                      </div>
                    </div>
                  </div>
                  {/* Mostrar a div 'info-no-content' abaixo caso não esteja selecionado nada. */}
                  <div className="info-no-content">
                    <img
                      className="image"
                      alt="Nenhuma data selecionada"
                      src={calendar}
                    />
                    <div className="inventory">
                      Selecione uma data acima para visualizar o consumo.
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-hover table-sm">
                      <thead className="thead-dark">
                        <tr>
                          <th>Dia</th>
                          <th>Equipamentos Cadastrados</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>08/01/2021</div>
                          </td>
                          <td>
                            <div>5</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>09/01/2021</div>
                          </td>
                          <td>
                            <div>1</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>10/01/2021</div>
                          </td>
                          <td>
                            <div>8</div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot className="tfoot-dark">
                        <tr>
                          <td>
                            <i className="fas fa-angle-right"></i> Total em
                            Janeiro
                          </td>
                          <td>14</td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fas fa-angle-double-right"></i> Total
                            em 2021
                          </td>
                          <td>150</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
