import React, { useEffect, useState } from "react";
import { TextInput } from "../../../core/components/form/text-input";
import { Button } from "../../../core/components/button";
import { Select } from "../../../core/components/form/select";
import { userType } from "../../user";
import { remove, post } from "../../../service";
import { localStore } from "../../../core/utils/store";
import { config } from "../../../config";

export const Analysis = ({
  form: { entity, errors, touched, submitting, handleChange, hasId },
  setPage,
  route,
  users,
  customer,
  global: { modal, operator, refresh },
}) => {
  useEffect(() => {
    if (operator.type == userType.ANALYST && entity.completed) {
      modal.alert("Você não possui permissão para executar essa ação.");
      route.history.goBack();
    }

    // eslint-disable-next-line
  }, [entity.completed]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <>
      <div className="row">
        {operator.type !== userType.ANALYST && (
          <div className="col-md">
            <Select
              isClearable={true}
              label="Analista"
              placeholder="Busque e selecione um analista"
              getDisplay={(u) => u.name}
              getId={(u) => u.id}
              items={users.filter((u) => u.type === userType.ANALYST)}
              selected={users.find((u) => u.id === entity.analystId)}
              onChange={(u) => {
                handleChange({
                  path: "analystId",
                  values: { analystId: u ? u.id : 0 },
                });
              }}
            />
            <sup>
              <a className="display-8" target="_blank" href="/equipe">
                Você pode convidar um analista para sua equipe aqui
              </a>
            </sup>
          </div>
        )}

        <div className="col-md">
          <TextInput
            label="ART do Projeto"
            value={entity.projectArt}
            meta={{
              error: errors.projectArt,
              touched: touched.projectArt,
            }}
            onChange={(projectArt, type) =>
              handleChange({
                path: "projectArt",
                type,
                values: { projectArt },
              })
            }
          />
        </div>
      </div>

      <div className="buttons text-right">
        <button
          disabled={submitting || deleteLoading}
          className="btn btn-secondary"
          onClick={() => route.history.goBack()}
        >
          {submitting || deleteLoading ? (
            <>
              <i className="fas fa-spinner fa-spin valign-middle" />
              <span>Voltar</span>
            </>
          ) : (
            <>
              <i
                className="fas fa-angle-left valign-middle fa-sm"
                aria-hidden="true"
              />
              <span>Voltar</span>
            </>
          )}
        </button>

        {entity.id !== 0 &&
          (operator.type === userType.ENGINEER ||
            operator.type === userType.ANALYST) &&
          operator.canDeleteAnalisys && (
            <Button
              customClassName="btn-danger"
              disabled={submitting || deleteLoading}
              loading={submitting || deleteLoading}
              icon="fas fa-times valign-middle fa-sm"
              title="Remover Análise"
              onClick={() =>
                modal.confirm(
                  <div>
                    <p className="alert-remove">
                      <h6>Esta ação é irreversível! Será excluído:</h6>
                      <ul className="lista text-left">
                        <li>Todas as fotos cadastradas nesta análise</li>
                        <li>Todos os dados e informações da análise</li>
                        <li>Esta ação é irreversível!</li>
                      </ul>
                      <span>Deseja realmente remover esta análise?</span>
                    </p>
                  </div>,
                  (confirmed) => {
                    if (confirmed) {
                      setDeleteLoading(true);
                      remove("analysis", entity.id)
                        .then(() => {
                          localStore.set(config.OPERATOR_KEY, {
                            ...operator,
                            usedAnalysis: operator.usedAnalysis - 1,
                          });
                          route.history.goBack();
                        })
                        .finally(() => setDeleteLoading(false));
                    }
                  }
                )
              }
            />
          )}

        <button
          disabled={submitting || deleteLoading}
          className="btn btn-secondary"
          onClick={() => setPage({ type: "machine1", id: 0 })}
        >
          {submitting || deleteLoading ? (
            <>
              <span>Avançar</span>
              <i className="fas fa-spinner fa-spin valign-middle" />
            </>
          ) : (
            <>
              <span>Avançar</span>
              <i
                className="fas fa-angle-right valign-middle fa-sm"
                aria-hidden="true"
              />
            </>
          )}
        </button>
      </div>
    </>
  );
};
