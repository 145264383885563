import React, { useEffect, useState } from "react";
import { generateId } from "../utils";
import { Button } from "../../../core/components/button";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { Select } from "../../../core/components/form/select";
import { TextAreaInput } from "../../../core/components/form/textarea-input";
import { addOrUpdate, remove } from "../../../service";

const renderQuestions = (type, title, pap, setPap) => {
  const fields = [
    ["Instalado", `${type}Installed`],
    ["Localizado em zona segura", `${type}SafeArea`],
    ["Passível de acionamento acidental", `${type}Accidental`],
    ["Passível de burla", `${type}AntiFraud`],
    ["Está identificado em língua portuguesa", `${type}Portuguese`],
    ["Acionado em EBT ou por dupla isolação", `${type}Ebt`],
  ];

  return (
    <>
      <div className="row">
        <div className="col-md">
          <div className="module-header">
            <span>{title}</span>
          </div>
        </div>
      </div>

      {fields.map(([name, field], i) => (
        <div key={i} className="row">
          <div className="col-md">
            <h3>{name}</h3>

            <div className="check-group">
              <div className="checkbox">
                <input
                  id={field}
                  type="checkbox"
                  checked={pap[field]}
                  onChange={({ target }) =>
                    setPap((pap) => ({
                      ...pap,
                      [field]: target.checked,
                    }))
                  }
                />
                <label className="noselect" htmlFor={field}>
                  {pap[field] ? "Sim" : "Não"}
                </label>
              </div>

              <div className="checkbox">
                <input
                  id={`${field}Nr12`}
                  type="checkbox"
                  checked={pap[`${field}Nr12`]}
                  onChange={({ target }) =>
                    setPap((pap) => ({
                      ...pap,
                      [`${field}Nr12`]: target.checked,
                    }))
                  }
                />
                <label className="noselect" htmlFor={`${field}Nr12`}>
                  {pap[`${field}Nr12`] ? "Atende NR-12" : "Não Atende NR-12"}
                </label>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export function PapForm({ modal, lists, pap, setPap, removePap, setPage }) {
  const [setedPap, onSetPap] = useState({ modified: false, nav: false });
  const [submitting, setSubmitting] = useState(false);
  const [removedPap, onRemovePap] = useState(false);
  useEffect(() => {
    if (setedPap.modified) {
      setSubmitting(true);
      addOrUpdate("pap", pap)
        .then((resp) => {
          if (resp) {
            pap.id = resp.id;
          }
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onSetPap(false);
          if (setedPap.nav)
            setPage((prev) => ({
              ...prev,
              type: "machine3",
              id: 0,
            }));
        });
    }
  }, [setedPap]);

  useEffect(() => {
    if (removedPap) {
      setSubmitting(true);
      remove("pap", pap.id)
        .then(() => {
          removePap();
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          setSubmitting(false);
          onRemovePap(false);
        });
    }
  }, [removedPap]);

  return (
    <>
      <label>Fotos dos pontos</label>
      <div className="row">
        <div className="col-md">
          <div className="fotos mb-3">
            <PhotoBox
              title="Partida"
              photo={pap.activationPhoto}
              modal={modal}
              onChange={(data) =>
                setPap((prev) => ({
                  ...prev,
                  activationPhoto: data
                    ? {
                        id: generateId(),
                        data,
                      }
                    : null,
                }))
              }
            />

            <PhotoBox
              title="Parada"
              photo={pap.stopPhoto}
              modal={modal}
              onChange={(data) =>
                setPap((prev) => ({
                  ...prev,
                  stopPhoto: data
                    ? {
                        id: generateId(),
                        data,
                      }
                    : null,
                }))
              }
            />

            <PhotoBox
              title="Rearme"
              photo={pap.resetPhoto}
              modal={modal}
              onChange={(data) =>
                setPap((prev) => ({
                  ...prev,
                  resetPhoto: data
                    ? {
                        id: generateId(),
                        data,
                      }
                    : null,
                }))
              }
            />
          </div>
        </div>
      </div>

      {!!pap.activationPhoto &&
        renderQuestions("activation", "Partida", pap, setPap)}
      {!!pap.stopPhoto && renderQuestions("stop", "Parada", pap, setPap)}
      {!!pap.resetPhoto && renderQuestions("reset", "Rearme", pap, setPap)}

      <div className="row">
        <div className="col-md">
          <Select
            isMulti
            label="Parecer Técnico"
            noItemMessage="Nenhum parecer foi encontrado..."
            getId={(t) => t.id}
            getDisplay={(t) => `${t.code} ${t.description}`}
            items={lists.standards.filter((s) => s.titleId === 4)}
            onChange={(items) =>
              setPap((prev) => ({
                ...prev,
                standardsItems: items,
                standards: items.map((i) => i.id),
              }))
            }
            selected={pap.standardsItems}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextAreaInput
            label="Possíveis soluções"
            value={pap.solution}
            rows={5}
            onChange={(solution) =>
              setPap((prev) => ({
                ...prev,
                solution,
              }))
            }
          />
        </div>
      </div>

      <div className="buttons text-right">
        <Button
          customClassName="btn-secondary"
          icon="fas fa-angle-left valign-middle"
          title="Voltar"
          disabled={submitting}
          onClick={() => {
            onSetPap({ modified: true, nav: true });
          }}
        />

        <Button
          disabled={submitting}
          customClassName="btn-danger"
          icon="fas fa-times valign-middle fa-sm"
          title="Remover PAP"
          onClick={() => {
            modal.confirm("Deseja remover esse PAP?", (confirmed) => {
              if (confirmed) {
                onRemovePap(true);
              }
            });
          }}
        />

        <Button
          customClassName="btn-success"
          icon="fas fa-check valign-middle fa-sm"
          title="Salvar PAP"
          loading={submitting}
          disabled={submitting}
          onClick={() => onSetPap({ modified: true, nav: false })}
        />
      </div>
    </>
  );
}
