import React, { useEffect, useState, useMemo } from "react";
import { useForm } from "../../core/hooks/form";
import { Button } from "../../core/components/button";
import { ErrorMessage } from "../../core/components/form/error-message";
import { TextInput } from "../../core/components/form/text-input";
import {
  CepMask,
  CpfMask,
  PhoneMask,
  requiredMessage,
  CellPhoneMask,
} from "../../core/utils/misc";
import { parseNumber } from "../../core/utils/parse";
import {
  getById,
  addOrUpdate,
  getList,
  post,
  get,
  remove,
  getExternal,
  logOut,
} from "../../service";
import { userType } from "./utils";
import { isCNPJ, isCPF, isEmail } from "../../core/utils/validate";
import { Select } from "../../core/components/form/select";

import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { config } from "localforage";
import { localStore } from "../../core/utils/store";
import { PhotoBox } from "../../core/components/PhotoBox/PhotoBox";
import { toastType } from "../../core/helpers/misc";

function onSubmit(
  user,
  form,
  data,
  route,
  isSelfEdit,
  isClientFilter,
  { modal, refresh, operator }
) {
  if (isClientFilter && !data) {
    form.setErrors({
      _error: "Foto do cliente não encontrada. Configure uma foto!",
    });
  } else {
    if (
      operator.type === userType.ENGINEER &&
      user.type !== userType.CUSTOMER &&
      user.email
    ) {
      get(`User.findByMail?email=${user.email}`)
        .then((done) => {
          if (
            done &&
            [userType.MANAGER, userType.ANALYST].includes(user.type)
          ) {
            post("User.UpdateAnalistCliet", user)
              .then(() =>
                modal.alert("Membro atualizado!", () =>
                  route.history.push("/equipe")
                )
              )
              .catch((err) => modal.alert(err.message))
              .finally(() => {
                form.setSubmitting(false);
              });
          } else {
            addOrUpdateUser();
          }
        })
        .catch((err) => {
          modal.alert(err.message);
        })
        .finally(() => {
          form.setSubmitting(false);
        });
    } else {
      addOrUpdateUser();
    }
  }

  function addOrUpdateUser() {
    let responseAdded = {};
    let title = "Usuário";

    switch (user.type) {
      case userType.CUSTOMER:
        title = "Cliente";
      case userType.MANAGER:
        title = "Gestor";
      case userType.ENGINEER:
        title = "Engenheiro";
      case userType.GUESTENGINEER:
        title = "Engenheiro";
      case userType.ANALYST:
        title = "Analista";
      case userType.ADMIN:
        title = "Administrador";
      default:
        title = "Usuário";
    }

    const toastMessage = {
      text: `${user.name} salvo!`,
      type: toastType.INFO,
    };

    addOrUpdate("user", user, toastMessage)
      .then((resp) => {
        responseAdded = resp;
        if (!data) return;
        const id = resp ? resp.id : user.id;
        return post("user.photo", { id, data });
      })
      .then(() => {
        form.setSubmitting(false);

        if (!isSelfEdit && !form.hasId && user.type !== userType.CUSTOMER) {
          modal.generic(
            "Convite enviado!",
            <div className="row">
              <div className="col-md custom-input-field">
                <TextInput
                  customClassName="custom link-convite"
                  label="Copie o link do convite abaixo:"
                  value={responseAdded.link}
                  disabled={true}
                />
              </div>

              <OverlayTrigger
                trigger="click"
                key="top"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>Link do convite copiado!</Tooltip>
                }
              >
                <div id="copy-link" className="col-md-2">
                  <Button
                    customClassName="btn-primary btn-xs mb-0"
                    icon="fas fa-clipboard"
                    onClick={() => {
                      navigator.clipboard.writeText(responseAdded.link);
                    }}
                  />
                </div>
              </OverlayTrigger>
            </div>,

            true,
            () => {
              route.history.push("/equipe");
            }
          );
        } else {
          modal.alert(
            isSelfEdit
              ? "Perfil atualizado com sucesso. Faça login novamente para aplicar as alterações!"
              : form.hasId
              ? isClientFilter
                ? "Cliente atualizado!"
                : "Membro atualizado!"
              : "Cadastro feito!",
            () => {
              if (isSelfEdit) {
                logOut();
              } else {
                route.history.goBack();
              }
            }
          );
        }
      })
      .catch((err) => form.setErrors({ _error: err.message }));
  }
}

export const UserForm = ({ global, route }) => {
  const [customers, setCustomers] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [info, setInfo] = useState({
    completedAnalysisCount: 0,
    currentAnalysisBalance: 0,
    firstAnalisy: "",
    maxAnalysis: 0,
    riskCount: 0,
    stetesAnalisys: [],
    teamSize: 0,
    totalAnalysis: 0,
    totalClients: 0,
    totalStates: 0,
    usedAnalysis: 0,
    isIlimitedUser: false,
  });

  const isSelfEdit = ["/meu-perfil", "/meu-perfil/"].includes(
    route.location.pathname
  );
  const [isClientFilter] = useState(
    route.match.path === "/cliente/cadastro/:id" ||
      route.match.path === "/cliente/cadastro"
  );

  const userTypes = useMemo(
    () =>
      [userType.ENGINEER, userType.GUESTENGINEER].includes(global.operator.type)
        ? isClientFilter
          ? [{ id: userType.CUSTOMER, name: "Cliente" }]
          : [
              { id: userType.GUESTENGINEER, name: "Engenheiro" },
              { id: userType.ANALYST, name: "Analista" },
              { id: userType.MANAGER, name: "Gestor" },
            ]
        : [
            { id: userType.ENGINEER, name: "Engenheiro" },
            { id: userType.ADMIN, name: "Admin" },
          ],
    [global.operator.type]
  );

  const form = useForm(
    {
      initialEntity: {
        id: 0,
        city: "",
        complement: "",
        cpfCnpj: "",
        district: "",
        email: "",
        name: "",
        customerCode: "",
        businessName: "",
        cellphone: "",
        postalCode: "",
        state: "",
        streetName: "",
        streetNumber: "",
        telephone: "",
        proRegisterNumber: "",
        proRegisterType: "",
        role: "",
        responsible: "",
        currentPassword: "",
        password: "",
        password_checker: "",
        customers: [],
        useDocx: false,
        isTrialUser: false,
        avaliableTrialAnalisys: 0,
        avaliableTrialDays: 0,
        canDeleteAnalisys: false,
        type: userTypes[0].id,
        selectedType: userTypes[0],
      },
      validate: (values, errors) => {
        if (
          values.cpfCnp &&
          !isCNPJ(values.cpfCnpj) &&
          !isCPF(values.cpfCnpj)
        ) {
          errors.cpfCnpj = "Preencha o campo com um CNPJ/CPF válido.";
        }

        if (!values.name) {
          errors.name = requiredMessage;
        }

        if (!values.cellphone) {
          errors.cellphone = requiredMessage;
        }

        if (values.type !== userType.CUSTOMER && !values.email) {
          errors.email = requiredMessage;
          if (!isEmail(values.email)) {
            errors.email = "Preencha o campo com um e-mail válido.";
          }
        }

        if (isClientFilter) {
          if (!values.postalCode) {
            errors.postalCode = requiredMessage;
          }

          if (!values.state) {
            errors.state = requiredMessage;
          }

          if (!values.streetName) {
            errors.streetName = requiredMessage;
          }

          if (!values.streetNumber) {
            errors.streetNumber = requiredMessage;
          }

          if (!values.city) {
            errors.city = requiredMessage;
          }

          if (!values.district) {
            errors.district = requiredMessage;
          }

          if (!values.role) {
            errors.role = requiredMessage;
          }
          if (!values.responsible) {
            errors.responsible = requiredMessage;
          }

          if (values.type === userType.CUSTOMER) {
            if (!values.businessName) {
              errors.businessName = requiredMessage;
            }

            if (!values.customerCode) {
              errors.customerCode = requiredMessage;
            }
          }
        } else {
          if (
            [userType.ENGINEER, userType.GUESTENGINEER].includes(values.type)
          ) {
            if (!values.role) {
              errors.role = requiredMessage;
            }
            if (!values.proRegisterType) {
              errors.proRegisterType = requiredMessage;
            }
            if (!values.proRegisterNumber) {
              errors.proRegisterNumber = requiredMessage;
            }
          }
        }

        if (isSelfEdit) {
          if (
            (values.password || values.password_checker) &&
            values.password !== values.password_checker
          ) {
            errors.password_checker = "Senhas não coincidem";
            errors.password = "Senhas não coincidem";
          }
        }

        if (isSelfEdit) {
          if (
            (values.password || values.password_checker) &&
            !values.currentPassword
          ) {
            errors.currentPassword = "Senha atual não informada";
          }
        }
        return errors;
      },
    },
    route
  );

  useEffect(() => {
    post("user.login-info", global.operator).then((user) => {
      localStore.set(config.OPERATOR_KEY, {
        ...user,
        apiVersion: global.operator.version,
      });
      const newOperator = localStore.get(config.OPERATOR_KEY) || {};
      global.operator = newOperator;
    });
    // eslint-disable-next-line
  }, [global.loading]);

  useEffect(() => {
    if (global.operator.type === userType.ENGINEER) {
      get("dashboard.engineer")
        .then((data) => {
          setInfo(data);
        })
        .catch((err) => global.modal.alert(err.message));
    }
  }, [global.loading]);

  useEffect(() => {
    form.setValues({
      type: userTypes[0].id,
      selectedType: userTypes[0],
    });
    // eslint-disable-next-line
  }, [userTypes]);

  useEffect(() => {
    if (!global.loading) {
      if (form.hasId || isSelfEdit) {
        form.handleFetch({
          action: async (id, ac) => {
            const key = id || global.operator.id;

            const user = await getById("user", parseNumber(key), ac.signal);
            user.password = "";
            user.password_checker = "";
            if (user && user.photoId > 0) {
              const photo = await get(`user.photo/${key}`);
              if (photo) setPhoto(photo);
            }

            return user;
          },
          errorFn: (err) =>
            global.modal.alert(err.message, () => route.history.goBack()),
        });
      } else {
        if (
          [userType.ENGINEER].includes(global.operator.type) &&
          global.operator.isTrial
        ) {
          let hasLimit = isClientFilter
            ? global.operator.hasTrialClient
            : global.operator.hasTrialMember;
          if (!hasLimit) {
            global.modal.alert(
              "Ação não permitida em modo teste. Desbloqueie mais recursos!",
              () => route.history.goBack()
            );
          }
        }
      }

      getList("user", {
        usePager: false,
        filters: [{ items: [{ name: "type", value: userType.CUSTOMER }] }],
      })
        .then(({ items }) => setCustomers(items))
        .catch((err) => global.modal.alert(err.message));
    }
    // eslint-disable-next-line
  }, [global.loading]);

  const { entity, errors, touched } = form;

  return (
    <div>
      <form
        autoComplete="off"
        className="box is-clearfix"
        onSubmit={(ev) => {
          ev.preventDefault();
          form.handleSubmit((u) =>
            onSubmit(
              u,
              form,
              photo ? photo.data : null,
              route,
              isSelfEdit,
              isClientFilter,
              global
            )
          );
        }}
      >
        {form.fetching ? (
          <SkeletonTheme color="#2E2F42" highlightColor="#404258">
            <div className="row">
              <div
                className={
                  global.operator.type === userType.ENGINEER && isSelfEdit
                    ? "col-md-3"
                    : "display-none"
                }
              >
                <Skeleton height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={6} />
                </div>
              </div>

              <div
                className={
                  global.operator.type === userType.ENGINEER && isSelfEdit
                    ? "col-md-9"
                    : "col-md"
                }
              >
                <Skeleton width={200} height={30} count={1} />
                <div className="mt-2">
                  <Skeleton height={55} count={6} />
                </div>
              </div>
            </div>
          </SkeletonTheme>
        ) : (
          <div className="row">
            <div
              className={
                global.operator.type === userType.ENGINEER && isSelfEdit
                  ? "col-md-3"
                  : "display-none"
              }
            >
              <div className="card my-profile">
                <div className="card-body p-2">
                  <ul className="nav flex-column">
                    <p className="menu-label pl-1">Conta</p>
                    <li className="nav-item mb-1">
                      <a className="nav-link active" href="/meu-perfil">
                        <i className="fas fa-user-circle fa-lg fa-fw mr-3"></i>{" "}
                        Meus dados
                      </a>
                    </li>
                    {!global.operator.isTrial && (
                      <li className="nav-item mb-1">
                        <a className="nav-link" href="/meu-perfil/personalizar">
                          <i className="fas fa-palette fa-lg fa-fw mr-3"></i>{" "}
                          Personalizar relatórios
                        </a>
                      </li>
                    )}
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/notificacoes"
                      >
                        <i className="fas fa-bell-slash fa-lg fa-fw mr-3"></i>{" "}
                        Notificações e e-mails
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/historico"
                      >
                        <i className="fas fa-history fa-lg fa-fw mr-3"></i>{" "}
                        Histórico de consumo
                      </a>
                    </li>
                    <p className="menu-label pl-1">Faturamento</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento"
                      >
                        <i className="fas fa-credit-card fa-lg fa-fw mr-3"></i>{" "}
                        Meu plano
                      </a>
                    </li>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/faturamento#planos"
                      >
                        <i className="fas fa-money-check-alt fa-lg fa-fw mr-3"></i>{" "}
                        Planos
                      </a>
                    </li>
                    <p className="menu-label pl-1">Outros</p>
                    <li className="nav-item mb-1">
                      <a
                        className="nav-link disabled"
                        href="/meu-perfil/afiliados"
                      >
                        <i className="fas fa-hands-helping fa-lg fa-fw mr-3"></i>{" "}
                        Programa de Afiliados
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="customer-analytics mt-2">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="float-right">
                      <p className="display-inline font-medium-2">
                        {global.operator.maxAnalysis ? (
                          <div>
                            {global.operator.isTrial ? (
                              <b>
                                {info.usedAnalysis}
                                <sub> / {info.maxAnalysis} grátis</sub>
                              </b>
                            ) : (
                              <b>
                                {info.usedAnalysis}{" "}
                                <sub> de {info.maxAnalysis} usados</sub>
                              </b>
                            )}
                          </div>
                        ) : (
                          <b>
                            <i className="fas fa-infinity" /> Ilimitado
                          </b>
                        )}
                      </p>
                    </div>
                    <p>Seus créditos disponíveis:</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                global.operator.type === userType.ENGINEER && isSelfEdit
                  ? "col-md-9"
                  : "col-md"
              }
            >
              <div className="ptm-3 pb-2 display-inline-table font-medium-5">
                <h5>
                  {form.hasId ? (
                    isClientFilter ? (
                      <div>
                        <i className="fas fa-user-edit"></i> Editar cliente
                      </div>
                    ) : (
                      <div>
                        <i className="fas fa-user-edit"></i> Editar membro
                      </div>
                    )
                  ) : isSelfEdit ? (
                    <div>Editar meus dados</div>
                  ) : isClientFilter ? (
                    <div>
                      <i className="fas fa-user-plus"></i> Cadastrar cliente
                    </div>
                  ) : (
                    <div>
                      <i className=""></i> Convidar membro
                    </div>
                  )}
                </h5>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="mb-1">
                    <h5 className="card-title">
                      <div>
                        <i className="fas fa-user"></i> Informações básicas
                      </div>
                    </h5>
                    <p style={{ fontWeight: 200 }}>
                      Dados pessoais, informações de acesso e assinatura
                      profissional.
                    </p>
                  </div>

                  {entity.type === userType.CUSTOMER && (
                    <div className="row">
                      <div className="col-md">
                        <div className="fotos left">
                          <PhotoBox
                            noRemove
                            title="Foto do cliente"
                            photo={photo}
                            modal={global.modal}
                            onChange={(data) => setPhoto({ data })}
                          />
                        </div>
                        <sup>Apenas .JPG, .JPEG e .GIF.</sup>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    {!(isSelfEdit || form.hasId) && (
                      <div className="col-md-2">
                        <Select
                          label="Cargo do usuário"
                          getDisplay={(i) => i.name}
                          getId={(i) => i.id}
                          items={userTypes}
                          selected={entity.selectedType || undefined}
                          onChange={(type) =>
                            form.handleChange({
                              path: "type",
                              values: {
                                type: type.id,
                                selectedType: type,
                              },
                            })
                          }
                        />
                      </div>
                    )}

                    <div className="col-md">
                      <TextInput
                        label="Nome"
                        value={entity.name}
                        meta={{
                          error: errors.name,
                          touched: touched.name,
                        }}
                        onChange={(name, type) => {
                          form.handleChange({
                            path: "name",
                            type,
                            values: { name },
                          });
                          if (!form.hasId && !isSelfEdit) {
                            form.handleChange({
                              path: "customerCode",
                              type,
                              values: {
                                customerCode: name
                                  .replace(/[^A-Za-z]/g, "")
                                  .toUpperCase()
                                  .substring(0, 10),
                              },
                            });
                          }
                        }}
                      />
                    </div>

                    {entity.type === userType.CUSTOMER && (
                      <div className="col-md">
                        <TextInput
                          label="Razão Social"
                          value={entity.businessName}
                          meta={{
                            error: errors.businessName,
                            touched: touched.businessName,
                          }}
                          onChange={(businessName, type) =>
                            form.handleChange({
                              path: "businessName",
                              type,
                              values: { businessName },
                            })
                          }
                        />
                      </div>
                    )}

                    {entity.type === userType.CUSTOMER && (
                      <div className="col-md-2">
                        <TextInput
                          label="ID do Cliente"
                          maxLength={10}
                          disabled={form.hasId || isSelfEdit}
                          value={entity.customerCode}
                          meta={{
                            error: errors.customerCode,
                            touched: touched.customerCode,
                          }}
                          onChange={(value, type) =>
                            form.handleChange({
                              path: "customerCode",
                              type,
                              values: {
                                customerCode: value
                                  .replace(/[^A-Za-z]/g, "")
                                  .toUpperCase()
                                  .substring(0, 10),
                              },
                            })
                          }
                        />
                      </div>
                    )}

                    <div className="col-md">
                      <TextInput
                        label={
                          entity.type !== userType.CUSTOMER ? "CPF" : "CPF/CNPJ"
                        }
                        mask={
                          entity.type !== userType.CUSTOMER
                            ? CpfMask
                            : undefined
                        }
                        value={entity.cpfCnpj}
                        meta={{
                          error: errors.cpfCnpj,
                          touched: touched.cpfCnpj,
                        }}
                        onChange={(cpfCnpj, type) =>
                          form.handleChange({
                            path: "cpfCnpj",
                            type,
                            values: { cpfCnpj },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    {entity.type !== userType.CUSTOMER && (
                      <div className="col-md">
                        <TextInput
                          disabled={
                            isSelfEdit || (!isClientFilter && form.hasId)
                          }
                          label="E-mail"
                          value={entity.email}
                          meta={{
                            error: errors.email,
                            touched: touched.email,
                          }}
                          onChange={(email, type) =>
                            form.handleChange({
                              path: "email",
                              type,
                              values: { email },
                            })
                          }
                        />
                      </div>
                    )}
                    <div className="col-md">
                      <TextInput
                        label="Celular"
                        mask={CellPhoneMask}
                        value={entity.cellphone}
                        meta={{
                          error: errors.cellphone,
                          touched: touched.cellphone,
                        }}
                        onChange={(cellphone, type) =>
                          form.handleChange({
                            path: "cellphone",
                            type,
                            values: { cellphone },
                          })
                        }
                      />
                    </div>
                  </div>

                  {isSelfEdit && (
                    <div className="mt-4">
                      <h5 className="card-title">
                        <div>
                          <i className="fas fa-lock"></i> Editar senha
                        </div>
                      </h5>
                    </div>
                  )}

                  {isSelfEdit && (
                    <div className="row">
                      <div className="col-md">
                        <TextInput
                          autoComplete="off"
                          label="Senha atual"
                          type="password"
                          meta={{
                            error: errors.currentPassword,
                            touched: touched.currentPassword,
                          }}
                          onChange={(currentPassword, type) =>
                            form.handleChange({
                              path: "currentPassword",
                              type,
                              values: { currentPassword },
                            })
                          }
                        />
                      </div>

                      <div className="col-md">
                        <TextInput
                          autoComplete="off"
                          label="Nova senha"
                          type="password"
                          meta={{
                            error: errors.password,
                            touched: touched.password,
                          }}
                          onChange={(password, type) =>
                            form.handleChange({
                              path: "password",
                              type,
                              values: { password },
                            })
                          }
                        />
                      </div>

                      <div className="col-md">
                        <TextInput
                          autoComplete="off"
                          label="Repetir nova senha"
                          type="password"
                          meta={{
                            error: errors.password_checker,
                            touched: touched.password_checker,
                          }}
                          onChange={(password_checker, type) =>
                            form.handleChange({
                              path: "password_checker",
                              type,
                              values: { password_checker },
                            })
                          }
                        />
                      </div>
                    </div>
                  )}

                  {entity.type === userType.CUSTOMER && (
                    <div className="mt-3">
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Endereço
                      </p>
                    </div>
                  )}
                  {entity.type === userType.CUSTOMER && (
                    <div className="row">
                      <div className="col-md-1">
                        <TextInput
                          label="CEP"
                          mask={CepMask}
                          value={entity.postalCode}
                          meta={{
                            error: errors.postalCode,
                            touched: touched.postalCode,
                          }}
                          onChange={(postalCode, type) =>
                            form.handleChange({
                              path: "postalCode",
                              type,
                              values: { postalCode },
                            })
                          }
                        />
                      </div>
                      <div className="col-md-1 no-padding no-gutter">
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Preencher endereço
                            </Tooltip>
                          }
                        >
                          <Button
                            title="Completar CEP"
                            id="cep"
                            icon="fas fa-plus"
                            customClassName="btn-primary btn-cep mt-4"
                            onClick={(env, type) => {
                              const cep = entity.postalCode;

                              getExternal(
                                `https://viacep.com.br/ws/${cep}/json/`
                              ).then((done) => {
                                form.handleChange({
                                  path: "streetName",
                                  type,
                                  values: { streetName: done.logradouro },
                                });
                                form.handleChange({
                                  path: "district",
                                  type,
                                  values: { district: done.bairro },
                                });
                                form.handleChange({
                                  path: "city",
                                  type,
                                  values: { city: done.localidade },
                                });
                                form.handleChange({
                                  path: "state",
                                  type,
                                  values: { state: done.uf },
                                });
                              });
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                      <div className="col-md">
                        <TextInput
                          label="Endereço"
                          value={entity.streetName}
                          meta={{
                            error: errors.streetName,
                            touched: touched.streetName,
                          }}
                          onChange={(streetName, type) =>
                            form.handleChange({
                              path: "streetName",
                              type,
                              values: { streetName },
                            })
                          }
                        />
                      </div>

                      <div className="col-md-1">
                        <TextInput
                          label="Número"
                          value={entity.streetNumber}
                          meta={{
                            error: errors.streetNumber,
                            touched: touched.streetNumber,
                          }}
                          onChange={(streetNumber, type) =>
                            form.handleChange({
                              path: "streetNumber",
                              type,
                              values: { streetNumber },
                            })
                          }
                        />
                      </div>

                      <div className="col-md">
                        <TextInput
                          label="Bairro"
                          value={entity.district}
                          meta={{
                            error: errors.district,
                            touched: touched.district,
                          }}
                          onChange={(district, type) =>
                            form.handleChange({
                              path: "district",
                              type,
                              values: { district },
                            })
                          }
                        />
                      </div>

                      <div className="col-md">
                        <TextInput
                          label="Cidade"
                          value={entity.city}
                          meta={{
                            error: errors.city,
                            touched: touched.city,
                          }}
                          onChange={(city, type) =>
                            form.handleChange({
                              path: "city",
                              type,
                              values: { city },
                            })
                          }
                        />
                      </div>

                      <div className="col-md-1">
                        <TextInput
                          label="UF"
                          maxLength={2}
                          value={entity.state}
                          meta={{
                            error: errors.state,
                            touched: touched.state,
                          }}
                          onChange={(state, type) =>
                            form.handleChange({
                              path: "state",
                              type,
                              values: { state },
                            })
                          }
                        />
                      </div>
                    </div>
                  )}

                  {entity.type === userType.ENGINEER &&
                    global.operator.type === userType.ADMIN && (
                      <div className="row mt-3">
                        <div className="container-fluid">
                          <h5 className="card-title">
                            <i className="fas fa-plus-circle  "></i> Recursos
                            adicionais do usuário
                          </h5>
                        </div>
                        <div className="col-md">
                          <p>
                            <i
                              style={{ color: `#dd5959` }}
                              className="fas fa-times"
                            ></i>{" "}
                            Habilitar recurso de exclusão de análise
                          </p>
                          <input
                            type="checkbox"
                            className="switch valign-middle mt-1"
                            checked={entity.canDeleteAnalisys}
                            onChange={({ target }) =>
                              form.setValues({
                                canDeleteAnalisys: target.checked,
                              })
                            }
                          />
                        </div>
                        <div className="col-md">
                          <p>
                            <i
                              style={{ color: `#f4b96b` }}
                              className="fas fa-clock"
                            ></i>{" "}
                            Engenheiro em Periodo de Trial
                          </p>
                          <input
                            type="checkbox"
                            className="switch valign-middle mt-1 mb-3"
                            checked={entity.isTrialUser}
                            onChange={({ target }) =>
                              form.setValues({
                                isTrialUser: target.checked,
                              })
                            }
                          />
                        </div>
                        <div className="col-md">
                          <TextInput
                            label="Dias em modo teste"
                            value={entity.avaliableTrialDays}
                            meta={{
                              error: errors.avaliableTrialDays,
                              touched: touched.avaliableTrialDays,
                            }}
                            onChange={(avaliableTrialDays, type) =>
                              form.handleChange({
                                path: "avaliableTrialDays",
                                type,
                                values: { avaliableTrialDays },
                              })
                            }
                          />
                        </div>
                        <div className="col-md">
                          <TextInput
                            label="Equipamentos em modo teste"
                            value={entity.avaliableTrialAnalisys}
                            meta={{
                              error: errors.avaliableTrialAnalisys,
                              touched: touched.avaliableTrialAnalisys,
                            }}
                            onChange={(avaliableTrialAnalisys, type) =>
                              form.handleChange({
                                path: "avaliableTrialAnalisys",
                                type,
                                values: { avaliableTrialAnalisys },
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {[
                userType.MANAGER,
                userType.ANALYST,
                userType.GUESTENGINEER,
              ].includes(entity.type) &&
                global.operator.type === userType.ENGINEER && (
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <div>
                          <i className="fas fa-user-tag"></i> Permissões para
                          acesso
                        </div>
                      </h5>
                      <p style={{ float: `left`, fontWeight: 200 }}>
                        Selecione os clientes que o usuário convidado terá
                        acesso.
                      </p>
                    </div>

                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col-md">
                          {customers.map((c) => (
                            <div
                              key={c.id}
                              className="check-group check-group-permissions"
                            >
                              <div className="checkbox">
                                <input
                                  id={`customer_${c.id}`}
                                  type="checkbox"
                                  checked={entity.customers.includes(c.id)}
                                  onChange={({ target }) =>
                                    form.setValues((prev) => ({
                                      customers: target.checked
                                        ? [...prev.customers, c.id]
                                        : prev.customers.filter(
                                            (id) => id !== c.id
                                          ),
                                    }))
                                  }
                                />
                                <label
                                  className="noselect"
                                  htmlFor={`customer_${c.id}`}
                                >
                                  {c.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <ErrorMessage error={errors._error} />
                    </div>
                  </div>
                )}
              {[
                userType.CUSTOMER,
                userType.ENGINEER,
                userType.GUESTENGINEER,
              ].includes(entity.type) && (
                <div className="card" id="assinatura">
                  <div className="card-header pb-0">
                    <h5 className="card-title">
                      {entity.type === userType.CUSTOMER ? (
                        <div>
                          <i className="fas fa-signature"></i> Configure o
                          registro do responsável e assinatura
                        </div>
                      ) : (
                        <div>
                          <i className="fas fa-signature"></i> Configure seu
                          registro profissional e assinatura
                        </div>
                      )}
                    </h5>
                  </div>

                  <div className="card-body pt-0">
                    <div className="row">
                      {entity.type === userType.CUSTOMER && (
                        <div className="col-md">
                          <TextInput
                            label="Responsável na empresa"
                            placeholder="Insira o nome do responsável desta empresa"
                            value={entity.responsible}
                            meta={{
                              error: errors.responsible,
                              touched: touched.responsible,
                            }}
                            onChange={(responsible, type) =>
                              form.handleChange({
                                path: "responsible",
                                type,
                                values: { responsible },
                              })
                            }
                          />
                        </div>
                      )}

                      <div className="col-md">
                        <TextInput
                          label="Cargo exercido"
                          placeholder={
                            entity.type === userType.ENGINEER
                              ? "Insira o seu cargo exercido. Ex: Engenheiro de Segurança do Trabalho"
                              : "Insira o cargo exercido pelo responsável da empresa"
                          }
                          value={entity.role}
                          meta={{
                            error: errors.role,
                            touched: touched.role,
                          }}
                          onChange={(role, type) =>
                            form.handleChange({
                              path: "role",
                              type,
                              values: { role },
                            })
                          }
                        />
                      </div>
                    </div>

                    {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                      entity.type
                    ) && (
                      <div className="row">
                        <div className="col-md">
                          <TextInput
                            label="Registro Profissional"
                            placeholder="Insira o emissor do seu registro profissional. Ex: CREA-SC"
                            value={entity.proRegisterType}
                            meta={{
                              error: errors.proRegisterType,
                              touched: touched.proRegisterType,
                            }}
                            onChange={(proRegisterType, type) =>
                              form.handleChange({
                                path: "proRegisterType",
                                type,
                                values: { proRegisterType },
                              })
                            }
                          />
                        </div>

                        <div className="col-md">
                          <TextInput
                            label="Número do Registro"
                            placeholder="Insira o número do seu registro"
                            value={entity.proRegisterNumber}
                            meta={{
                              error: errors.proRegisterNumber,
                              touched: touched.proRegisterNumber,
                            }}
                            onChange={(proRegisterNumber, type) =>
                              form.handleChange({
                                path: "proRegisterNumber",
                                type,
                                values: { proRegisterNumber },
                              })
                            }
                          />
                        </div>
                      </div>
                    )}

                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Esta é uma prévia da assinatura inserida ao final dos
                          relatórios e documentos gerados!
                        </Tooltip>
                      }
                    >
                      <div className="msg-example" id="signature">
                        {[userType.ENGINEER, userType.GUESTENGINEER].includes(
                          entity.type
                        ) ? (
                          <>
                            <span>{entity.name}</span>
                            <span>{entity.role}</span>
                            <span>
                              {entity.proRegisterType}{" "}
                              {entity.proRegisterNumber}
                            </span>
                          </>
                        ) : (
                          <>
                            <span>{entity.name}</span>
                            <span>Responsável: {entity.responsible}</span>
                            <span>Cargo: {entity.role}</span>
                          </>
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-md">
                      <ErrorMessage error={errors._error} />
                    </div>
                    <div className="col-md-auto m-0">
                      <Button
                        customClassName="btn-secondary"
                        icon="fas fa-angle-left valign-middle"
                        title="Voltar"
                        onClick={() => route.history.goBack()}
                      />
                    </div>
                    <div>
                      {form.hasId &&
                        !global.operator.isTrial &&
                        [userType.ENGINEER].includes(global.operator.type) && (
                          <div className="col-md-auto m-0">
                            <Button
                              customClassName="btn-danger"
                              icon="fas fa-times valign-middle fa-sm"
                              title="Excluir Cliente"
                              loading={form.submitting}
                              disabled={form.submitting}
                              onClick={() =>
                                global.modal.confirm(
                                  <>
                                    {entity.type === userType.CUSTOMER && (
                                      <p className="alert-remove">
                                        <h6>Esta ação é irreversível!</h6>
                                        <ul className="lista text-left">
                                          <li>
                                            <b>Todas as análises</b> vinculadas
                                            a este cliente serão{" "}
                                            <b>excluídas!</b>
                                          </li>
                                          <li>
                                            <b>Todos os membros</b> também serão{" "}
                                            <b>desvinculados!</b>
                                          </li>
                                        </ul>
                                        <span>
                                          Deseja realmente excluir o cliente{" "}
                                          <b>{entity.name}</b>?
                                        </span>
                                      </p>
                                    )}
                                  </>,
                                  (confirmed) => {
                                    if (confirmed) {
                                      remove("user", entity.id)
                                        .then(
                                          global.modal.alert(
                                            isClientFilter
                                              ? "Cliente removido com sucesso."
                                              : "Membro removido com sucesso.",

                                            () => {
                                              global.refresh();
                                              route.history.goBack();
                                            }
                                          )
                                        )
                                        .catch((err) =>
                                          global.modal.alert(err.message)
                                        );
                                    }
                                  }
                                )
                              }
                            />
                          </div>
                        )}
                    </div>
                    <div className="col-md-auto m-0">
                      <div className="text-right">
                        <Button
                          customClassName="btn-success ml-1"
                          icon="fas fa-check valign-middle fa-sm"
                          title={form.hasId ? "Salvar Alterações" : "Salvar"}
                          loading={form.submitting}
                          disabled={form.submitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
