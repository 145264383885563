export const userType = {
  ADMIN: 1,
  ENGINEER: 2,
  ANALYST: 3,
  CUSTOMER: 4,
  MANAGER: 5,
  GUESTENGINEER: 6,
};

export const userFields = ["id", "cpfCnpj", "name", "email", "city", "type"];
