import React from 'react'
import { classNames } from '../../helpers/misc'
import { Link } from '../route'

function handleClick(ev, onClick) {
    if (!!onClick && typeof onClick !== 'string') {
        ev.preventDefault()
        onClick(ev.target)
    }
}

function renderContent(title, icon, loading) {
    return loading ? (
        <i className="fas fa-spinner fa-spin valign-middle" />
    ) : (
        <>
            {!!icon && <i className={icon} aria-hidden="true" />}
            {!!title && <span>{title}</span>}
        </>
    )
}

export const Button = props => {
    const {
        loading = false,
        onClick,
        customClassName,
        icon,
        title,
        className = 'btn',
        ...buttonProps
    } = props

    const buttonClassName = classNames(className, customClassName, { loading })

    return typeof onClick === 'string' ? (
        <Link className={buttonClassName} to={onClick}>
            {renderContent(title, icon, loading)}
        </Link>
    ) : (
        <button
            {...buttonProps}
            className={buttonClassName}
            onClick={ev => handleClick(ev, onClick)}
        >
            {renderContent(title, icon, loading)}
        </button>
    )
}
