import React, { useEffect } from 'react'
import { useForm } from '../../core/hooks/form'
import { requiredMessage } from '../../core/utils/misc'
import { getById, addOrUpdate } from '../../service'
import { parseNumber } from '../../core/utils/parse'
import { TextInput } from '../../core/components/form/text-input'
import { Button } from '../../core/components/button'
import { ErrorMessage } from '../../core/components/form/error-message'

function onSubmit(user, form, route, modal) {
    addOrUpdate('standardtitle', user)
        .then(() => {
            form.setSubmitting(false)

            return modal.alert(
                `Titulo ${form.hasId ? 'atualizado' : 'cadastrado'} com sucesso.`,
                () => route.history.push('/titulo')
            )
        })
        .catch(err => form.setErrors({ _error: err.message }))
}

export const StandardTitleForm = ({ global, route }) => {
    const form = useForm(
        {
            initialEntity: {
                id: 0,
                name: ''
            },
            validate: (values, errors) => {
                if (!values.name) {
                    errors.name = requiredMessage
                }

                return errors
            }
        },
        route
    )

    const { entity, errors, touched } = form

    useEffect(() => {
        if (form.hasId) {
            form.handleFetch({
                action: (id, ac) => getById('standardtitle', parseNumber(id), ac.signal),
                errorFn: err => global.modal.alert(err.message, () => route.history.goBack())
            })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <form
                className="box is-clearfix"
                onSubmit={ev => {
                    ev.preventDefault()
                    form.handleSubmit(ent => onSubmit(ent, form, route, global.modal))
                }}
            >
                {form.fetching ? (
                    'Carregando...'
                ) : (
                    <div className="row">
                        <div className="col-md">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        {form.hasId ? 'Editar Titulo' : 'Cadastrar Titulo'}
                                    </h5>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md">
                                            <TextInput
                                                label="Nome"
                                                value={entity.name}
                                                meta={{
                                                    error: errors.name,
                                                    touched: touched.name
                                                }}
                                                onChange={(name, type) =>
                                                    form.handleChange({
                                                        path: 'name',
                                                        type,
                                                        values: { name }
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="text-right">
                                        <Button
                                            customClassName="btn-success"
                                            icon="fas fa-check valign-middle fa-sm"
                                            title="Salvar"
                                            loading={form.submitting}
                                            disabled={form.submitting}
                                        />
                                    </div>

                                    <ErrorMessage error={errors._error} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
}
