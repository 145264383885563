import React, { useState, useEffect } from "react";
import { Link } from "../../core/components/route";
import {
  maskCpfCnpj,
  toLocaleDate,
  toLocaleDateTime,
} from "../../core/utils/format";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { userType } from "./utils";
import { getList, remove, update, get, post } from "../../service";
import { useRefresh } from "../../core/hooks/refresh";
import SearchNotFound from "../../assets/img/029-search-not-found.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { localStore } from "../../core/utils/store";
import { config } from "localforage";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";

const formatUserType = (type) => {
  switch (type) {
    case userType.CUSTOMER:
      return "Cliente";
    case userType.ANALYST:
      return "Analista";
    case userType.ENGINEER:
      return "Engenheiro";
    case userType.MANAGER:
      return "Gestor";
    case userType.GUESTENGINEER:
      return "Engenheiro";
    case userType.ADMIN:
    default:
      return "Administrador";
  }
};

function refreshPage() {
  window.location.reload(false);
}

const List = ({
  users,
  canEnable,
  canEdit,
  refresh,
  global,
  customBtn,
  isClientFilter,
}) => {
  return users.length === 0 ? (
    <div className="info-no-content">
      <img
        className="image"
        alt="Não há análises cadastradas"
        src={SearchNotFound}
      ></img>{" "}
      <div className="inventory">
        Membro não cadastrado ou não encontrado por este filtro.{" "}
        <Button
          title="Atualizar"
          icon="fas fa-sync"
          customClassName="btn btn-outline-light btn-xs ml-1 mb-0 mt-0"
          onClick={refreshPage}
        />{" "}
      </div>{" "}
    </div>
  ) : (
    <table className="table">
      <tbody>
        {" "}
        {users.map((u) => (
          <tr key={u.id}>
            <th>
              <Avatar
                textSizeRatio={3.1}
                alt="Foto de Perfil"
                maxInitials={2}
                className="avatar rounded-circle width-40 height-40 text-bold-500"
                fgColor="black"
                color="GhostWhite"
                email={u.email}
                name={u.name}
              />
            </th>
            <th>
              <h6 className="list-title"> Cargo </h6>
              <div className="cut-text-mini">
                {" "}
                {formatUserType(u.type)}{" "}
              </div>{" "}
            </th>{" "}
            <th>
              <h6 className="list-title"> Nome </h6>{" "}
              <div className="cut-text-mini"> {u.name} </div>{" "}
            </th>{" "}
            <th>
              <h6 className="list-title"> E-mail </h6>{" "}
              <div className="cut-text-small"> {u.email} </div>{" "}
            </th>{" "}
            {[userType.ENGINEER, userType.ADMIN].includes(
              global.operator.type
            ) && (
              <th className="status">
                <h6 className="list-title"> Status </h6>{" "}
                {u.emailConfirmed ? (
                  <div className="confirmed">
                    {" "}
                    <i className="fas fa-circle fa-xs valign-middle"></i>{" "}
                    Confirmado{" "}
                  </div>
                ) : (
                  <div className="waiting">
                    {" "}
                    <i className="fas fa-circle fa-xs valign-middle"></i>{" "}
                    Aguardando{" "}
                  </div>
                )}{" "}
              </th>
            )}
            {canEnable && [userType.ENGINEER, userType.ADMIN].includes(u.type) && (
              <th>
                <h6 className="list-title"> Habilitar membro </h6>{" "}
                <input
                  type="checkbox"
                  className="switch"
                  checked={u.enabled}
                  onChange={(ev) => {
                    u.enabled = ev.target.checked;
                    update("user", u)
                      .then((resp) => {
                        global.refresh();
                      })
                      .catch((err) => global.modal.alert(err.message));
                  }}
                />{" "}
              </th>
            )}
            {global.operator.type === userType.ADMIN &&
            [userType.ENGINEER, userType.ADMIN].includes(u.type) ? (
              <th>
                <h6 className="list-title">
                  {" "}
                  Habilitar modo teste <i className="fas fa-user-clock"></i>{" "}
                </h6>{" "}
                <input
                  type="checkbox"
                  className="switch"
                  checked={u.isTrialUser}
                  disabled={[userType.ADMIN].includes(u.type)}
                  onChange={(ev) => {
                    u.isTrialUser = ev.target.checked;
                    update("user", u)
                      .then((resp) => {
                        global.refresh();
                      })
                      .catch((err) => global.modal.alert(err.message));
                  }}
                />{" "}
              </th>
            ) : (
              <th></th>
            )}
            {global.operator.type === userType.ADMIN &&
            [userType.ENGINEER, userType.ADMIN].includes(u.type) ? (
              <th>
                <h6 className="list-title"> Data de cadastro </h6>{" "}
                {u.createdAt ? toLocaleDate(u.createdAt) : "-"}
              </th>
            ) : (
              <th></th>
            )}
            {global.operator.type === userType.ADMIN &&
            [userType.ENGINEER].includes(u.type) ? (
              <th>
                <h6 className="list-title"> Data de expiração </h6>{" "}
                {u.expiredAt ? toLocaleDate(u.expiredAt) : "-"}
              </th>
            ) : (
              <th>
                <h6 className="list-title"> Data de expiração </h6>
                <b>
                  <i className="fas fa-infinity" /> Ilimitado
                </b>
              </th>
            )}
            {[
              userType.ANALYST,
              userType.MANAGER,
              userType.GUESTENGINEER,
            ].includes(u.type) && <th></th>}
            <th className="no-wrap no-width">
              <h6 className="list-title"> Ação </h6>
              {isClientFilter && (
                <Button
                  type="button"
                  title="Arquivos do Cliente"
                  icon="fas fa-folder-open"
                  customClassName="btn-light btn-xs mb-0 mt-0"
                  onClick={`/cliente/${u.id}/extras`}
                />
              )}{" "}
              {canEdit && (
                <div className="display-flex column-gap-1">
                  <Button
                    type="button"
                    title="Editar membro"
                    icon="fas fa-edit"
                    customClassName="btn-light btn-xs mb-0 mt-0"
                    onClick={`${customBtn.path}/${u.id}`}
                  />
                  {!u.emailConfirmed &&
                    [userType.ENGINEER].includes(global.operator.type) && (
                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Link do convite copiado!
                          </Tooltip>
                        }
                      >
                        <div id="copy-link">
                          <Button
                            type="button"
                            title="Copiar link do convite"
                            icon="fas fa-copy"
                            customClassName="btn-light btn-xs mb-0 mt-0"
                            onClick={() => {
                              post("User.GenerateNewLinkConfirmation", u)
                                .then((done) => {
                                  navigator.clipboard.writeText(done);
                                })
                                .catch((err) =>
                                  global.modal.alert(err.message)
                                );
                            }}
                          />{" "}
                        </div>
                      </OverlayTrigger>
                    )}
                  {!u.emailConfirmed &&
                    [userType.ADMIN].includes(global.operator.type) && (
                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Link do convite copiado!
                          </Tooltip>
                        }
                      >
                        <div id="copy-link">
                          <Button
                            type="button"
                            title="Copiar Convite"
                            icon="fas fa-copy"
                            customClassName="btn-light btn-xs mb-0 mt-0"
                            onClick={() => {
                              post("User.GenerateNewLinkConfirmation", u)
                                .then((done) => {
                                  navigator.clipboard.writeText(done);
                                })
                                .catch((err) =>
                                  global.modal.alert(err.message)
                                );
                            }}
                          />{" "}
                        </div>
                      </OverlayTrigger>
                    )}
                  <Dropdown bsPrefix="dropdown-normatiza">
                    <Dropdown.Toggle
                      bsPrefix="btn-light btn-xs mb-0 mt-0"
                      id="dropdown-normatiza"
                    >
                      <i className="fas fa-ellipsis-v"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        id="excluir"
                        onClick={() =>
                          global.modal.confirm(
                            <>
                              {" "}
                              {u.type === userType.MANAGER && (
                                <p className="alert-remove">
                                  <h6> Esta ação é irreversível! </h6>{" "}
                                  <ul className="lista text-left">
                                    <li>
                                      {" "}
                                      <b> Todos os clientes </b> vinculados a
                                      este gestor serão <b>desvinculados!</b>{" "}
                                    </li>{" "}
                                  </ul>{" "}
                                  <span>
                                    {" "}
                                    Deseja realmente excluir o gestor{" "}
                                    <b> {u.name} </b>?
                                  </span>
                                </p>
                              )}
                              {u.type === userType.ANALYST && (
                                <p className="alert-remove">
                                  <h6> Esta ação é irreversível! </h6>{" "}
                                  <ul className="lista text-left">
                                    <li>
                                      {" "}
                                      <b> Todas as análises </b> vinculadas a
                                      este analista serão <b>desvinculadas!</b>{" "}
                                    </li>{" "}
                                    <li>
                                      {" "}
                                      <b> Todos os clientes </b> também serão{" "}
                                      <b>desvinculados!</b>{" "}
                                    </li>{" "}
                                  </ul>{" "}
                                  <span>
                                    {" "}
                                    Deseja realmente excluir o analista{" "}
                                    <b> {u.name} </b>?
                                  </span>
                                </p>
                              )}
                            </>,
                            (confirmed) => {
                              if (confirmed) {
                                if (
                                  ![userType.ADMIN].includes(
                                    global.operator.type
                                  )
                                ) {
                                  get(`User.findByMail?email=${u.email}`)
                                    .then((done) => {
                                      if (
                                        done &&
                                        [
                                          userType.MANAGER,
                                          userType.ANALYST,
                                        ].includes(u.type)
                                      ) {
                                        post("User.DeleteAnalistCliet", u)
                                          .then(refresh)
                                          .catch((err) =>
                                            global.modal.alert(err.message)
                                          );
                                      } else {
                                        remove("user", u.id)
                                          .then(refresh)
                                          .catch((err) =>
                                            global.modal.alert(err.message)
                                          );
                                      }
                                    })
                                    .catch((err) => {
                                      global.modal.alert(err.message);
                                    });
                                } else {
                                  remove("user", u.id)
                                    .then(refresh)
                                    .catch((err) =>
                                      global.modal.alert(err.message)
                                    );
                                }
                              }
                            }
                          )
                        }
                      >
                        <i className="fas fa-times valign-baseline"></i> Excluir
                        membro
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}{" "}
            </th>{" "}
          </tr>
        ))}{" "}
      </tbody>{" "}
    </table>
  );
};

export const UserList = ({ global, route }) => {
  const [fetching, setFetching] = useState(false);

  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({
    city: "",
    name: "",
  });
  const [customBtn, setCustomBtn] = useState({
    title: "",
    path: "",
  });
  const [isClientFilter] = useState(route.location.pathname === "/cliente");
  const [logedUserType] = useState(global.operator.type);
  const refresh = useRefresh();

  const filteredUsers = [
    ["city", filter.city],
    ["name", filter.name],
  ].reduce((items, [key, value]) => {
    return value !== ""
      ? items.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            i[key]
          )
        )
      : items;
  }, users);

  useEffect(() => {
    post("user.login-info", global.operator).then((user) => {
      localStore.set(config.OPERATOR_KEY, {
        ...user,
        apiVersion: global.operator.version,
      });
      const newOperator = localStore.get(config.OPERATOR_KEY) || {};
      global.operator = newOperator;
    });
    // eslint-disable-next-line
  }, [global.loading, refresh.ref]);

  useEffect(() => {
    if (!global.loading) {
      let mounted = true;
      setFetching(true);
      getList("user", {
        usePager: false,
        sort: [["type"]],
      })
        .then(({ items }) => {
          if (mounted) {
            if (isClientFilter) {
              setCustomBtn({
                title: "Adicionar Cliente",
                path: "/cliente/cadastro",
              });
              setUsers(items.filter((c) => c.type === 4));
            } else {
              setCustomBtn({
                title: "Convidar Membro",
                path: "/equipe/cadastro",
              });
              setUsers(
                logedUserType === userType.ADMIN
                  ? items.filter(
                      (c) =>
                        c.id !== global.operator.id && [1, 2].includes(c.type)
                    )
                  : items.filter(
                      (c) =>
                        c.id !== global.operator.id &&
                        [2, 3, 5, 6].includes(c.type)
                    )
              );
            }
          }
        })
        .catch((err) => global.modal.alert(err.message))
        .finally(() => mounted && setFetching(false));

      return () => {
        mounted = false;
      };
    }
    // eslint-disable-next-line
  }, [global.loading, refresh.ref]);

  const canEnable =
    [userType.ENGINEER, userType.ADMIN].includes(logedUserType) &&
    !isClientFilter;

  const canEdit =
    canEnable ||
    ([userType.ENGINEER].includes(logedUserType) && isClientFilter);

  return (
    <>
      <div className="row">
        <div className="col-md">
          <div className="card">
            <div className="card-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-auto mr-auto pl-0">
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Crie, gerencie acesso de membros da equipe e clientes!
                        </Tooltip>
                      }
                    >
                      <h5 id="showHelpUsers" className="card-title">
                        {" "}
                        {isClientFilter ? (
                          <div>
                            <i className="far fa-handshake"> </i> Clientes
                            cadastrados{" "}
                          </div>
                        ) : (
                          <div>
                            <i className="fas fa-users"> </i> Minha Equipe{" "}
                          </div>
                        )}{" "}
                      </h5>
                    </OverlayTrigger>
                  </div>{" "}
                  <div className="col-md-2 custom-input-field">
                    <TextInput
                      label="Filtrar por nome"
                      customClassName="custom"
                      icon="fas fa-search"
                      value={filter.name}
                      onChange={(name) =>
                        setFilter((prev) => ({
                          ...prev,
                          name,
                        }))
                      }
                    />{" "}
                  </div>
                  <div className="col-md-auto"> </div>
                  <div className="col-auto no-padding">
                    {" "}
                    {canEdit && (
                      <Button
                        customClassName="btn-success mtm-2 mb-0"
                        title={customBtn.title}
                        icon=""
                        disabled={
                          logedUserType === userType.ENGINEER &&
                          global.operator.isTrial &&
                          !global.operator.hasTrialMember
                        }
                        onClick={() => route.history.push(customBtn.path)}
                      />
                    )}{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="card-body">
              <div className="table-responsive">
                {" "}
                {fetching ? (
                  <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                    <p>
                      <Skeleton height={70} count={5} />{" "}
                    </p>{" "}
                  </SkeletonTheme>
                ) : (
                  <List
                    users={filteredUsers}
                    canEdit={canEdit}
                    canEnable={canEnable}
                    global={global}
                    refresh={refresh.force}
                    customBtn={customBtn}
                    isClientFilter={isClientFilter}
                  />
                )}{" "}
              </div>{" "}
            </div>{" "}
            {[userType.ENGINEER].includes(global.operator.type) && (
              <div className="card-footer">
                <small>
                  <i className="fas fa-question fa-fw fa-sm"></i> Listagem de
                  todos os membros da sua equipe convidados!
                </small>
              </div>
            )}
            {[userType.ADMIN].includes(global.operator.type) && (
              <div className="card-footer">
                <small>
                  <i className="fas fa-question fa-fw fa-sm"></i> Listagem de
                  todos os engenheiros cadastrados!
                </small>
              </div>
            )}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
};
