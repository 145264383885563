const apiUrls = {
  dev: "https://normatiza-producao-novo.uw.r.appspot.com/api",
  prod: "https://normatiza-producao-novo.uw.r.appspot.com/api",
  hml: "https://normatiza-producao-novo.uw.r.appspot.com/api",
};

export const config = {
  API_URL: apiUrls[process.env.REACT_APP_HOST_ENV || "dev"],
  DEFAULT_OPERATOR: {
    id: 0,
    name: "",
    email: "",
    type: "customer",
    useDocx: false,
    canDeleteAnalisys: false,
    maxAnalysis: 0,
    usedAnalysis: 0,
    isTrial: true,
    hasTrialLimit: false,
    hasTrialClient: false,
    hasTrialMember: false,
  },
  VERSION: "4.12.3",
  APIVERSION: "2.40.3",
  NAMESPACE: "normatiza",
  OPERATOR_KEY: "operator",
  LISTS_KEY: "lists",
  TOKEN_KEY: "tk",
};
