import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Button } from "../../core/components/button";
import { parseNumber } from "../../core/utils/parse";
import { downloadInventory, getById, getList } from "../../service";
import InventoryNoContent from "../../assets/img/027-error.png";
import { forceDownload } from "../../core/helpers/misc";

const MachineList = ({ fetching, machines }) => {
  if (fetching) {
    return (
      <SkeletonTheme color="#2E2F42" highlightColor="#404258">
        <p>
          <Skeleton duration={1.5} height={50} count={1} />
        </p>
      </SkeletonTheme>
    );
  }

  if (machines.length === 0) {
    return (
      <div className="info-no-content">
        <img
          className="image"
          alt="Não há equipamentos no inventário"
          src={InventoryNoContent}
        />
        <div className="inventory">
          Ainda não existem equipamentos analisados para compor o inventário.
        </div>
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          {machines.map((m) => (
            <tr key={m.id}>
              <th>
                <h6 className="list-title">ID da máquina/análise</h6>
                {m.equipmentId}
              </th>
              <th>
                <h6 className="list-title">Número de série</h6>
                {m.serialNumber}
              </th>
              <th>
                <h6 className="list-title">Nome da máquina</h6>
                {m.name}
              </th>
              <th>
                <h6 className="list-title">Setor localizado</h6>
                {m.sectorName}
              </th>
              <th>
                <h6 className="list-title">Tipo</h6>
                {m.type}
              </th>
              <th>
                <h6 className="list-title">Modelo</h6>
                {m.model}
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const InventoryPage = ({ route, global, customerId }) => {
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [machines, setMachines] = useState([]);
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const key = route.match.params.id || customerId;
    setLoading(true);
    Promise.all([
      getById("user", parseNumber(key), null),
      getList("machine", {
        usePager: false,
        filters: [{ items: [{ name: "customerId", value: parseNumber(key) }] }],
      }),
    ])
      .then(([customer, machines]) => {
        setCustomer(customer);
        setMachines(machines.items);
      })
      .catch((err) => global.modal.alert(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="row">
      <div className="col-md">
        <div className="card">
          <div className="card-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-auto mr-auto pl-0">
                  <div className="ptm-3 display-inline-table font-medium-5">
                    <h5>
                      <i className="fas fa-boxes mr-1"></i>Inventário de
                      máquinas e equipamentos
                    </h5>
                  </div>
                </div>

                <div className="col-auto no-padding text-right custom-input-field">
                  <Button
                    customClassName="btn btn-secondary mtm-2 mb-0 mt-0"
                    title="Voltar"
                    icon="fas fa-angle-left valign-middle"
                    onClick={() => route.history.goBack()}
                  />
                  <Button
                    title="Baixar inventário"
                    id="inventoryPDF"
                    icon="fas fa-file-pdf valign-middle"
                    customClassName="btn btn-primary btn-pdf mtm-2 mb-0 mt-0 ml-1"
                    disabled={downloading || !machines.length}
                    loading={downloading}
                    onClick={() => {
                      setDownloading(true);
                      downloadInventory(
                        customer.id,
                        `${customer.customerCode}-INVENTÁRIO.pdf`
                      )
                        .then((blob) =>
                          forceDownload(
                            blob,
                            `${customer.customerCode}-INVENTÁRIO.pdf`,
                            "Inventário"
                          )
                        )
                        .catch((err) => global.modal.alert(err.message))
                        .finally(() => setDownloading(false));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <MachineList fetching={loading} machines={machines} />
          </div>
          <div className="card-footer">
            <small>
              <i className="fas fa-question fa-fw fa-sm"></i> Inventário de
              máquinas e equipamentos cadastrado para o cliente "
              <span className="data cut">{`${customer.name}`}"</span>.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};
