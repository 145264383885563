import React, { useState, useEffect } from "react";
import { toLocaleDate, maskCpfCnpj } from "../../core/utils/format";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { userDocType } from "./utils";
import { getList, remove, get, getById } from "../../service";
import { useRefresh } from "../../core/hooks/refresh";
import SearchNotFound from "../../assets/img/029-search-not-found.png";
import { userType } from "../user/utils";
import { forceDownloadUrl } from "../../core/helpers/misc";
import { parseNumber } from "../../core/utils/parse";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const formatUserDocType = (type) => {
  switch (type) {
    case userDocType.DOCUMENT:
      return "Outros Documentos";
    case userDocType.ANALISY:
      return "Análise";
    case userDocType.PROJECT:
      return "Projetos/Estudos";
    case userDocType.TRAINING:
      return "Treinamentos";
    case userDocType.PROCESS:
      return "Procedimentos";
    default:
      return "Outros Documentos";
  }
};

const List = ({ docs, refresh, modal, operator }) => {
  const [loading, setLoading] = useState(false);

  return docs.length === 0 ? (
    <div className="info-no-content">
      <img
        className="image"
        alt="Não há análises cadastradas"
        src={SearchNotFound}
      ></img>
      <div className="inventory">
        Documento não cadastrado ou não encontrado por este filtro.
      </div>
    </div>
  ) : (
    <table className="table">
      <tbody>
        {docs
          .map((u) => (
            <tr key={u.id}>
              <th>
                <h6 className="list-title">Tipo de documento</h6>
                {formatUserDocType(u.type)}
              </th>
              <th>
                <h6 className="list-title">Análise relacionada</h6>
                <div className="cut-text-small">{u.analisisRef}</div>
              </th>
              <th>
                <h6 className="list-title">Nome do arquivo</h6>
                <div className="cut-text-small">{u.name}</div>
              </th>
              <th>
                <h6 className="list-title">Descrição do documento</h6>
                <div className="cut-text-medium">{u.description}</div>
              </th>
              <th>
                <h6 className="list-title">Data de Criação</h6>
                {toLocaleDate(u.dateFile)}
              </th>

              <th className="no-wrap no-width">
                <h6 className="list-title">Ação/Download</h6>

                {loading ? (
                  <i className="fas fa-spinner fa-spin valign-middle" />
                ) : (
                  <div>
                    {" "}
                    <Button
                      title="Baixar"
                      icon="fas fa-file-download"
                      customClassName="btn btn-success btn-xs mb-0 mt-0"
                      onClick={() => {
                        setLoading(true);
                        get(`UserDocs.DownloadFileUrl/${u.id}`)
                          .then((done) => {
                            forceDownloadUrl(done, u.name, "Arquivo");
                            // window.open(done);
                          })
                          .catch((err) => {
                            global.modal.alert(err.message);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }}
                    />
                    {operator !== userType.CUSTOMER && (
                      <Button
                        title="Excluir"
                        icon="fas fa-times"
                        customClassName="btn btn-danger btn-xs mb-0 mt-0 remove"
                        onClick={() =>
                          modal.confirm(
                            <div>
                              Deseja remover esse documento?{" "}
                              <strong>Esta é uma ação irreversível!</strong>
                            </div>,
                            (confirmed) => {
                              if (confirmed) {
                                remove("UserDocs", u.id)
                                  .then(() => refresh())
                                  .catch((err) => modal.alert(err.message));
                              }
                            }
                          )
                        }
                      />
                    )}
                  </div>
                )}
              </th>
            </tr>
          ))
          .sort()
          .reverse()}
      </tbody>
    </table>
  );
};

export const UserDocsList = ({ global, route }) => {
  const [customerId] = useState(route.match.params["id"]);
  const [fetching, setFetching] = useState(false);
  const [user, setUser] = useState({ name: "", cpfCnpj: "" });
  const [validClient, setValidClient] = useState(true);
  const [docs, setDocs] = useState([]);
  const [filter, setFilter] = useState({
    analisisRef: "",
    name: "",
    date: "",
  });

  const [logedUserType] = useState(global.operator.type);
  const refresh = useRefresh();

  const filteredUsers = [
    ["analisisRef", filter.analisisRef],
    ["name", filter.name],
  ].reduce((items, [key, value]) => {
    return value !== ""
      ? items.filter((i) =>
          new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
            i[key]
          )
        )
      : items;
  }, docs);

  useEffect(() => {
    get(`UserDocs.ValidCustumer?id=${customerId}`)
      .then((valid) => {
        if (!valid) {
          setValidClient(false);
          global.modal.alert(
            "Cliente não encontrado ou acesso não permitido.",
            () => route.history.push("/")
          );
        } else {
          return getById("user", parseNumber(customerId));
        }
      })
      .then((user) => {
        setUser(user);
      })
      .catch((err) => global.modal.alert(err.message));
  }, []);

  useEffect(() => {
    if (!global.loading && validClient) {
      let mounted = true;
      setFetching(true);
      getList("UserDocs", {
        usePager: false,
        filters: [{ items: [{ name: "CustomerId", value: customerId }] }],
      })
        .then(({ items }) => {
          if (mounted) {
            setDocs(items);
          }
        })
        .catch((err) => global.modal.alert(err.message))
        .finally(() => mounted && setFetching(false));

      return () => {
        mounted = false;
      };
    }
  }, [validClient, global.loading, refresh.ref]);

  return (
    <>
      <div className="row">
        <div className="col-md">
          <div className="card">
            <div className="card-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-auto mr-auto pl-0">
                    <h5 className="card-title">
                      {logedUserType === userType.ADMINs ? (
                        <div>
                          <i className="fas fa-file-alt"></i> Documentos extras{" "}
                        </div>
                      ) : (
                        <div className="customer">
                          <div className="title">
                            <i className="fas fa-file-alt "></i> Documentos
                            extras <span className="dash">/</span>{" "}
                            <span className="data">
                              Cliente {`${user.name}`}{" "}
                              <i
                                className="fas fa-check-circle valign-middle fa-xs"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </h5>
                  </div>

                  <div className="col-md-2 custom-input-field">
                    <TextInput
                      label="Filtrar por ID"
                      customClassName="custom"
                      icon="fas fa-search"
                      value={filter.type}
                      onChange={(analisisRef) =>
                        setFilter((prev) => ({ ...prev, analisisRef }))
                      }
                    />
                  </div>
                  <div className="col-md-2 custom-input-field">
                    <TextInput
                      label="Filtrar por nome"
                      customClassName="custom"
                      icon="fas fa-search"
                      value={filter.type}
                      onChange={(name) =>
                        setFilter((prev) => ({ ...prev, name }))
                      }
                    />
                  </div>

                  <div className="col-md-auto"></div>

                  <div className="col-auto no-padding">
                    {logedUserType !== userType.ADMIN ? (
                      <Button
                        customClassName="btn-secondary mtm-2 mb-0"
                        icon="fas fa-angle-left valign-middle"
                        title="Voltar"
                        onClick={`/clientes/${customerId}/equipamentos`}
                      />
                    ) : (
                      ""
                    )}

                    <Button
                      customClassName="btn-success mtm-2 mb-0 ml-1"
                      title="Novo documento"
                      icon="valign-middle"
                      onClick={`/cliente/${customerId}/extras/add`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {fetching ? (
                  <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                    <p>
                      <Skeleton height={60} count={1} />
                    </p>
                  </SkeletonTheme>
                ) : (
                  <List
                    docs={filteredUsers}
                    modal={global.modal}
                    refresh={refresh.force}
                    operator={logedUserType}
                  />
                )}
              </div>
            </div>
            <div className="card-footer">
              <small>
                <i className="fas fa-question fa-fw fa-sm"></i> Listagem de
                todos os documentos extras adicionados. Mais recentes primeiro.
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
