import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "../../../core/hooks/form";
import { getAnalisyName, getPageTitle, pageSteps } from "../utils";
import { Analysis } from "./analysis";
import { Machine } from "./machine";
import { RiskForm } from "./risk";
import { PapForm } from "./pap";
import { PeForm } from "./pe";

import { usePrevious } from "../../../core/hooks/previous";
import { getById, getList, addOrUpdate, remove } from "../../../service";
import { scrollTo } from "scroll-js";
import { useRefresh } from "../../../core/hooks/refresh";

import { Button } from "../../../core/components/button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { toastType } from "../../../core/helpers/misc";

export const RiskAnalysisForm = ({ route, global }) => {
  const [modified, setModified] = useState(false);
  const [page, setPage] = useState({
    type: "analysis",
    id: 0,
  });

  const [layout, setLayout] = useState({
    type: "grid",
    id: 0,
  });

  const [users, setUsers] = useState([]);
  const [sectors, setSectors] = useState([]);
  const prevPage = usePrevious(page);
  const refresh = useRefresh();
  const routeState = route.location.state || {};
  const form = useForm(
    {
      initialEntity: {
        id: 0,
        analystId: 0,
        createdAt: new Date(),
        customerId: routeState.customer ? routeState.customer.id : 0,
        customerName: "",
        customerCode: "",
        engineerId: 0,
        createdDevice: 1,
        machine: {
          id: 0,
          capacity: "",
          manufacturer: "",
          manufacturerAddress: "",
          manufacturerCity: "",
          manufacturerPostalCode: "",
          manufacturerCnpj: "",
          manufacturerCrea: "",
          manufacturerTag: "",
          manufacturerPatrimony: "",
          machineUsage: "",
          sectorId: 0,
          sectorName: "",
          serialNumber: "",
          operatorInterventions: "",
          eletricEnergy: false,
          hydraulicEnergy: false,
          mechanicalEnergy: false,
          pneumaticEnergy: false,
          radioactiveEnergy: false,
          equipmentType: "",
          equipmentModel: "",
          equipmentName: "",
          otherInfo: "",
          processDescription: "",
          productiveCapacity: "",
          commandPositions: "",
          cycleTime: "",
          driveTime: "",
          emergencyTime: "",
          manufactureYear: null,
          frontPhoto: null,
          leftPhoto: null,
          rightPhoto: null,
          topPhoto: null,
          risks: [],
          papDevices: [],
          peDevices: [],
          totalOperators: 1,
          width: "",
          height: "",
          weight: "",
          depth: "",
          capitation: false,
          hasInstructionManual: false,
          intendedPreventiveMaintenance: false,
          maintenanceRecordAvailable: false,
          registeredPreventiveMaintenance: false,
          workingAndSafetyProcedures: false,
        },
        userId: 0,
        projectArt: "",
        sequential: 0,
        removed: false,
        completed: 0,
      },
      validate: (values, errors) => {
        if (values.machine.sectorId === 0) {
          errors.machine = {
            ...errors.machine,
            sectorId: "Selecione o setor da máquina ou crie um novo",
          };
        }

        return errors;
      },
    },
    route
  );

  useEffect(() => {
    getList("user", { usePager: false })
      .then(({ items }) => setUsers(items))
      .catch((err) => global.modal.alert(err.message));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getList("sector", { usePager: false })
      .then(({ items }) => setSectors(items))
      .catch((err) => global.modal.alert(err.message));
    // eslint-disable-next-line
  }, [refresh.ref]);

  const { setValues, entity } = form;
  const { machine } = entity;
  const risk = machine.risks.find((r) => r.id === page.id);
  const pap = machine.papDevices.find((pap) => pap.id === page.id);
  const pe = machine.peDevices.find((pe) => pe.id === page.id);

  const setRisk = useCallback(
    (fn) => {
      setModified(false);

      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          risks: prev.machine.risks.map((r) => (r.id === page.id ? fn(r) : r)),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  const removeRisk = useCallback(
    () => {
      setModified(false);
      setPage((prev) => ({
        ...prev,
        type: "machine3",
      }));
      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          risks: prev.machine.risks.filter((r) => r.id !== page.id),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  const setPap = useCallback(
    (fn) => {
      setModified(false);
      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          papDevices: prev.machine.papDevices.map((pap) =>
            pap.id === page.id ? fn(pap) : pap
          ),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  const removePap = useCallback(
    () => {
      setModified(false);
      setPage((prev) => ({
        ...prev,
        type: "machine3",
      }));
      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          papDevices: prev.machine.papDevices.filter(
            (pap) => pap.id !== page.id
          ),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  const setPe = useCallback(
    (fn) => {
      setModified(false);
      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          peDevices: prev.machine.peDevices.map((pe) =>
            pe.id === page.id ? fn(pe) : pe
          ),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  const removePe = useCallback(
    () => {
      setModified(false);
      setPage((prev) => ({
        ...prev,
        type: "machine3",
      }));
      setValues((prev) => ({
        ...prev,
        machine: {
          ...prev.machine,
          peDevices: prev.machine.peDevices.filter((pe) => pe.id !== page.id),
        },
      }));
    },
    // eslint-disable-next-line
    [page.id]
  );

  useEffect(() => {
    if (form.hasId && !global.loading) {
      form.handleFetch({
        action: async (code) => {
          const id = parseInt(code, 10);
          const { lists } = global;

          return getById("analysis", id).then((analysis) => ({
            ...analysis,
            machine: {
              ...analysis.machine,
              papDevices: (analysis.machine.papDevices || []).map((pap) => ({
                ...pap,
                standardsItems: lists.standards.filter((s) =>
                  pap.standards.includes(s.id)
                ),
              })),
              peDevices: (analysis.machine.peDevices || []).map((pe) => ({
                ...pe,
                standardsItems: lists.standards.filter((s) =>
                  pe.standards.includes(s.id)
                ),
              })),
              risks: (analysis.machine.risks || []).map((r) => ({
                ...r,
                securityTypes: lists.securityTypes.filter((st) =>
                  r.securities.some((s) => s.typeId === st.id)
                ),
                types: lists.dangerTypes.filter(
                  (dt) =>
                    r.origins.some((o) => o.typeId === dt.id) ||
                    r.consequences.some((c) => c.typeId === dt.id)
                ),
                standardTitles: lists.standardTitles.filter((st) =>
                  r.standards.some((s) => s.titleId === st.id)
                ),
              })),
            },
          }));
        },
        errorFn: (err) =>
          global.modal.alert(err.message, () => route.history.goBack()),
      });
    }
    // eslint-disable-next-line
  }, [global.loading]);

  useEffect(() => {
    if (!!prevPage) {
      if (pageSteps.indexOf(page.type) < pageSteps.indexOf(prevPage.type)) {
        // if (modified) {

        form.handleSubmit((a) => {
          const b = JSON.parse(JSON.stringify(a));
          b.machine.papDevices = [];
          b.machine.peDevices = [];
          b.machine.risks = [];

          let messageToast =
            a.id > 0 && a.sequential > 0
              ? `Análise ${getAnalisyName(a)} salva!`
              : `Análise salva!`;
          addOrUpdate("analysis", b, {
            text: messageToast,
            type: toastType.SUCESS,
          })
            .then((resp) => {
              setModified(false);
              if (resp) {
                a.id = resp.id;
                a.machine.id = resp.machineId;
              }
            })
            .catch((err) => {
              global.modal.alert(err.message);
              route.history.goBack();
            })
            .finally(() => form.setSubmitting(false));
        });
        //}
        scrollTo(window, { top: document.body.scrollHeight });
      } else {
        scrollTo(window, { top: 0 });
      }
    }
    // eslint-disable-next-line
  }, [page.type]);

  useEffect(() => {
    const user = routeState.customer;

    if (user) {
      form.setValues({
        customerCode: user.customerCode,
        customerName: user.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  const customer = users.find((u) => u.id === entity.customerId) || {
    name: "",
    cpfCnpj: "",
  };

  return (
    <div>
      <form
        className="box is-clearfix"
        onSubmit={(ev) => {
          ev.preventDefault();
          form.handleSubmit((a) => {
            const b = JSON.parse(JSON.stringify(a));
            b.machine.papDevices = [];
            b.machine.peDevices = [];
            b.machine.risks = [];

            let messageToast =
              a.id > 0 && a.sequential > 0
                ? `Análise ${getAnalisyName(a)} salva!`
                : `Análise salva!`;
            addOrUpdate("analysis", b, {
              text: messageToast,
              type: toastType.SUCESS,
            })
              .then((resp) => {
                if (page.type === "machine4") {
                  return route.history.push(
                    `/clientes/${entity.customerId}/equipamentos`
                  );
                }

                setModified(false);

                if (resp) {
                  a.id = resp.id;
                  a.machine.id = resp.machineId;

                  global.refresh();
                }
              })
              .catch((err) => {
                global.modal.alert(err.message);
                route.history.goBack();
              })
              .finally(() => form.setSubmitting(false));
          });
        }}
      >
        {form.fetching ? (
          <>
            <div className="text-center h4 pt-5">
              <i className="fas fa-spinner fa-spin fa-2x" />
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-md">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    <div className="customer">
                      {" "}
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Voltar para equipamentos
                          </Tooltip>
                        }
                      >
                        <a
                          className="suffix"
                          onClick={() => route.history.goBack()}
                        >
                          <i
                            className="fas fa-folder valign-middle fa-sm"
                            aria-hidden="true"
                          />
                          <span> Equipamentos</span>
                        </a>
                      </OverlayTrigger>
                      <span className="dash">/</span>
                      {`${getPageTitle(page)} `}
                      <span className="dash">/</span>
                      <span className="data cut">
                        Cliente {`${customer.name}`}{" "}
                        <i
                          className="fas fa-check-circle valign-middle fa-xs"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </h5>

                  {["machine3"].includes(page.type) && (
                    <div className="col-md">
                      <div className="float-right">
                        <OverlayTrigger
                          key="card"
                          placement="left"
                          overlay={
                            <Tooltip id={`tooltip-card`}>
                              Visualizar em blocos
                            </Tooltip>
                          }
                        >
                          <Button
                            title=""
                            id="vgrid"
                            icon="fas fa-th"
                            customClassName="btn-primary btn-sm mb-0 mt-0 mr-1"
                            onClick={() =>
                              setLayout({
                                type: "grid",
                                id: 0,
                              })
                            }
                          />
                        </OverlayTrigger>

                        <OverlayTrigger
                          key="list"
                          placement="left"
                          overlay={
                            <Tooltip id={`tooltip-list`}>
                              Visualizar em lista
                            </Tooltip>
                          }
                        >
                          <Button
                            title=""
                            id="vlist"
                            icon="fas fa-bars"
                            customClassName="btn-primary btn-sm mb-0 mt-0"
                            onClick={() =>
                              setLayout({
                                type: "list",
                                id: 1,
                              })
                            }
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>

                <div className="card-body">
                  {page.type === "analysis" && (
                    <Analysis
                      form={form}
                      setPage={setPage}
                      route={route}
                      users={users}
                      customer={customer}
                      global={global}
                    />
                  )}

                  {["machine1", "machine2", "machine3", "machine4"].includes(
                    page.type
                  ) && (
                    <Machine
                      form={form}
                      global={global}
                      refresh={refresh.force}
                      page={page}
                      setPage={setPage}
                      sectors={sectors}
                      layout={layout}
                    />
                  )}

                  {page.type === "risk" && (
                    <RiskForm
                      risk={risk}
                      setRisk={setRisk}
                      removeRisk={removeRisk}
                      modal={global.modal}
                      lists={global.lists}
                      setPage={setPage}
                    />
                  )}

                  {page.type === "pap" && (
                    <PapForm
                      pap={pap}
                      setPap={setPap}
                      removePap={removePap}
                      modal={global.modal}
                      lists={global.lists}
                      setPage={setPage}
                    />
                  )}

                  {page.type === "pe" && (
                    <PeForm
                      pe={pe}
                      setPe={setPe}
                      removePe={removePe}
                      modal={global.modal}
                      lists={global.lists}
                      setPage={setPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
