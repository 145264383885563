import React from "react";
import ReactSelect from "react-select";

export const Select = ({
  getDisplay,
  getId,
  meta = {},
  selected,
  items,
  onChange,
  placeholder = "Selecione...",
  noItemMessage = "Nenhum resultado foi encontrado.",
  disabled,
  isMulti,
  label,
  isLoading,
  isClearable = false,
}) => {
  return (
    <div className="form-group">
      {label && <label>{label}</label>}

      <ReactSelect
        isClearable={isClearable}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        isSearchable
        classNamePrefix="react-select"
        noOptionsMessage={() => noItemMessage}
        placeholder={placeholder}
        isDisabled={disabled}
        options={items}
        getOptionLabel={getDisplay}
        getOptionValue={(v) => getId(v)}
        onChange={(v) => {
          if (!!onChange) {
            onChange(isMulti && !v ? [] : v);
          }
        }}
        value={selected ?? null}
        isLoading={isLoading}
      />

      {meta.touched && !!meta.error && (
        <p className="help is-danger help-error">{meta.error}</p>
      )}
    </div>
  );
};
