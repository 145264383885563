import React, { useState } from "react";
import {
  getHrnClassName,
  getHrnDescription,
  hrnTitles,
  peHelper,
} from "../../riskanalysis/utils";
import { classNames } from "../../../core/helpers/misc";
import { ReactHint } from "../../../core/components/tooltip";

export const HrnCalculate = () => {
  const [risk, setRisk] = useState({
    hrnFe: 0.5,
    hrnPe: 0.03,
    hrnMpl: 0.1,
    hrnNp: 1,
  });

  const hrnResult = risk.hrnFe * risk.hrnMpl * risk.hrnNp * risk.hrnPe;
  const hrnResultMessage = getHrnDescription(hrnResult);

  return (
    <div className="col-md-9">
      <div className="ptm-3 pb-2 display-inline-table font-medium-5">
        <h5>
          <div>Cálculo HRN</div>
        </h5>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="mb-1">
            <h5 className="card-title">
              <div>
                <i className="fas fa-calculator"></i> Calculadora de HRN
              </div>
            </h5>
          </div>
          <p style={{ fontWeight: 200 }}>
            Defina os valores de probabilidade, frequência, severidade e
            exposição para ter o valor de classificação de perigo.
          </p>
          <div className="row mb-2">
            <div className="col-md select-model">
              <div className="mt-2 mb-2">
                <div className="module-title">
                  <h2>Probabilidade de Exposição (PE)</h2>
                  <p>
                    {hrnTitles.pe[risk.hrnPe]}

                    <span
                      className="question-tooltip"
                      data-rh={peHelper(risk.hrnPe)}
                      data-rh-at="right"
                    >
                      ?
                    </span>
                  </p>

                  <ReactHint events={{ click: true, hover: true }} />
                </div>

                <div className="check-group ">
                  <div className="checkbox noui">
                    <input
                      id="hrnProb03r"
                      type="checkbox"
                      checked={risk.hrnPe === 0.03}
                      onChange={() => {
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 0.03,
                        }));
                      }}
                    />
                    <label htmlFor="hrnProb03r">0,03</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb1r"
                      type="checkbox"
                      checked={risk.hrnPe === 1}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 1,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb1r">1</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb1_5r"
                      type="checkbox"
                      checked={risk.hrnPe === 1.5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 1.5,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb1_5r">1,5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb2r"
                      type="checkbox"
                      checked={risk.hrnPe === 2}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 2,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb2r">2</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb5r"
                      type="checkbox"
                      checked={risk.hrnPe === 5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 5,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb5r">5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb8r"
                      type="checkbox"
                      checked={risk.hrnPe === 8}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 8,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb8r">8</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb10r"
                      type="checkbox"
                      checked={risk.hrnPe === 10}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 10,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb10r">10</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnProb15r"
                      type="checkbox"
                      checked={risk.hrnPe === 15}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnPe: 15,
                        }))
                      }
                    />
                    <label htmlFor="hrnProb15r">15</label>
                  </div>
                </div>

                <div className="module-title">
                  <h2>Frequência de Exposição (FE)</h2>
                  <p>{hrnTitles.fe[risk.hrnFe]}</p>
                </div>

                <div className="check-group ">
                  <div className="checkbox noui">
                    <input
                      id="hrnFreq05r"
                      type="checkbox"
                      checked={risk.hrnFe === 0.5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 0.5,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq05r">0,5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnFreq1r"
                      type="checkbox"
                      checked={risk.hrnFe === 1}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 1,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq1r">1</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnFreq15r"
                      type="checkbox"
                      checked={risk.hrnFe === 1.5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 1.5,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq15r">1,5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnFreq25r"
                      type="checkbox"
                      checked={risk.hrnFe === 2.5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 2.5,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq25r">2,5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnFreq4r"
                      type="checkbox"
                      checked={risk.hrnFe === 4}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 4,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq4r">4</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnFreq5r"
                      type="checkbox"
                      checked={risk.hrnFe === 5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnFe: 5,
                        }))
                      }
                    />
                    <label htmlFor="hrnFreq5r">5</label>
                  </div>
                </div>

                <div className="module-title">
                  <h2>
                    Grau de Severidade ou Probabilidade Máxima de Perda (PMP)
                  </h2>
                  <p>{hrnTitles.mpl[risk.hrnMpl]}</p>
                </div>

                <div className="check-group ">
                  <div className="checkbox noui">
                    <input
                      id="hrnSev01r"
                      type="checkbox"
                      checked={risk.hrnMpl === 0.1}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 0.1,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev01r">0,1</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev05r"
                      type="checkbox"
                      checked={risk.hrnMpl === 0.5}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 0.5,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev05r">0,5</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev2r"
                      type="checkbox"
                      checked={risk.hrnMpl === 2}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 2,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev2r">2</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev4r"
                      type="checkbox"
                      checked={risk.hrnMpl === 4}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 4,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev4r">4</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev6r"
                      type="checkbox"
                      checked={risk.hrnMpl === 6}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 6,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev6r">6</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev10r"
                      type="checkbox"
                      checked={risk.hrnMpl === 10}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 10,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev10r">10</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnSev15r"
                      type="checkbox"
                      checked={risk.hrnMpl === 15}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnMpl: 15,
                        }))
                      }
                    />
                    <label htmlFor="hrnSev15r">15</label>
                  </div>
                </div>

                <div className="module-title">
                  <h2>Número de Pessoas Expostas ao Risco (NP)</h2>
                  <p>{hrnTitles.np[risk.hrnNp]}</p>
                </div>

                <div className="check-group  mb-0">
                  <div className="checkbox noui">
                    <input
                      id="hrnPeople1r"
                      type="checkbox"
                      checked={risk.hrnNp === 1}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnNp: 1,
                        }))
                      }
                    />
                    <label htmlFor="hrnPeople1r">1</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnPeople2r"
                      type="checkbox"
                      checked={risk.hrnNp === 2}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnNp: 2,
                        }))
                      }
                    />
                    <label htmlFor="hrnPeople2r">2</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnPeople4r"
                      type="checkbox"
                      checked={risk.hrnNp === 4}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnNp: 4,
                        }))
                      }
                    />
                    <label htmlFor="hrnPeople4r">4</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnPeople8r"
                      type="checkbox"
                      checked={risk.hrnNp === 8}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnNp: 8,
                        }))
                      }
                    />
                    <label htmlFor="hrnPeople8r">8</label>
                  </div>

                  <div className="checkbox noui">
                    <input
                      id="hrnPeople12r"
                      type="checkbox"
                      checked={risk.hrnNp === 12}
                      onChange={() =>
                        setRisk((prev) => ({
                          ...prev,
                          hrnNp: 12,
                        }))
                      }
                    />
                    <label htmlFor="hrnPeople12r">12</label>
                  </div>
                </div>
                <div
                  className={classNames("hrn-bar", getHrnClassName(hrnResult))}
                >
                  <p>HRN :</p>
                  <h2>
                    {`${hrnResult.toLocaleString("pt-BR", {
                      useGrouping: false,
                    })} - ${hrnResultMessage}`}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 mb-1 calc-description">
            <h5 className="card-title">
              <div>
                <i className="fas fa-question"></i> Como funciona o cálculo?
              </div>
            </h5>
            <p>
              A cálculadora captura os quatro valores acima e os multiplica:
            </p>
            <ul>
              <li>Probabilidade de Exposição (PE)</li>
              <li>Frequência de Exposição (FE)</li>
              <li>Grau de Severidade ou Probabilidade Máxima de Perda (PMP)</li>
              <li>Número de Pessoas Expostas ao Risco (NP)</li>
            </ul>
            <p>
              Ou seja, o resultado do Cálculo HRN é:{" "}
              <strong>PE * FE * PMP * NP.</strong>
            </p>
          </div>

          <div className="mt-3 mb-1 calc-description">
            <h5 className="card-title">
              <div>
                <i className="fas fa-info"></i> Avalie o resultado
              </div>
            </h5>
            <p>
              O Cálculo HRN deve ser feito em cada ponto de risco analisado em
              um equipamento, ou seja, cada ponto irá resultar em nível grau de
              severidade diferente.
            </p>
            <table className="table table-hover table-sm">
              <thead className="thead-dark">
                <tr>
                  <th>Risco</th>
                  <th>Valor</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="aceitavel">Aceitável</td>
                  <td>0 a 1</td>
                  <td>Risco aceitável - considerar possíveis ações</td>
                </tr>
                <tr>
                  <td className="muitoBaixo">Muito baixo</td>
                  <td>1,1 a 5</td>
                  <td>Até 1 ano</td>
                </tr>
                <tr>
                  <td className="baixo">Baixo</td>
                  <td>5,1 a 10</td>
                  <td>Até 3 meses</td>
                </tr>
                <tr>
                  <td className="significante">Significante</td>
                  <td>10,1 a 50</td>
                  <td>Até 1 mês</td>
                </tr>
                <tr>
                  <td className="alto">Alto</td>
                  <td>50,1 a 100</td>
                  <td>Até 1 semana</td>
                </tr>
                <tr>
                  <td className="muitoAlto">Muito Alto</td>
                  <td>100,1 a 500</td>
                  <td>Até 1 dia</td>
                </tr>
                <tr>
                  <td className="extremo">Extremo</td>
                  <td>500,1 a 1000</td>
                  <td>Ação imediata</td>
                </tr>
                <tr>
                  <td className="inaceitavel">Inaceitável</td>
                  <td>Maior que 1000</td>
                  <td>Parar atividade</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
