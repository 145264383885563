import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { Route, Router, Switch } from "react-router";
import { Login } from "./components/login";
import { PrivateRoute } from "./core/components/route";
import { history } from "./core/utils/history";
import { Link } from "./core/components/route";
import "./assets/sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/pt-br";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import { Panel } from "./components/panel";
import { Badge } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import {
  UserList,
  UserForm,
  userType,
  UserFormSingUp,
  UserConfigsReport,
  UserHomeTopPageDashBord,
} from "./components/user";

import { toast } from "react-toastify";
import { StandardList, StandardForm } from "./components/standard";
import {
  StandardTitleList,
  StandardTitleForm,
} from "./components/standard-title";
import { UserLimitList } from "./components/user-limit";
import { getList, get } from "./service";
import { UserDocsForm, UserDocsList } from "./components/user-docs";
import { ConfirmUser } from "./components/user/ConfirmUser";
import { UserReportsList } from "./components/user-reports";

import { UserConsumeHistory } from "./components/user/UserConsumeHistory";
import { AnalysisTools } from "./components/external-tools";
import ModalVideo from "react-modal-video";
import { RiskAnalysisForm } from "./components/riskanalysis/form/RiskAnalysisForm";
import { RiskStudiesForm } from "./components/riskanalysis/form/RiskStudiesForm";
import { CustomerPage } from "./components/riskanalysis/CustomerPage";
import { EngineerAnalysisList } from "./components/riskanalysis/EngineerAnalysisList";
import { AnalysisPage } from "./components/riskanalysis/AnalysisPage";
import { InventoryPage } from "./components/riskanalysis/InventoryPage";
import { RiskTechnicalReportForm } from "./components/riskanalysis/form/RiskTechnicalReportForm";
import { GroundingReportForm } from "./components/riskanalysis/form/GroundingReportForm";
import { ManualReportForm } from "./components/riskanalysis/form/ManualReportForm";
import { ProceduresReportForm } from "./components/riskanalysis/form/ProceduresReportForm";
import { TrainingReportForm } from "./components/riskanalysis/form/TrainingReportForm";

const rootElement = document.getElementById("root");

toast.configure({
  position: "bottom-center",
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const HomeMessage = (op) => {
  return (
    <div className="row mb-3">
      <div className="col-lg">
        <div className="jumbotron normatiza">
          <h1>
            {`Seja bem-vindo (a),`} {<b> {op.name} </b>}
          </h1>
          <p className="lead">
            Bem-vindo (a) de volta ao painel de gestão da Normatiza –{" "}
            {moment().format("DD [de] MMMM [de] YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

const HomeZeroClient = (isOpen, playerTutorial) => {
  return (
    <div className="row">
      <div className="col-md">
        <div className="card">
          <div className="card-body pb-3">
            <span className="text-center ">
              <h2 className="mb-2">Vamos começar?</h2>
              <h3>
                <Badge className="mr-1 pt-1" pill variant="light">
                  1º Passo
                </Badge>{" "}
                Cadastre um cliente
              </h3>
              <h3>
                <Badge className="mr-1 pt-1" pill variant="light">
                  2º Passo
                </Badge>{" "}
                Crie análises de riscos, estudos, laudos
              </h3>
              <h3>
                <Badge className="mr-1 pt-1" pill variant="light">
                  3º Passo
                </Badge>{" "}
                Gerencie equipe, gere relatórios incríveis em PDF e muito mais!
              </h3>
            </span>
            <div className="col-auto mt-2 text-center">
              <Link
                className="btn btn-success mtm-2 mb-0"
                to={"cliente/cadastro"}
              >
                <span>Cadastrar primeiro cliente</span>
              </Link>
            </div>
            <p className="text-center text-white mt-2">Veja também:</p>
            <div className="col-auto text-center">
              <button
                className="btn btn-danger mtm-2 mb-0"
                onClick={() => playerTutorial(true)}
              >
                <i className="fas fa-video fa-fw fa-sm"></i>
                <span>Assistir tutorial rápido</span>
              </button>
            </div>

            <ModalVideo
              channel="youtube"
              title={false}
              isOpen={isOpen}
              videoId="CmlPG1BOy_4"
              onClose={() => playerTutorial(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LastAnalises = (loading, analysis, route, global) => {
  return (
    <div className="row mb-3">
      <div className="col-md">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title mb-2">
              <i className="fas fa-history"></i> Últimos relatórios criados
            </h5>

            {loading ? (
              <>
                <SkeletonTheme color="#2E2F42" highlightColor="#404258">
                  <p>
                    <Skeleton duration={1.5} height={50} count={3} />
                  </p>
                </SkeletonTheme>
              </>
            ) : (
              <EngineerAnalysisList
                noMargin
                dashBoard
                fetching={loading}
                items={analysis}
                route={route}
                global={global}
                simpleView={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const LastCustomers = (route, global) => {
  return (
    <div className="row mb-3">
      <div className="col-md">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title mb-2">
              <i className="fas fa-history"></i> Últimos Clientes criados
            </h5>
            <CustomerPage route={route} global={global} simpleView={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

const EngineerDashboard = ({ operator, route, global }) => {
  const [loading, setLoading] = useState(true);

  const [isOpenYoutube, playerTutorialYoutube] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  const [info, setInfo] = useState({
    completedAnalysisCount: 0,
    currentAnalysisBalance: 0,
    firstAnalisy: "",
    maxAnalysis: 0,
    riskCount: 0,
    stetesAnalisys: [],
    teamSize: 0,
    totalAnalysis: 0,
    totalClients: 0,
    totalStates: 0,
    usedAnalysis: 0,
    isIlimitedUser: false,
  });

  useEffect(() => {
    Promise.all([
      getList("analysis", { perPage: 3, sort: [["id", "DESC"]] }),
      get("dashboard.engineer"),
    ])
      .then(([analises, userinfo]) => {
        setInfo(userinfo);
        setAnalysis(analises.items);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {HomeMessage(operator)}
      {loading && (
        <SkeletonTheme color="#2E2F42" highlightColor="#404258">
          <div className="row">
            <div className={"col-md"}>
              <div className="mt-2">
                <Skeleton height={55} count={7} />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      )}

      {info.hasAnalisis || info.hasClient ? (
        <div>
          <div className="row mb-3">
            <div className="col-md">
              <div className="card">
                <div className="card-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-auto mr-auto pl-0">
                        <h5 className="card-title">
                          <div className="customer">
                            <i className="fas fa-tachometer-alt"></i> Visão
                            geral do meu ambiente
                          </div>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <UserHomeTopPageDashBord
                    global={global}
                    userInfo={info}
                    loading={loading}
                  ></UserHomeTopPageDashBord>
                </div>
              </div>
            </div>
          </div>

          {info.hasAnalisis
            ? LastAnalises(loading, analysis, route, global)
            : null}
          {info.hasClient ? LastCustomers(route, global) : null}
          <div className="card-footer">
            <small>
              <i className="fas fa-question fa-fw fa-sm"></i> Listagem das
              últimas análises criadas e todos os clientes são listados do mais
              recentes primeiro.
            </small>
          </div>
        </div>
      ) : (
        !loading && info && HomeZeroClient(isOpenYoutube, playerTutorialYoutube)
      )}
    </>
  );
};

render(
  <Router history={history}>
    <App>
      {(global) => (
        <Switch>
          <Route
            exact
            path="/login/:token?"
            render={(r) => <Login route={r} global={global} />}
          />
          <Route
            exact
            path="/login/confirm/:token?"
            render={(r) => (
              <ConfirmUser route={r} global={global} isEnginner={false} />
            )}
          />
          <Route
            exact
            path="/login/confirmEng/:token?"
            render={(r) => (
              <ConfirmUser route={r} global={global} isEnginner={true} />
            )}
          />
          <Route
            exact
            path="/registrar"
            render={(r) => <UserFormSingUp route={r} global={global} />}
          />

          <PrivateRoute
            path="/"
            render={(route) => (
              <Panel
                route={route}
                global={global}
                path="/"
                homeRender={(op) =>
                  [userType.ENGINEER, userType.GUESTENGINEER].includes(
                    op.type
                  ) ? (
                    <EngineerDashboard
                      operator={op}
                      route={route}
                      global={global}
                    />
                  ) : (
                    HomeMessage(op)
                  )
                }
                pages={[
                  {
                    href: "/clientes",
                    icon: "fas fa-user-check fa-lg fa-fw",
                    component: CustomerPage,
                    title: "Meus Clientes",
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    href: "/empresas",
                    icon: "fas fa-user-check fa-lg fa-fw",
                    component: CustomerPage,
                    title: "Minhas Empresas",
                    hideWhen: (op) => ![userType.MANAGER].includes(op.type),
                  },

                  {
                    href: "/equipe",
                    icon: "fas fa-users fa-lg fa-fw",
                    component: UserList,
                    title: "Usuários",
                    hideWhen: (op) => ![userType.ADMIN].includes(op.type),
                  },
                  {
                    href: "/cliente",
                    icon: "fas fa-building fa-lg fa-fw",
                    component: UserList,
                    title: "Empresas",
                    hideWhen: (op) => ![userType.ADMIN].includes(op.type),
                  },
                  {
                    href: "/ferramentas",
                    icon: "fas fa-tools fa-lg fa-fw",
                    component: AnalysisTools,
                    title: "Ferramentas",
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },

                  {
                    href: "/limites",
                    icon: "fas fa-user-tag fa-lg fa-fw",
                    component: UserLimitList,
                    title: "Limites & Uso",
                    hideWhen: (op) => op.type !== userType.ADMIN,
                  },
                  {
                    href: "/titulo",
                    icon: "fas fa-file-signature fa-lg fa-fw",
                    component: StandardTitleList,
                    title: "Titulos",
                    hideWhen: (op) => op.type !== userType.ADMIN,
                  },
                  {
                    href: "/norma",
                    icon: "fas fa-file-signature fa-lg fa-fw",
                    component: StandardList,
                    title: "Normas",
                    hideWhen: (op) => op.type !== userType.ADMIN,
                  },
                ]}
                userPages={[
                  {
                    href: "/meu-perfil",
                    icon: "fas fa-user-circle fa-fw ",
                    component: UserForm,
                    title: "Configurações da conta",
                  },
                  {
                    href: "/equipe",
                    icon: "fas fa-users fa-fw ",
                    component: UserList,
                    title: "Convidar membros",
                    hideWhen: (op) => ![userType.ENGINEER].includes(op.type),
                  },
                  {
                    // habilitar para mexer
                    href: "https://normatiza.app/kb",

                    icon: "fas fa-question-circle fa-fw ",
                    // component: VideoCollection,
                    title: "Ajuda e suporte",
                  },
                ]}
                routes={[
                  {
                    path: "/meu-perfil/personalizar",
                    component: UserConfigsReport,
                    hideWhen: (op) =>
                      ![userType.ENGINEER].includes(op.type) || op.isTrial,
                  },
                  {
                    path: "/meu-perfil/historico",
                    component: UserConsumeHistory,
                    hideWhen: (op) =>
                      ![userType.ENGINEER].includes(op.type) || op.isTrial,
                  },
                  {
                    path: ["/equipe/cadastro", "/equipe/cadastro/:id"],
                    component: UserForm,
                    hideWhen: (op) =>
                      ![userType.ENGINEER, userType.ADMIN].includes(op.type),
                  },
                  {
                    path: ["/cliente/cadastro", "/cliente/cadastro/:id"],
                    component: UserForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ADMIN,
                      ].includes(op.type),
                  },
                  {
                    path: ["/cliente/:id/extras/add"],
                    component: UserDocsForm,
                    hideWhen: (op) => [userType.ANALYST].includes(op.type),
                  },
                  {
                    path: ["/cliente/inventario/:id"],
                    component: InventoryPage,
                    hideWhen: (op) => [userType.ANALYST].includes(op.type),
                  },
                  {
                    path: ["/cliente/:id/extras"],
                    component: UserDocsList,
                    hideWhen: (op) => [userType.ANALYST].includes(op.type),
                  },
                  {
                    path: ["/cliente/:id/relatorio"],
                    component: UserReportsList,
                    hideWhen: (op) => [userType.ANALYST].includes(op.type),
                  },
                  {
                    path: ["/analise/cadastro", "/analise/:id"],
                    component: RiskAnalysisForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/clientes/:id/equipamentos"],
                    component: AnalysisPage,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                        userType.MANAGER,
                      ].includes(op.type),
                  },
                  {
                    path: ["/estudo/cadastro", "/estudo/:id"],
                    component: RiskStudiesForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/laudo/cadastro", "/laudo/:id"],
                    component: RiskTechnicalReportForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/processo/cadastro", "/processo/:id"],
                    component: ProceduresReportForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/manual/cadastro", "/manual/:id"],
                    component: ManualReportForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/capacitacao/cadastro", "/capacitacao/:id"],
                    component: TrainingReportForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/aterramento/cadastro", "/aterramento/:id"],
                    component: GroundingReportForm,
                    hideWhen: (op) =>
                      ![
                        userType.ENGINEER,
                        userType.GUESTENGINEER,
                        userType.ANALYST,
                      ].includes(op.type),
                  },
                  {
                    path: ["/norma/cadastro", "/norma/:id"],
                    component: StandardForm,
                    hideWhen: (op) => op.type !== userType.ADMIN,
                  },
                  {
                    path: ["/titulo/cadastro", "/titulo/:id"],
                    component: StandardTitleForm,
                    hideWhen: (op) => op.type !== userType.ADMIN,
                  },
                ]}
              />
            )}
          />
        </Switch>
      )}
    </App>
  </Router>,
  rootElement
);

serviceWorker.register();
