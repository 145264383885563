import React, { Component } from 'react'
import { classNames } from '../../../helpers/misc'
import { parseNumber } from '../../../utils/parse'
import {
    adjustDecimalCaret,
    maskDecimal,
    getInputSelection,
    setCaretPosition
} from '../../../utils/mask'

export class DecimalInput extends Component {
    static defaultConfig = {
        decimalSeparator: ',',
        precision: 2,
        prefix: '',
        selectAllOnFocus: true,
        thousandSeparator: '.'
    }

    static defaultProps = {
        ...DecimalInput.defaultConfig,
        ignoreEnter: true,
        input: {},
        meta: {}
    }

    state = {
        caretPosition: 0
    }

    constructor(props) {
        super(props)
        this._onChangeOrBlur = this._onChangeOrBlur.bind(this)
        this._onKeyDown = this._onKeyDown.bind(this)
        this._onFocus = this._onFocus.bind(this)
        this._applyRef = this._applyRef.bind(this)
        this.inputElement = null
    }

    handleChange(text, type) {
        const { props } = this
        const inputElement = this.inputElement

        if (!!props.onChange && !!inputElement) {
            const precision = Math.max(0, Math.min(20, props.precision))
            const { end: caretPosition } = getInputSelection(inputElement)
            const value = Math.min(parseNumber(text) / 10 ** precision, Number.MAX_SAFE_INTEGER)
            const masked = maskDecimal(value, { ...this.getConfig(), prefix: '' })

            this.setState({
                caretPosition: adjustDecimalCaret({ caretPosition, masked, text })
            })

            props.onChange(value, type)
        }
    }

    componentDidUpdate() {
        const { state, inputElement } = this

        if (!!inputElement) {
            setCaretPosition(inputElement, state.caretPosition)
        }
    }

    render() {
        const { meta, value, input } = this.props
        const config = this.getConfig()

        const hasError = meta.touched && !!meta.error
        const showPrefix = config.prefix !== ''

        return (
            <>
                <div className={showPrefix ? 'field has-addons' : 'field'}>
                    {showPrefix && (
                        <div className="control">
                            <div className="button is-static">{config.prefix}</div>
                        </div>
                    )}

                    <div className="control is-expanded">
                        <input
                            {...input}
                            type="tel"
                            value={maskDecimal(value, { ...config, prefix: '' })}
                            className={classNames('input', { 'is-danger': hasError })}
                            ref={this._applyRef}
                            onChange={this._onChangeOrBlur}
                            onBlur={this._onChangeOrBlur}
                            onFocus={this._onFocus}
                            onKeyDown={this._onKeyDown}
                        />
                    </div>
                </div>

                {hasError && <p className="help is-danger">{meta.error}</p>}
            </>
        )
    }

    _applyRef = el => (this.inputElement = el) && this.props.refEl && this.props.refEl(el)

    _onKeyDown = ev => ev.keyCode === 13 && this.props.ignoreEnter && ev.preventDefault()

    _onFocus = ev => this.props.selectAllOnFocus && ev.target.select()

    _onChangeOrBlur = ev => this.handleChange(ev.target.value, ev.type === 'blur' ? ev.type : 'key')

    getConfig() {
        const {
            decimalSeparator,
            precision,
            prefix,
            selectAllOnFocus,
            thousandSeparator
        } = this.props
        return { decimalSeparator, precision, prefix, selectAllOnFocus, thousandSeparator }
    }
}
