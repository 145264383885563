import { createLocation } from "history";
import React, { Component } from "react";
import { history } from "../../utils/history";
import { isValidURL } from "../../utils/validate";
const isModifiedEvent = (event) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export class Link extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(newProps) {
    const { props } = this;

    return (
      newProps.to !== props.to ||
      newProps.children !== props.children ||
      newProps.className !== props.className
    );
  }

  handleClick = (event) => {
    const { props } = this;
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      if (isValidURL(props.to)) {
        window.open(`${props.to}`, "_blank");
      } else {
        history.push(props.to);
      }
    }
  };

  render() {
    const { to, ...props } = this.props;

    const href = history.createHref(
      createLocation(to, null, undefined, history.location)
    );

    return (
      <a {...props} onClick={this.handleClick} href={href}>
        {props.children}
      </a>
    );
  }
}
