import React, { useState } from "react";
import { Button } from "../../core/components/button";
import { TextInput } from "../../core/components/form/text-input";
import { useForm } from "../../core/hooks/form";
import { requiredMessage } from "../../core/utils/misc";
import { config } from "../../config";
import Logo from "../../assets/img/logo.svg";
import { post } from "../../service";
import { LoginErrorMessage } from "../../core/components/form/error-message";

function changePassword(login, route, form, global, token, isEnginner) {
  const url = isEnginner ? "password.confirmEnginner" : "password.confirm";
  post(url, {
    token,
    password: login.password,
  })
    .then(() => {
      global.modal.alert(`Tudo pronto. Conta confirmada, agora basta acessar!`);
      form.resetForm();
      route.history.push("/login");
    })
    .catch((err) => form.setErrors({ global: err.message }));
}

export const ConfirmUser = ({ route, global, isEnginner }) => {
  const { token } = route.match.params;
  const form = useForm(
    {
      initialEntity: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      validate: (values, err) => {
        if (!isEnginner) {
          if (!values.password) {
            err.password = requiredMessage;
          }

          if (!values.confirmPassword) {
            err.confirmPassword = requiredMessage;
          }

          if (values.password !== values.confirmPassword) {
            err.confirmPassword = "Senhas devem ser iguais.";
          }
        }

        return err;
      },
    },
    route
  );

  function renderPass(form) {
    const { entity, errors, touched, handleChange, submitting } = form;

    return (
      <>
        {!isEnginner && (
          <div>
            <p className="text-center login-help">
              <i className="fas fa-life-ring"></i> Cadastre uma senha para
              continuar!
            </p>
            <TextInput
              acceptEnter
              type="password"
              label="Senha"
              value={entity.password}
              meta={{
                error: errors.password,
                touched: touched.password,
              }}
              onChange={(password, type) =>
                handleChange({
                  path: "password",
                  type,
                  values: { password },
                })
              }
            />
            <TextInput
              acceptEnter
              type="password"
              label="Confirme a Senha"
              value={entity.confirmPassword}
              meta={{
                error: errors.confirmPassword,
                touched: touched.confirmPassword,
              }}
              onChange={(confirmPassword, type) =>
                handleChange({
                  path: "confirmPassword",
                  type,
                  values: { confirmPassword },
                })
              }
            />
          </div>
        )}
        <p className="text-center login-help">
          <i className="fas fa-life-ring"></i> Clique abaixo para confirmar sua conta!
        </p>
        <Button
          customClassName="login-btn"
          icon="fas fa-check-double fa-xs"
          title="Confirmar minha conta"
          loading={submitting}
          disabled={submitting}
        />

        <LoginErrorMessage error={errors._error} />
        <br />
        <br />
      </>
    );
  }
  return (
    <div className="login-page">
      <div className="login-logo">
        <img src={Logo} alt="Normatiza" />
        <i className="fas fa-code" aria-hidden="true"></i>{" "}
        <small style={{ color: `#ffffff` }}>
          BETA v{config.VERSION} / API v{config.APIVERSION}
        </small>
      </div>

      <div className="login-box">
        <div className="login-form">
          <form
            onSubmit={(ev) => {
              ev.preventDefault();

              form.handleSubmit((ent) =>
                changePassword(ent, route, form, global, token, isEnginner)
              );
            }}
          >
            {renderPass(form)}
          </form>
        </div>
      </div>
    </div>
  );
};
