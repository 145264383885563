import { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const modalElement = document.getElementById('modal')
const container = document.createElement('div')

export const ModalPortal = ({ children }) => {
    useLayoutEffect(() => {
        modalElement.appendChild(container)
        return () => modalElement.removeChild(container)
    }, [])

    return createPortal(children, container)
}
