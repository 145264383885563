import React, { useEffect, useState } from "react";
import {
  newRisk,
  cloneRisk,
  clonePAPDevice,
  clonePEDevice,
  generateId,
  newPAPDevice,
  newPEDevice,
  getHrnClassName,
  getHrnDescription,
  pageSteps,
} from "../utils";
import { TextInput } from "../../../core/components/form/text-input";
import { IntegerInput } from "../../../core/components/form/integer-input";
import { TextAreaInput } from "../../../core/components/form/textarea-input";
import { PhotoBox } from "../../../core/components/PhotoBox/PhotoBox";
import { classNames, showToast, toastType } from "../../../core/helpers/misc";
import CreatableSelect from "react-select/creatable";
import { inPath } from "../../../core/helpers/path";
import { CepMask, CnpjMask } from "../../../core/utils/misc";
import { add, remove } from "../../../service";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { Button } from "../../../core/components/button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MachineInfo = ({
  form: { entity, touched, errors, handleChange },
  sectors,
  refresh,
  machine,
  setMachine,
  modal,
}) => {
  const sectorError = inPath(touched, "machine.sector")
    ? inPath(errors, "machine.sector")
    : undefined;

  const mensageBlock = "Analise finalizada, campo bloqueado para edição";
  return (
    <>
      <h3 className="module-header">Fotos das faces da máquina</h3>
      <div className="fotos mb-3">
        <PhotoBox
          title="Frontal"
          readOnly={entity.completed}
          photo={machine.frontPhoto}
          modal={modal}
          onChange={(data) =>
            setMachine((prev) => ({
              ...prev,
              frontPhoto: data
                ? {
                    id: generateId(),
                    data,
                  }
                : null,
            }))
          }
        />

        <PhotoBox
          title="Esquerda"
          readOnly={entity.completed}
          photo={machine.leftPhoto}
          modal={modal}
          onChange={(data) =>
            setMachine((prev) => ({
              ...prev,
              leftPhoto: data
                ? {
                    id: generateId(),
                    data,
                  }
                : null,
            }))
          }
        />

        <PhotoBox
          title="Direita"
          readOnly={entity.completed}
          photo={machine.rightPhoto}
          modal={modal}
          onChange={(data) =>
            setMachine((prev) => ({
              ...prev,
              rightPhoto: data
                ? {
                    id: generateId(),
                    data,
                  }
                : null,
            }))
          }
        />

        <PhotoBox
          title="Posterior"
          readOnly={entity.completed}
          photo={machine.topPhoto}
          modal={modal}
          onChange={(data) =>
            setMachine((prev) => ({
              ...prev,
              topPhoto: data
                ? {
                    id: generateId(),
                    data,
                  }
                : null,
            }))
          }
        />
      </div>
      <h3 className="module-header">
        Setor desta máquina <span className="red">*</span>
      </h3>
      <div className="row">
        <div className="col-md">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              entity.completed ? (
                <Tooltip id={`tooltip-top`}>{mensageBlock}a</Tooltip>
              ) : (
                <Tooltip hidden={true} id={`tooltip-top`}>
                  b
                </Tooltip>
              )
            }
          >
            <div className="form-group">
              <CreatableSelect
                isDisabled={entity.completed}
                classNamePrefix="react-select"
                options={sectors
                  .filter((s) => s.customerId === entity.customerId)
                  .map(({ id, name }) => ({ label: name, value: id }))}
                onChange={(item, { action }) => {
                  let sectorId = item ? item.value : 0;
                  const sectorName = item ? item.label : "";

                  if (action === "create-option") {
                    return add("sector", {
                      name: sectorName,
                      customerId: entity.customerId,
                    })
                      .then(({ id }) => {
                        setMachine((prev) => ({
                          ...prev,
                          sectorId: id,
                          sectorName,
                        }));

                        return refresh();
                      })
                      .catch((err) => modal.alert(err.message));
                  }

                  setMachine((prev) => ({
                    ...prev,
                    sectorId,
                    sectorName,
                  }));
                }}
                placeholder="Selecione ou crie um setor..."
                formatCreateLabel={(v) => {
                  return `Criar novo setor chamado "${v}"`;
                }}
                noOptionsMessage={() =>
                  "Nenhum setor foi localizado! Crie um novo ou melhore a busca."
                }
                value={
                  machine.sectorId
                    ? {
                        label: machine.sectorName,
                        value: machine.sectorId,
                      }
                    : undefined
                }
              />

              {sectorError && (
                <p className="help is-danger help-error">{sectorError}</p>
              )}
            </div>
          </OverlayTrigger>
        </div>
      </div>

      <h3 className="module-header">Dados básicos da máquina</h3>

      <div className="row">
        <div className="col-md">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              entity.completed ? (
                <Tooltip id={`tooltip-top`}>{mensageBlock}</Tooltip>
              ) : (
                <Tooltip hidden={true} id={`tooltip-top`}></Tooltip>
              )
            }
          >
            <TextInput
              disabled={entity.completed}
              label="Nome da Máquina"
              value={machine.equipmentName}
              onChange={(equipmentName) =>
                setMachine((prev) => ({
                  ...prev,
                  equipmentName,
                }))
              }
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="Altura"
            value={machine.height}
            onChange={(height) =>
              setMachine((prev) => ({
                ...prev,
                height,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Largura"
            value={machine.width}
            onChange={(width) =>
              setMachine((prev) => ({
                ...prev,
                width,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Profundidade"
            value={machine.depth}
            onChange={(depth) =>
              setMachine((prev) => ({
                ...prev,
                depth,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Peso"
            value={machine.weight}
            onChange={(weight) =>
              setMachine((prev) => ({
                ...prev,
                weight,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="Tipo de Máquina"
            value={machine.equipmentType}
            onChange={(equipmentType) =>
              setMachine((prev) => ({
                ...prev,
                equipmentType,
              }))
            }
          />
        </div>

        <div className="col-md">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              entity.completed ? (
                <Tooltip id={`tooltip-top`}>{mensageBlock}</Tooltip>
              ) : (
                <Tooltip hidden={true} id={`tooltip-top`}></Tooltip>
              )
            }
          >
            <TextInput
              label="Modelo da Máquina"
              disabled={entity.completed}
              value={machine.equipmentModel}
              onChange={(equipmentModel) =>
                setMachine((prev) => ({
                  ...prev,
                  equipmentModel,
                }))
              }
            />
          </OverlayTrigger>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              entity.completed ? (
                <Tooltip id={`tooltip-top`}>{mensageBlock}</Tooltip>
              ) : (
                <Tooltip hidden={true} id={`tooltip-top`}></Tooltip>
              )
            }
          >
            <TextInput
              label="Número de Série"
              disabled={entity.completed}
              value={machine.serialNumber}
              onChange={(serialNumber) =>
                setMachine((prev) => ({
                  ...prev,
                  serialNumber,
                }))
              }
            />
          </OverlayTrigger>
        </div>

        <div className="col-md">
          <IntegerInput
            label="Ano de Fabricação"
            value={machine.manufactureYear}
            onChange={(manufactureYear) =>
              setMachine((prev) => ({
                ...prev,
                manufactureYear,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="Capacidade"
            value={machine.capacity}
            onChange={(capacity) =>
              setMachine((prev) => ({
                ...prev,
                capacity,
              }))
            }
          />
        </div>
      </div>

      <h3 className="module-header">Dados do fabricante</h3>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="Fabricante"
            value={machine.manufacturer}
            onChange={(manufacturer) =>
              setMachine((prev) => ({
                ...prev,
                manufacturer,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Endereço"
            value={machine.manufacturerAddress}
            onChange={(manufacturerAddress) =>
              setMachine((prev) => ({
                ...prev,
                manufacturerAddress,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Cidade"
            value={machine.manufacturerCity}
            onChange={(manufacturerCity) =>
              setMachine((prev) => ({
                ...prev,
                manufacturerCity,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="CEP"
            mask={CepMask}
            value={machine.manufacturerPostalCode}
            onChange={(manufacturerPostalCode) =>
              setMachine((prev) => ({
                ...prev,
                manufacturerPostalCode,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="CNPJ"
            mask={CnpjMask}
            value={machine.manufacturerCnpj}
            onChange={(manufacturerCnpj, type) =>
              handleChange({
                type,
                path: "machine.manufacturerCnpj",
                values: (prev) => ({
                  ...prev,
                  machine: {
                    ...prev.machine,
                    manufacturerCnpj,
                  },
                }),
              })
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Registro CREA"
            value={machine.manufacturerCrea}
            onChange={(manufacturerCrea) =>
              setMachine((prev) => ({
                ...prev,
                manufacturerCrea,
              }))
            }
          />
        </div>

        <div className="col-md">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              entity.completed ? (
                <Tooltip id={`tooltip-top`}>{mensageBlock}</Tooltip>
              ) : (
                <Tooltip hidden={true} id={`tooltip-top`}></Tooltip>
              )
            }
          >
            <TextInput
              label="TAG"
              disabled={entity.completed}
              value={machine.manufacturerTag}
              onChange={(manufacturerTag) =>
                setMachine((prev) => ({
                  ...prev,
                  manufacturerTag,
                }))
              }
            />
          </OverlayTrigger>
        </div>

        <div className="col-md">
          <TextInput
            label="Patrimônio"
            value={machine.manufacturerPatrimony}
            onChange={(manufacturerPatrimony) =>
              setMachine((prev) => ({
                ...prev,
                manufacturerPatrimony,
              }))
            }
          />
        </div>
      </div>
    </>
  );
};

function renderMachineStructure(machine, setMachine) {
  return (
    <>
      <h3 className="module-header">Dados de utilização da máquina</h3>
      <div className="row">
        <div className="col-md">
          <TextInput
            label="Utilização da Máquina"
            value={machine.machineUsage}
            onChange={(machineUsage) =>
              setMachine((prev) => ({
                ...prev,
                machineUsage,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Capacidade Produtiva"
            value={machine.productiveCapacity}
            onChange={(productiveCapacity) =>
              setMachine((prev) => ({
                ...prev,
                productiveCapacity,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextAreaInput
            label="Descrição dos Processos Realizados pela Máquina"
            value={machine.processDescription}
            rows={5}
            onChange={(processDescription) =>
              setMachine((m) => ({
                ...m,
                processDescription,
              }))
            }
          />
        </div>
      </div>

      <p style={{ marginBottom: 10 }}>Operadores</p>

      <div className="row">
        <div className="col-md">
          <IntegerInput
            showButtons
            label="Número de Operadores"
            value={machine.totalOperators}
            min={1}
            onChange={(v) =>
              setMachine((prev) => ({
                ...prev,
                totalOperators: v || 1,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Intervenções realizadas pelos Operadores"
            value={machine.operatorInterventions}
            onChange={(operatorInterventions) =>
              setMachine((prev) => ({
                ...prev,
                operatorInterventions,
              }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3>Fontes de Energia</h3>

          <div className="check-group ">
            <div className="checkbox">
              <input
                id="electricEnergy"
                type="checkbox"
                checked={machine.eletricEnergy}
                onChange={({ target }) =>
                  setMachine((prev) => ({
                    ...prev,
                    eletricEnergy: target.checked,
                  }))
                }
              />
              <label className="noselect" htmlFor="electricEnergy">
                Elétrica
              </label>
            </div>

            <div className="checkbox">
              <input
                id="pneumaticEnergy"
                type="checkbox"
                checked={machine.pneumaticEnergy}
                onChange={({ target }) =>
                  setMachine((m) => ({
                    ...m,
                    pneumaticEnergy: target.checked,
                  }))
                }
              />
              <label className="noselect" htmlFor="pneumaticEnergy">
                Pneumática
              </label>
            </div>

            <div className="checkbox">
              <input
                id="mechanicalEnergy"
                type="checkbox"
                checked={machine.mechanicalEnergy}
                onChange={({ target }) =>
                  setMachine((m) => ({
                    ...m,
                    mechanicalEnergy: target.checked,
                  }))
                }
              />
              <label className="noselect" htmlFor="mechanicalEnergy">
                Mecânica
              </label>
            </div>

            <div className="checkbox">
              <input
                id="hydraulicEnergy"
                type="checkbox"
                checked={machine.hydraulicEnergy}
                onChange={({ target }) =>
                  setMachine((m) => ({
                    ...m,
                    hydraulicEnergy: target.checked,
                  }))
                }
              />
              <label className="noselect" htmlFor="hydraulicEnergy">
                Hidráulica
              </label>
            </div>

            <div className="checkbox">
              <input
                id="radioactiveEnergy"
                type="checkbox"
                checked={machine.radioactiveEnergy}
                onChange={({ target }) =>
                  setMachine((m) => ({
                    ...m,
                    radioactiveEnergy: target.checked,
                  }))
                }
              />
              <label className="noselect" htmlFor="radioactiveEnergy">
                Radioativa
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <TextInput
            label="Tempo de Acionamento"
            value={machine.driveTime}
            onChange={(driveTime) =>
              setMachine((prev) => ({
                ...prev,
                driveTime,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Tempo de Ciclo"
            value={machine.cycleTime}
            onChange={(cycleTime) =>
              setMachine((prev) => ({
                ...prev,
                cycleTime,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Tempo de Parada de Emergência"
            value={machine.emergencyTime}
            onChange={(emergencyTime) =>
              setMachine((prev) => ({
                ...prev,
                emergencyTime,
              }))
            }
          />
        </div>

        <div className="col-md">
          <TextInput
            label="Posições de Comando de Máquina"
            value={machine.commandPositions}
            onChange={(commandPositions) =>
              setMachine((prev) => ({
                ...prev,
                commandPositions,
              }))
            }
          />
        </div>
      </div>

      <h3 className="module-header">Dados adicionais da máquina</h3>

      <div className="row">
        <div className="col-md">
          <TextAreaInput
            label="Outras Informações"
            value={machine.otherInfo}
            rows={5}
            onChange={(otherInfo) =>
              setMachine((m) => ({
                ...m,
                otherInfo,
              }))
            }
          />
        </div>
      </div>
    </>
  );
}

function renderMachineRisk(
  machine,
  setMachine,
  setPage,
  modal,
  layout,
  analiseId,
  customerId,
  onRemoveRisk,
  onRemovePap,
  onRemovePe
) {
  return (
    <>
      <h3 className="module-header">Pontos de risco, PAP e PE</h3>
      <div className="row">
        <div className="col-md">
          <h3>Pontos de Risco</h3>
          {layout.type === "grid" && (
            <div className="risk-slider">
              <div
                className="box box-add"
                onClick={() => {
                  let newRsk = newRisk(
                    machine.id,
                    analiseId,
                    customerId,
                    Math.max(...machine.risks.map((o) => o.sequencial), 0)
                  );
                  setMachine((prev) => ({
                    ...prev,
                    risks: [...prev.risks, newRsk],
                  }));

                  setPage((prev) => ({
                    ...prev,
                    type: "risk",
                    id: newRsk.id,
                  }));
                }}
              >
                <span className="mt-1">Adicionar novo</span>
                <i className="fas fa-folder-plus icon mt-1" />
              </div>

              {machine.risks
                .map((r, i) => {
                  const hrnResult = r.hrnFe * r.hrnMpl * r.hrnNp * r.hrnPe;

                  return (
                    <div className="box" key={r.id}>
                      <div
                        className="col nospace"
                        onClick={() =>
                          setPage((prev) => ({
                            ...prev,
                            type: "risk",
                            id: r.id,
                          }))
                        }
                      >
                        <p className="local">
                          <i className="fas fa-map-marker-alt" /> {r.local}
                        </p>

                        <p
                          className={classNames(
                            "hrn",
                            getHrnClassName(hrnResult)
                          )}
                        >
                          {"HRN: "}
                          {hrnResult.toLocaleString("pt-br", {
                            useGrouping: false,
                          })}
                        </p>
                      </div>
                      <div className="col nospace action">
                        <span>{i + 1}º Ponto</span>

                        <OverlayTrigger
                          key={`ExcluirRisk${i}`}
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-left`}>
                              Excluir ponto de risco
                            </Tooltip>
                          }
                        >
                          <span
                            id="ExcluirRisk"
                            onClick={() => {
                              modal.confirm(
                                <div>
                                  <span className="alert-remove">
                                    <h6>Deseja remover este ponto de risco?</h6>
                                    <span>Esta remoção é irreversível!</span>
                                  </span>
                                </div>,
                                (confirmed) => {
                                  if (confirmed) {
                                    onRemoveRisk({
                                      excluded: true,
                                      id: r.id,
                                    });
                                    setMachine((prev) => ({
                                      ...prev,
                                      risks: [
                                        ...prev.risks.filter(
                                          (c) => c.id !== r.id
                                        ),
                                      ],
                                    }));
                                  }
                                }
                              );
                            }}
                          >
                            <i className="fas fa-trash remove" />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          key={`DuplicarRisk${i}`}
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-left`}>
                              Duplicar ponto de risco
                            </Tooltip>
                          }
                        >
                          <span
                            id="clonarRisk"
                            onClick={() => {
                              var risk = cloneRisk(r, machine);
                              setMachine((prev) => ({
                                ...prev,
                                risks: [...prev.risks, risk],
                              }));

                              setPage((prev) => ({
                                ...prev,
                                type: "risk",
                                id: risk.id,
                              }));
                            }}
                          >
                            <i className="fas fa-copy clone" />
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key={`image${i}`}
                          placement="top"
                          overlay={
                            <div className="fotos mb-3">
                              <PhotoBox
                                readOnly={true}
                                photo={r.photo}
                                modal={modal}
                              />
                            </div>
                          }
                        >
                          <span id="PreviewRisk">
                            <i
                              className="fas fa-camera preview"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  );
                })
                .reverse()}
            </div>
          )}

          {layout.type === "list" && (
            <div className="risk-slider display-block">
              <table className="table table-hover table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Ponto</th>
                    <th scope="col">Localização do Ponto</th>
                    <th scope="col">HRN - Hazard Rating Number</th>
                    <th scope="col">Categoria NBR 14153</th>
                    <th scope="col">Prévia</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {machine.risks.map((r, i) => {
                    const hrnResult = r.hrnFe * r.hrnMpl * r.hrnNp * r.hrnPe;

                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r.local}</td>
                        <td>
                          <p
                            className={classNames(
                              "hrn",
                              getHrnClassName(hrnResult)
                            )}
                          >
                            {hrnResult.toLocaleString("pt-br", {
                              useGrouping: false,
                            })}{" "}
                            - {getHrnDescription(hrnResult)}
                          </p>
                        </td>
                        <td>
                          {r.severityCategory
                            ? r.severityCategory
                            : "Não categorizado"}
                        </td>
                        <td className="action">
                          <OverlayTrigger
                            key={`image${i}`}
                            placement="right"
                            overlay={
                              <div className="fotos mb-3">
                                <PhotoBox
                                  readOnly={true}
                                  photo={r.photo}
                                  modal={modal}
                                />
                              </div>
                            }
                          >
                            <i
                              className="fas fa-camera preview"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </td>
                        <td className="action">
                          <OverlayTrigger
                            key={`EditarrRisk${i}`}
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-left`}>
                                Editar ponto de risco
                              </Tooltip>
                            }
                          >
                            <span
                              id="EditarRisk"
                              className="float-left"
                              onClick={() =>
                                setPage((prev) => ({
                                  ...prev,
                                  type: "risk",
                                  id: r.id,
                                }))
                              }
                            >
                              <i className="fas fa-edit edit" />
                            </span>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key={`ExcluirRisk${i}`}
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-left`}>
                                Excluir ponto de risco
                              </Tooltip>
                            }
                          >
                            <span
                              id="ExcluirRisk"
                              className="float-left"
                              onClick={() => {
                                modal.confirm(
                                  <div>
                                    <span className="alert-remove">
                                      <h6>
                                        Deseja remover este ponto de risco?
                                      </h6>
                                      <span>Esta remoção é irreversível!</span>
                                    </span>
                                  </div>,
                                  (confirmed) => {
                                    if (confirmed) {
                                      onRemoveRisk({
                                        excluded: true,
                                        id: r.id,
                                      });
                                      setMachine((prev) => ({
                                        ...prev,
                                        risks: [
                                          ...prev.risks.filter(
                                            (c) => c.id !== r.id
                                          ),
                                        ],
                                      }));
                                    }
                                  }
                                );
                              }}
                            >
                              <i className="fas fa-trash remove" />
                            </span>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key={`DuplicarRisk${i}`}
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-left`}>
                                Duplicar ponto de risco
                              </Tooltip>
                            }
                          >
                            <span
                              id="clonarRisk"
                              className="float-left"
                              onClick={() => {
                                var risk = cloneRisk(r, machine);
                                setMachine((prev) => ({
                                  ...prev,
                                  risks: [...prev.risks, risk],
                                }));

                                setPage((prev) => ({
                                  ...prev,
                                  type: "risk",
                                  id: risk.id,
                                }));
                              }}
                            >
                              <i className="fas fa-copy clone" />
                            </span>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="float-left">
                <button
                  className="btn btn-primary btn-sm mb-0 mtn-3 mr-1"
                  onClick={() => {
                    let newRsk = newRisk(
                      machine.id,
                      analiseId,
                      customerId,
                      Math.max(...machine.risks.map((o) => o.sequencial), 0)
                    );
                    setMachine((prev) => ({
                      ...prev,
                      risks: [...prev.risks, newRsk],
                    }));

                    setPage((prev) => ({
                      ...prev,
                      type: "risk",
                      id: newRsk.id,
                    }));
                  }}
                >
                  Novo ponto{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3>Dispositivos de PAP (Partida Acionamento e Parada)</h3>

          <div className="risk-slider">
            <div
              className="box box-add"
              onClick={() => {
                let newDevicePAP = newPAPDevice(
                  machine.id,
                  analiseId,
                  customerId,
                  Math.max(...machine.papDevices.map((o) => o.sequencial), 0)
                );
                setMachine((prev) => ({
                  ...prev,
                  papDevices: [...prev.papDevices, newDevicePAP],
                }));
                setPage((prev) => ({
                  ...prev,
                  type: "pap",
                  id: newDevicePAP.id,
                }));
              }}
            >
              <span className="mt-1">Adicionar novo</span>
              <i className="fas fa-folder-plus icon mt-1" />
            </div>

            {machine.papDevices
              .map((pap, i) => (
                <div className="box" key={pap.id}>
                  <div className="container text-center">
                    <div
                      className="box-title box-spans"
                      onClick={() =>
                        setPage((prev) => ({
                          ...prev,
                          type: "pap",
                          id: pap.id,
                        }))
                      }
                    >
                      <p>{i + 1}º Ponto PAP</p>
                    </div>
                  </div>
                  <div className="box-footer">
                    <OverlayTrigger
                      key={`DuplicarPap${i}`}
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          Duplicar ponto PAP
                        </Tooltip>
                      }
                    >
                      <span
                        id="clonarPAP"
                        className="mr-2"
                        onClick={() => {
                          var p = clonePAPDevice(pap, machine);
                          setMachine((prev) => ({
                            ...prev,
                            papDevices: [...prev.papDevices, p],
                          }));
                          setPage((prev) => ({
                            ...prev,
                            type: "pap",
                            id: p.id,
                          }));
                        }}
                      >
                        <i className="fas fa-copy clone" />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key={`ExcluirPAP${i}`}
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-left`}>Excluir ponto PAP</Tooltip>
                      }
                    >
                      <span
                        id="excluirPAP"
                        onClick={() => {
                          modal.confirm(
                            "Deseja remover esse ponto de PAP?",
                            (confirmed) => {
                              if (confirmed) {
                                onRemovePap({
                                  excluded: true,
                                  id: pap.id,
                                });
                                setMachine((prev) => ({
                                  ...prev,
                                  papDevices: [
                                    ...prev.papDevices.filter(
                                      (c) => c.id !== pap.id
                                    ),
                                  ],
                                }));
                              }
                            }
                          );
                        }}
                      >
                        <i className="fas fa-trash remove" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              ))
              .reverse()}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3>Dispositivos de PE (Parada de Emergência)</h3>

          <div className="risk-slider">
            <div
              className="box box-add"
              onClick={() => {
                let newDevicePE = newPEDevice(
                  machine.id,
                  analiseId,
                  customerId,
                  Math.max(...machine.peDevices.map((o) => o.sequencial), 0)
                );
                setMachine((prev) => ({
                  ...prev,
                  peDevices: [...prev.peDevices, newDevicePE],
                }));

                setPage((prev) => ({
                  ...prev,
                  type: "pe",
                  id: newDevicePE.id,
                }));
              }}
            >
              <span className="mt-1">Adicionar novo</span>
              <i className="fas fa-folder-plus icon mt-1" />
            </div>

            {machine.peDevices
              .map((pe, i) => (
                <div className="box" key={pe.id}>
                  <div className="container text-center">
                    <div
                      className="box-title box-spans"
                      onClick={() =>
                        setPage((prev) => ({
                          ...prev,
                          type: "pe",
                          id: pe.id,
                        }))
                      }
                    >
                      <p>{i + 1}º Ponto PE</p>
                    </div>
                  </div>
                  <div className="box-footer">
                    <OverlayTrigger
                      key={`DuplicarPE${i}`}
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-left`}>Duplicar ponto PE</Tooltip>
                      }
                    >
                      <span
                        id="clonarPE"
                        className="mr-2"
                        onClick={() => {
                          var p = clonePEDevice(pe, machine);
                          setMachine((prev) => ({
                            ...prev,
                            peDevices: [...prev.peDevices, p],
                          }));
                          setPage((prev) => ({
                            ...prev,
                            type: "pe",
                            id: p.id,
                          }));
                        }}
                      >
                        <i className="fas fa-copy clone" />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key={`ExcluirPE${i}`}
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-left`}>Excluir ponto PE</Tooltip>
                      }
                    >
                      <span
                        id="excluirPE"
                        onClick={() => {
                          modal.confirm(
                            "Deseja remover esse ponto de PE?",
                            (confirmed) => {
                              if (confirmed) {
                                onRemovePe({
                                  excluded: true,
                                  id: pe.id,
                                });
                                setMachine((prev) => ({
                                  ...prev,
                                  peDevices: [
                                    ...prev.peDevices.filter(
                                      (c) => c.id !== pe.id
                                    ),
                                  ],
                                }));
                              }
                            }
                          );
                        }}
                      >
                        <i className="fas fa-trash remove" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              ))
              .reverse()}
          </div>
        </div>
      </div>
    </>
  );
}

function renderMachineQuestions(machine, setMachine) {
  return (
    <>
      <h3 className="module-header">Preencher disposições finais</h3>
      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            As manutenções preventivas com potencial de causar acidentes do
            trabalho são objetos de planejamento e gerenciamento efetuado por
            profissional legalmente habilitado.
          </p>
        </div>
        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group-questions">
            <div className="checkbox">
              <input
                id="intendedPreventiveMaintenance"
                type="checkbox"
                checked={machine.intendedPreventiveMaintenance}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    intendedPreventiveMaintenance: target.checked,
                  }))
                }
              />
              <label htmlFor="intendedPreventiveMaintenance">
                {machine.intendedPreventiveMaintenance ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            As manutenções preventivas e corretivas são registradas em livro
            próprio, ficha ou sistema informatizado, com os seguintes dados:
            <br />
            a) cronograma de manutenção;
            <br />
            b) intervenções realizadas;
            <br />
            c) data da realização de cada intervenção;
            <br />
            d) serviço realizado;
            <br />
            e) peças reparadas ou substituídas;
            <br />
            f) condições de segurança do equipamento;
            <br />
            g) indicação conclusiva quanto às condições de segurança da máquina;
            e<br />
            h) nome do responsável pela execução das intervenções.
          </p>
        </div>

        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group-questions">
            <div className="checkbox">
              <input
                id="registeredPreventiveMaintenance"
                type="checkbox"
                checked={machine.registeredPreventiveMaintenance}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    registeredPreventiveMaintenance: target.checked,
                  }))
                }
              />
              <label htmlFor="registeredPreventiveMaintenance">
                {machine.registeredPreventiveMaintenance ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            O registro das manutenções está disponível aos trabalhadores
            envolvidos na operação, manutenção e reparos, bem como à Comissão
            Interna de Prevenção de Acidentes - CIPA, ao Serviço de Segurança e
            Medicina do Trabalho - SESMT e à fiscalização do Ministério do
            Trabalho e Emprego
          </p>
        </div>

        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group-questions">
            <div className="checkbox">
              <input
                id="maintenanceRecordAvailable"
                type="checkbox"
                checked={machine.maintenanceRecordAvailable}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    maintenanceRecordAvailable: target.checked,
                  }))
                }
              />
              <label htmlFor="maintenanceRecordAvailable">
                {machine.maintenanceRecordAvailable ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            As máquinas e equipamentos possuem manual de instruções fornecido
            pelo fabricante ou importador, com informações relativas à segurança
            em todas as fases de utilização.
          </p>
        </div>

        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group-questions">
            <div className="checkbox">
              <input
                id="hasInstructionManual"
                type="checkbox"
                checked={machine.hasInstructionManual}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    hasInstructionManual: target.checked,
                  }))
                }
              />
              <label htmlFor="hasInstructionManual">
                {machine.hasInstructionManual ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            A máquina possui procedimentos de trabalho e segurança específicos,
            padronizados, com descrição detalhada de cada tarefa, passo a passo,
            a partir da análise de risco.
          </p>
        </div>

        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group-questions">
            <div className="checkbox">
              <input
                id="workingAndSafetyProcedures"
                type="checkbox"
                checked={machine.workingAndSafetyProcedures}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    workingAndSafetyProcedures: target.checked,
                  }))
                }
              />
              <label htmlFor="workingAndSafetyProcedures">
                {machine.workingAndSafetyProcedures ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row question">
        <div className="col-md-8">
          <h5>Pergunta: </h5>
          <p>
            Os trabalhadores envolvidos na operação, manutenção, inspeção e
            demais intervenções em máquinas e equipamentos possuem capacitação
            providenciada pelo empregador e compatível com suas funções, que
            aborde os riscos a que estão expostos e as medidas de proteção
            existentes e necessárias, nos termos desta Norma, para a prevenção
            de acidentes e doenças.
          </p>
        </div>

        <div className="col-md-2">
          <h5>Confere? </h5>
          <div className="check-group">
            <div className="checkbox">
              <input
                id="capitation"
                type="checkbox"
                checked={machine.capitation}
                onChange={({ target }) =>
                  setMachine((pap) => ({
                    ...pap,
                    capitation: target.checked,
                  }))
                }
              />
              <label htmlFor="capitation">
                {machine.capitation ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function renderSkeleton() {
  return (
    <SkeletonTheme color="#2E2F42" highlightColor="#404258">
      <h3 className="module-header">Pontos de risco, PAP e PE</h3>
      <div className="row">
        <div className="col-md">
          <h3>Pontos de Risco</h3>
          <div className="mb-2 mr-2">
            <Skeleton duration={1.5} height={87} count={1} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3>Dispositivos de PAP (Partida Acionamento e Parada)</h3>
          <div className="mb-2 mr-2">
            <Skeleton duration={1.5} height={87} count={1} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <h3>Dispositivos de PE (Parada de Emergência)</h3>
          <div className="mb-2 mr-2">
            <Skeleton duration={1.5} height={87} count={1} />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export const Machine = ({
  form: { submitting, entity, touched, errors, setValues, handleChange },
  global: { modal },
  refresh,
  sectors,
  page,
  setPage,
  layout,
}) => {
  const { machine } = entity;
  const setMachine = (m) =>
    setValues((prev) => ({
      ...prev,
      machine: m(prev.machine),
    }));

  const [removedRisk, onRemoveRisk] = useState({ excluded: false, id: 0 });
  useEffect(() => {
    if (removedRisk.excluded && removedRisk.id) {
      remove("risk", removedRisk.id)
        .then(() => {
          console.log("removed", removedRisk.id);
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          onRemoveRisk({ excluded: false, id: 0 });
        });
    }
  }, [removedRisk]);

  const [removedPap, onRemovePap] = useState({ excluded: false, id: 0 });
  useEffect(() => {
    if (removedPap.excluded && removedPap.id) {
      remove("pap", removedPap.id)
        .then(() => {
          console.log("removed", removedPap.id);
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          onRemoveRisk({ excluded: false, id: 0 });
        });
    }
  }, [removedPap]);

  const [removedPe, onRemovePe] = useState({ excluded: false, id: 0 });
  useEffect(() => {
    if (removedPe.excluded && removedPe.id) {
      remove("pe", removedPe.id)
        .then(() => {
          console.log("removed", removedPe.id);
        })
        .catch((err) => modal.alert(err.message))
        .finally(() => {
          onRemoveRisk({ excluded: false, id: 0 });
        });
    }
  }, [removedPe]);

  return (
    <>
      {page.type === "machine1" && (
        <MachineInfo
          form={{ entity, touched, errors, handleChange }}
          setMachine={setMachine}
          refresh={refresh}
          sectors={sectors}
          machine={machine}
          modal={modal}
        />
      )}

      {page.type === "machine2" && renderMachineStructure(machine, setMachine)}
      {page.type === "machine3" &&
        (submitting
          ? renderSkeleton()
          : renderMachineRisk(
              machine,
              setMachine,
              setPage,
              modal,
              layout,
              entity.id,
              entity.customerId,
              onRemoveRisk,
              onRemovePap,
              onRemovePe
            ))}
      {page.type === "machine4" && renderMachineQuestions(machine, setMachine)}

      <div className="buttons text-right">
        <Button
          disabled={submitting}
          customClassName="btn-secondary"
          icon="fas fa-angle-left valign-middle fa-sm"
          title="Voltar"
          onClick={() =>
            setPage((prev) => {
              const i = pageSteps.indexOf(prev.type);
              return { ...prev, type: pageSteps[i - 1] };
            })
          }
        />

        <button
          className="btn btn-success"
          onClick={(ev) => {
            if (machine.sectorId === 0) {
              ev.preventDefault();
              showToast({
                text: "Para prosseguir, selecione ou crie um novo setor!.",
                type: toastType.ERROR,
              });
            }
          }}
          disabled={submitting}
        >
          {submitting ? (
            <i className="fas fa-spinner fa-spin valign-middle" />
          ) : (
            <>
              <i
                className="fas fa-check valign-middle fa-sm valign-middle fa-sm"
                aria-hidden="true"
              />
              <span>
                {page.type === "machine4" ? "Salvar e Sair" : "Salvar Análise"}
              </span>
            </>
          )}
        </button>

        {page.type !== "machine4" && (
          <button
            disabled={submitting}
            className="btn btn-secondary"
            onClick={() =>
              machine.sectorId === 0
                ? showToast({
                    text: "⚠️Para prosseguir, selecione ou crie um novo setor!",
                    type: toastType.ERROR,
                  })
                : setPage((prev) => {
                    const i = pageSteps.indexOf(prev.type);
                    return { ...prev, type: pageSteps[i + 1] };
                  })
            }
          >
            <span>Avançar</span>
            <i
              className="fas fa-angle-right valign-middle fa-sm"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </>
  );
};
